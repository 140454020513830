<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'user-circle']" />
              <h2 class="tituloAtendimento">
                 Contratação de Pessoal
              </h2>
            </div>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>            
                <vs-select
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                  class="selectExample"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.type"
                  />
                </vs-select>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
        <SubstituicaoColaborador v-if="typeSelected == 'Substituição de colaborador'"/>
        <AumentoQuadro v-if="typeSelected == 'Aumento de quadro'"/>
        <AumentoQuadroDocente v-if="typeSelected == 'Aumento de quadro docente'"/>
      </div>
    </div>        
  </section>
</template>

<script>
import SubstituicaoColaborador from "./FormContratacao/SubstituicaoColaborador.vue"
import AumentoQuadro from "./FormContratacao/AumentoQuadro.vue"
import AumentoQuadroDocente from "./FormContratacao/AumentoQuadroDocente.vue"

export default {
  name: "ContratacaoPessoal",
  components: {
    SubstituicaoColaborador,
    AumentoQuadro,
    AumentoQuadroDocente,
  },
  data: () => ({
    typeSelected: null,
    data: {
      type: [
        { text: "Substituição de Colaborador", value: "Substituição de colaborador" },
        { text: "Aumento de Quadro", value: "Aumento de quadro" },
        { text: "Aumento de Quadro - Docente", value: "Aumento de quadro docente" },
      ],
    },
  }),
};
</script>
