import { https } from './api';

export default class {
    constructor() {
        this.instance = https('classes');
    }

    getSubjectByClassCode = (classCod) => {
        return this.instance({
            method: 'GET',
            url: `/${classCod}/subjects`
        })
    }

    getCoursesAndSubjectByClassCode = (classCod) => {
        return this.instance ({
            method: 'GET',
            url: `/${classCod}/coursesAndSubjects`
        })
    }

    getLessonPlans = (subjectID) => {
        return this.instance ({
            method: 'GET',
            url: `/${subjectID}/lessonPlans`
        })
    }

    getStudentsOfClass = (codColigada, idTurmaDisc) => {
        return this.instance ({
            method: 'GET',
            url: `/${codColigada}/${idTurmaDisc}/students`
        })
    }

    saveClassRoomLinks = (data) => {
        return this.instance ({
            method: 'POST',
            url: `/links`,
            data,
        })
    }

    integrateNotes = (data) => {
        return this.instance ({
            method: 'POST',
            url: `/notes/integration`,
            data,
        })
    }

    calculateFinalResult = (data) => {
        return this.instance ({
            method: 'POST',
            url: `/finalResult`,
            data,
        })
    }
}