<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'recycle']" />
            <h2 class="tituloAtendimento">                
               Solicitação de Material
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial"
                    rules="required"
                     
                  >
                    <vs-select                                
                      v-model="selectedUnit"
                      class="selectExampleDouble"
                      placeholder="Selecione uma Filial"
                    >
                      <vs-select-item
                          :key="index"
                          :value="item.CODFILIAL"
                          :text="item.POLO"
                          v-for="(item, index) in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>     

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Quantidade <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Quantidade"
                    rules="required"
                     
                  >
                      <vs-input type="number" v-model="amount" placeholder="Digite a Quantidade" class="selectExampleDouble"/>
                       
                  </ValidationProvider>
                </fieldset>
              </vs-col>                    
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                    name="Descrição do Material"
                    rules="required|max: 500"
                     
                >
                  <vs-textarea
                    label="Descrição do Material"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="materialDescription"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row> 
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import Units from "../../../services/units";
    import Attendance from "../../../services/attendance";
    import { getLoginDataFromStorage } from "../../../helpers/storage.js";
    import MarketingModel from "../../../models/marketing/marketing.js"
    export default {
      name: "SolicitacaoDeMaterial",
      data: () => ({
        idAttendance: null,
        errorIdAttendance: null,
        selectedUnit: null,
        units: null,
        amount: null,
        materialDescription: null,
      }),

      mounted() {
        this.$vs.loading({ text: "Carregando..." });
        new Units()
          .getUnits()
          .then(({ data }) => {
              this.units = Array.isArray(data) ? data : [data];
          })
          .catch(({ response }) => {
              if (response && response.data) {
                  this.units = response.data;
                  console.log(response)
              }
          })
          .finally(() => {
              this.$vs.loading.close();
          })
      },

      methods: {
        async createAttendance() {
          this.idAttendance = null;
          this.errorIdAttendance = null;  

          const { ...userProfile } = getLoginDataFromStorage();

          const personId = userProfile.userData.CODIGO;
          const personLocalId = userProfile.userData.CODLOCAL;
          const personName = userProfile.userData.NOME;
          const attendantId = userProfile.userData.CODATENDENTE;

          const payload = new MarketingModel().getDataByAttendanceId('M3');

          payload.data.locationId = personLocalId.toString();
          payload.data.personId = personId.toString();
          payload.data.attendantId = attendantId.toString();

          payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${this.selectedUnit}\nQUANTIDADE: ${this.amount}\nDESCRIÇÃO DO MATERIAL: ${this.materialDescription}`;

          this.$vs.loading({ text: "Carregando..." });
          new Attendance()
          .createAttendance(payload.data)
          .then(({ data }) => {
              this.idAttendance = data;
          })
          .catch(({ response }) => {
              if (response && response.data) {
              this.errorIdAttendance = response.data;
              console.log(response)
              }
          })
          .finally(() => {
              this.$vs.loading.close();
              this.resetForm();
          });
        },

        resetForm() {
          this.selectedUnit = null;
          this.amount = null;
          this.materialDescription = null;

          this.$nextTick(() => {
              this.$refs.observer.reset();
          });
        }
      },        
    }
</script>

<style scoped>
