<template>
    <section>
        <div class="subContainer">
            <vs-row class="topo">
                <div class="titulo">
                    <font-awesome-icon :icon="['fas', 'handshake']" />
                    <h2 class="tituloAtendimento">
                         Inclusão De Desconto - Convênios
                    </h2>
                </div>
            </vs-row>

            <div>
                <vs-row class="pt-d">
                    <vs-col>
                    <Message class="pd-t"
                        :msg="idAttendance"
                        :error="errorIdAttendance"
                        v-if="idAttendance || errorIdAttendance"          
                    />
                    </vs-col>
                </vs-row>
            </div>

            <div>
                <ValidationObserver ref="observer2" v-slot="{ invalid }">
                    <vs-row class="pd-t">
                        <vs-col>
                            <fieldset class="search">
                                <legend>RA do Aluno <b style="color: red;">*</b></legend>
                                <ValidationProvider
                                    name="RA"
                                    rules="required|numeric"
                                     
                                >                                            
                                    <vs-input
                                        @keyup.enter="getAgreementByRa()"   
                                        v-model="valueStudent"
                                        placeholder="Digite o RA do Aluno"
                                        class="selectExampleRA"
                                    />                                            
                                     
                                </ValidationProvider>
                                <vs-button
                                    color="primary"
                                    class="buscar"
                                    type="filled"
                                    :disabled="invalid"
                                    @click="getAgreementByRa()">
                                    <font-awesome-icon :icon="['fas', 'search']"/>
                                <span> Buscar </span></vs-button>                                        
                            </fieldset>                                    
                        </vs-col>
                    </vs-row>                            
                </ValidationObserver>                        

                <ValidationObserver ref="observer" v-slot="{ invalid }">
                    <div v-if="agreementRa != null">
                        <div v-if="agreementRa ==''">
                            <p class="validacao">RA Inválido</p>            
                        </div>
                        <div v-else>
                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Contrato <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Contrato"
                                            rules="required"
                                             
                                        >                                                    
                                            <vs-select
                                                class="selectExampleDouble"
                                                v-model="selectedAgreementStudent"
                                                placeholder="Selecione um Contrato"
                                            >
                                                <vs-select-item
                                                    :key="index"
                                                    :value="item.CODCONTRATO"
                                                    :text="item.CODCONTRATO"
                                                    v-for="(item, index) in agreementRa"
                                                />
                                            </vs-select>
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Filial <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Filial"
                                            rules="required"
                                                
                                        >
                                            <vs-select
                                                class="selectExampleDouble"
                                                v-model="selectedUnitStudent"
                                                placeholder="Selecione uma Filial"
                                            >
                                                <vs-select-item
                                                    :key="index"
                                                    :text="item.POLO"
                                                    :value="item.CODFILIAL"
                                                    v-for="(item, index) in units"
                                                />
                                            </vs-select>                                        
                                             
                                        </ValidationProvider>
                                    </fieldset>                                        
                                </vs-col>
                            </vs-row> 

                            <vs-row class="pd-t">
                                <vs-col>
                                <fieldset>
                                    <legend>Anexo <b style="color: red;">*</b></legend>
                                    <ValidationProvider
                                        name="Anexo"
                                        ref="provider"
                                        rules="required"
                                         
                                    >
                                        <input class="selectExample" type="file" @change="onFileUploadStudent">
                                         
                                    </ValidationProvider>
                                </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col>
                                    <ValidationProvider
                                        name="Texto Solicitação"
                                        rules="required|max: 500"
                                         
                                    >
                                    <vs-textarea
                                        rows="11"
                                        label="Texto Solicitação *"
                                        class="textArea"
                                        height="15em"
                                        counter="500"
                                        v-model="solicitationStudent"
                                    />
                                     
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>                                                                                   

                            <vs-row class="pd-t">
                                <vs-col style="text-align: center;">
                                    <vs-button
                                        color="danger"
                                        type="gradient"
                                        class="button-protocol"
                                        @click="resetForm()"
                                    >
                                        <font-awesome-icon :icon="['fas', 'times-circle']" />
                                        Cancelar
                                    </vs-button>
                                    
                                    <vs-button
                                        class="button-protocol"
                                        color="success"
                                        type="gradient"
                                        :disabled="invalid"
                                        @click="urgence = true"
                                    >
                                        <font-awesome-icon :icon="['fas', 'check']" />
                                        Gerar
                                    </vs-button>
                                </vs-col>            
                            </vs-row> 

                            <vs-popup title="Prioridade do Atendimento" :active.sync="urgence">
                                <vs-col>
                                    <vs-button
                                        style="width: 47.5%;margin: 0 1.25%;"
                                        type="gradient"
                                        @click="createAttendance(10000)"
                                    >
                                        Urgente
                                    </vs-button>

                                    <vs-button
                                        style="width: 47.5%;margin: 0 1.25%;"
                                        type="gradient"
                                        @click="createAttendance(100)"
                                    >
                                        Normal
                                    </vs-button>
                                </vs-col>
                            </vs-popup>                                          
                        </div>
                    </div>                                                                                                                           
                </ValidationObserver>                    
            </div>                        
        </div>    
    </section>
</template>

<script>
import Management from "../../../../services/management";
import Units from "../../../../services/units";
import { getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
export default {
    name: "BolsaEmpresaConveniada",

    data: () => ({
        filesStudent: null,
        base64ImageStudent: null,
        urgence: false,
        idAttendance: null,
        errorIdAttendance: null,
        valueStudent: null,
        selectedAgreementStudent: null,
        agreementRa: null,
        errorAgreementRa: null,
        selectedUnitStudent: null,
        solicitationStudent: null,
        units: null,
        errorUnit: null,
        visibleButton: false,
    }),

    mounted() {
        this.$vs.loading({ text: "Carregando..." });
        new Units()
        .getUnits()
        .then(({ data }) => {
            this.units = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
            if (response && response.data) {
                this.errorUnit = response.data;
                console.log(response)
            }
        })
        .finally(() => {
            this.$vs.loading.close();
        });
    },

    methods: {

        async onFileUploadStudent (event) {
            this.filesStudent = event.target.files[0];                            
            const { valid } = await this.$refs.provider.validate(this.filesStudent);   
            if (valid) {                                   
                this.createBase64ImageStudent(this.filesStudent);
                this.visibleButton = true;
            }
        },

        createBase64ImageStudent(file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                var bynaryData = event.target.result;

                this.base64ImageStudent = window.btoa(bynaryData);

            };
            reader.readAsBinaryString(file);
        },

        async createAttendance(urgence) {
            this.idAttendance = null;
            this.errorIdAttendance = null;
            const { ...userProfile } = getLoginDataFromStorage();

            const personId = userProfile.userData.CODIGO;
            const personLocalId = userProfile.userData.CODLOCAL;
            const personName = userProfile.userData.NOME;
            const attendantId = userProfile.userData.CODATENDENTE;
            
            const payload = new AdministrativoModel().getDataByAttendanceId('A1-8');

            payload.data.locationId = personLocalId.toString();
            payload.data.ra = this.valueStudent;
            payload.data.attendantId = attendantId.toString();
            payload.data.attachment = this.base64ImageStudent;
            payload.data.fileName = this.filesStudent.name;
            payload.data.priority = urgence;
            payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA: ${this.valueStudent}\nCONTRATO: ${this.selectedAgreementStudent}\nFILIAL: ${poloTextFormat(this.selectedUnitStudent, this.units)}\nSOLICITAÇÃO: ${this.solicitationStudent}`;

            this.$vs.loading({ text: "Carregando..." });
            new Attendance()
                .createAttendance(payload.data)
                .then(({ data }) => {
                this.idAttendance = data;
                })
                .catch(({ response }) => {
                if (response && response.data) {
                    this.errorIdAttendance = response.data;
                    console.log(response)
                }
                })
                .finally(() => {
                this.resetForm();
                this.$vs.loading.close();
                });
        },

        getAgreementByRa() {
            this.$vs.loading({ text: "Carregando..." });
            new Management()
                .getAgreementByRA(this.valueStudent)
                .then(({ data }) => {
                    this.agreementRa = Array.isArray(data) ? data : [data];                
                })
                .catch(({ response }) => {
                if (response && response.data) {
                    this.errorAgreementRa = response.data;
                    console.log(response)
                }
                })
                .finally(() => {
                this.$vs.loading.close();
                });
        },

        resetForm() {
            this.solicitationStudent = null;
            this.selectedAgreementStudent = null;
            this.selectedUnitStudent = null;
            this.valueStudent = null;
            this.agreementRa = null;
            this.visibleButton = false;

            this.$nextTick(() => {
                this.$refs.observer.reset();
                this.$refs.observer2.reset();
            });   
        },
    }
}
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
