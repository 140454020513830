import { https, https2 } from './apiRamais'

export default class {
    constructor() {        
        this.instance = null
        this.instance2 = https2()
    }
    
    getToken = () => {
        var payload = {
            "username": "Admin.qi",
            "password": "123qweasd@QWE"
        }
        return this.instance2({
            method: 'POST',
            url:'/token',
            data: payload
        });
    }

    getRamais = (token) => {
        token = this.getToken();
        this.instance = https(token);
        return this.instance({
            method: 'GET',
            url: "/peers"
        });
    }
}