export default class{
    constructor() {
        this.instance = 
        [            
            {
                header: "Administrativo",
                rule: "isLogged",
                submenu: [
                    {
                        index: "3.0",
                        icon: "gavel",
                        url: "/modulos/atendimentos/administracao/agendamento-manutencao",
                        title: "Agendamento de Manutenção"
                    },
                    {
                        index: "3.1",
                        icon: "coins",
                        url: "/modulos/atendimentos/administracao/ajustes-financeiros",
                        title: "Ajustes Financeiros"
                    },
                    {
                        index: "3.2",
                        icon: "address-card",
                        url: "/modulos/atendimentos/administracao/alteracao-cadastro",
                        title: "Alteração de Cadastro"
                    },
                    {
                        index: "3.3",
                        icon: "shopping-cart",
                        url: "/modulos/atendimentos/administracao/compras",
                        title: "Compras"
                    },
                    {
                        index: "3.4",
                        icon: "check-circle",
                        url: "/modulos/atendimentos/administracao/correcao-lancamentos-financeiros",
                        title: "Correção de Lanç. Financeiros"
                    },
                    {
                        index: "3.5",
                        icon: "wallet",
                        url: "/modulos/atendimentos/administracao/demanda-comercial",
                        title: "Demanda Comercial"
                    },
                    {
                        index: "3.6",
                        icon: "sticky-note",
                        url: "/modulos/atendimentos/administracao/estoque",
                        title: "Estoque"
                    },
                    {
                        index: "3.7",
                        icon: "gavel",
                        url: "/modulos/atendimentos/administracao/juridico",
                        title: "Jurídico"
                    },
                    {
                        index: "3.8",
                        icon: "cogs",
                        url: "/modulos/atendimentos/administracao/manutencao",
                        title: "Manutenção"
                    },
                    {
                        index: "3.9",
                        icon: "book",
                        url: "/modulos/atendimentos/administracao/solicitacao-netep",
                        title: "NETeP"
                    },
                    {
                        index: "3.10",
                        icon: "scroll",
                        url: "/modulos/atendimentos/administracao/normativas",
                        title: "Normativas"
                    },
                    {
                        index: "3.11",
                        icon: "filter",
                        url: "/modulos/atendimentos/administracao/numerarios",
                        title: "Numerários"
                    },
                    {
                        index: "3.12",
                        icon: "window-maximize",
                        url: "/modulos/atendimentos/administracao/projetos-arquitetonicos",
                        title: "Projetos Arquitetônicos"
                    },
                    {
                        index: "3.13",
                        icon: "laptop-code",
                        url: "/modulos/atendimentos/administracao/ti",
                        title: "TI"
                    },                               
                ]
            },
            {
                header: "DP/RH",
                rule: "isLogged",
                submenu: [
                    {
                        index: "4.0",
                        icon: "user-plus",
                        url: "/modulos/atendimentos/pessoal/beneficio-professor-instrutor",
                        title: "Benefício de Docente",
                        rule: "isLogged",
                    },
                    {
                        index: "4.1",
                        icon: "user-circle",
                        url: "/modulos/atendimentos/pessoal/contratacao-pessoal",
                        title: "Contratação de Pessoal",
                        rule: "isLogged",
                    },
                    {
                        index: "4.2",
                        icon: "users",
                        url: "/modulos/atendimentos/pessoal/departamento-pessoal",
                        title: "Departamento Pessoal",
                        rule: "isLogged",
                    },
                    {
                        index: "4.3",
                        icon: "sync-alt",
                        url: "/modulos/atendimentos/pessoal/movimentacao-pessoal",
                        title: "Movimentação de Pessoal",
                        rule: "isLogged",
                    },
                    {
                        index: "4.4",
                        icon: "retweet",
                        url: "/modulos/atendimentos/pessoal/bolsa",
                        title: "Solicitação de Bolsa",
                        rule: "isLogged",
                    },
                    {
                        index: "4.5",
                        icon: "users",
                        url: "/modulos/atendimentos/pessoal/treinamento",
                        title: "Treinamento",
                        rule: "isLogged",
                    },           
                ]
            },
            {
                header: "Educacional",
                rule: "isLogged",
                submenu: [
                    {
                        index: "5.1",
                        icon: "cogs",
                        url: "/modulos/atendimentos/educacional/solicitacao-de-alunos",
                        title: "Solicitações de Alunos"
                    },
                    {
                        index: "5.2",
                        icon: "cogs",
                        url: "/modulos/atendimentos/educacional/ajuste-diario-de-classe",
                        title: "Ajuste Diário de Classe"
                    },
                    {
                        index: "5.3",
                        icon: "certificate",
                        url: "/modulos/atendimentos/educacional/certificados",
                        title: "Certificados"
                    },
                    {
                        index: "5.4",
                        icon: "chart-bar",
                        url: "/modulos/atendimentos/educacional/cursos-superiores-ead",
                        title: "Cursos Superiores EAD"
                    },
                    {
                        index: "5.5",
                        icon: "chart-bar",
                        url: "/modulos/atendimentos/educacional/regulacao-escolar",
                        title: "Regulação Escolar"
                    },
                    {
                        index: "5.6",
                        icon: "cogs",
                        url: "/modulos/atendimentos/educacional/planejamento-academico",
                        title: "Planejamento Acadêmico"
                    }, 
                    {
                        index: "5.7",
                        icon: "users",
                        url: "/modulos/atendimentos/educacional/producao-de-materiais",
                        title: "Produção de Materiais"
                    },          
                ]
            },
            {
                header: "Marketing",
                rule: "isLogged",
                submenu: [
                    {
                        index: "6.1",
                        icon: "user-plus",
                        url: "/modulos/atendimentos/marketing/criacao-comunicacao",
                        title: "Criação ou Comunicação"
                    },
                    {
                        index: "6.2",
                        icon: "desktop",
                        url: "/modulos/atendimentos/marketing/publicacao-de-releases",
                        title: "Publicação de Releases"
                    },
                    {
                        index: "6.3",
                        icon: "recycle",
                        url: "/modulos/atendimentos/marketing/solicitacao-de-material",
                        title: "Solicitação de Material"
                    },           
                ]
            },   
        ]
    }
    getData(){
        return this.instance;
    }
}