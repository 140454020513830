<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'graduation-cap']" />
          <h2 class="tituloAtendimento">            
            Ajuste de Horários/Dias da Semana
          </h2>
        </div>
      </vs-row>

      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>Cód. Turma <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Cód. Turma"
                  rules="required|numeric"
                   
                >
                  <vs-input
                    @keyup.enter="getSubjectByClassCode()" 
                    v-model="classCode"
                    placeholder="Digite o Cód. Turma"
                    class="selectExampleRA"
                  />
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getSubjectByClassCode()">
                  <font-awesome-icon :icon="['fas', 'search']"/>
                <span>Buscar</span></vs-button>                          
              </fieldset>
            </vs-col>
          </vs-row> 
        </ValidationObserver>                             
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div   v-if="responseClassCode != null">
          <div v-if="responseClassCode == ''">
            <p class="validacao">Cód. Turma Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-table">  
              <vs-col>  
                <fieldset>
                  <ValidationProvider
                    name="Disciplina"
                    rules="required"
                     
                  >
                    <vs-table
                      class="table_intranet"
                      hoverFlat
                      multiple
                      v-model="selected"
                      :data="responseClassCode"
                      v-on:input="selectedItens"
                    >
                      <template slot="thead">
                        <vs-th> Disciplina </vs-th>
                        <vs-th> Inicio </vs-th>
                      </template>

                      <template slot-scope="{data}">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"                                
                        >
                          <vs-td>
                            {{ data[indextr].DISCIPLINA }}
                          </vs-td>

                          <vs-td>
                            {{ data[indextr].DATA_INICIAL }}
                          </vs-td>                              
                        </vs-tr>
                      </template>
                    </vs-table>
                     
                  </ValidationProvider>
                </fieldset>  
              </vs-col>
            </vs-row>  
            
            <vs-row class="pd-t">
              <vs-col>
                <div class="flexTextArea">
                  <ValidationProvider
                    name="Justificativa"
                    rules="required|max:500"
                     
                  >
                    <vs-textarea
                      label="Justificativa *"
                      height="210px"
                      rows="11"
                      class="textArea"
                      counter="500"
                      v-model="solicitation"
                    />
                     
                  </ValidationProvider>
                </div>
              </vs-col>                  
            </vs-row>              

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Classes from "../../../../services/classes";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  name: "AjusteDeHorarioDiasDaSemana",
  data: () => ({
    classCode: null,
    responseClassCode: null,
    errorResponseClassCode: null,
    selected: [],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),
  methods: {
    getSubjectByClassCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Classes()
        .getSubjectByClassCode(this.classCode)
        .then(({ data }) => {
          this.responseClassCode = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseClassCode = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },    

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1-3");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCÓDIGO TURMA: ${this.classCode}\nDISCIPLINA: ${this.selectedItens()}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },

    selectedItens() {
      let text = "";
      for (let i = 0; i < this.selected.length; i++) {
        text += this.selected[i].DISCIPLINA+",";
      }
      return text;
    },

    resetForm() {
      this.selected = [];
      this.solicitation = null;
      this.classCode = null;
      this.responseClassCode = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
