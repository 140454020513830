<template>
  <section>
    <div class="subContainer">
    <vs-row vs-w="12" class="topo">
      <div class="titulo">
        <font-awesome-icon :icon="['fas', 'graduation-cap']" />
        <h2 class="tituloAtendimento">          
          Adiamento de Turma
        </h2>
      </div>
    </vs-row>

    <div>
      <ValidationObserver ref="observer2" v-slot="{ invalid }">
        <vs-row class="pd-t">
          <vs-col>
            <fieldset class="search">
              <legend>Cód. Turma <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Cód. Turma"
                rules="required|numeric"
                 
              >
                <vs-input
                  @keyup.enter="getSubjectByClassCode()" 
                  v-model="classCode"
                  placeholder="Digite o Cód. Turma"
                  class="selectExampleRA"
                />                
                 
              </ValidationProvider>
              <vs-button
                color="primary"
                class="buscar"
                type="filled"
                :disabled="invalid"
                @click="getSubjectByClassCode()">
                <font-awesome-icon :icon="['fas', 'search']"/>
              <span> Buscar </span></vs-button>                          
            </fieldset>
          </vs-col>
        </vs-row> 
      </ValidationObserver>                             
    </div> 
    
    <div>
      <vs-row class="pt-d">
        <vs-col>
          <Message class="pd-t"
            :msg="idAttendance"
            :error="errorIdAttendance"
            v-if="idAttendance || errorIdAttendance"          
          />
        </vs-col>
      </vs-row>
    </div>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div   v-if="responseClassCode != null">
        <div v-if="responseClassCode == ''">
          <p class="validacao">Cód. Turma Inválido</p>            
        </div>
        <div v-else>
          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Disciplina <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Disciplina"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExampleDouble"
                    placeholder="Selecione uma Disciplina"
                    v-model="selectedCourse"                 
                  >
                    <vs-select-item
                      :key="item.CODIGO"
                      :value="item.DISCIPLINA + '-' + item.DATA_INICIAL"
                      :text="item.DISCIPLINA + '-' + item.DATA_INICIAL"
                      v-for="item in responseClassCode"
                    />                                      
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Data <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data"
                  rules="required"
                   
                  >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="date"
                    type="date"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>
            
          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Justificativa"
                rules="required|max: 500"
              >
                <vs-textarea
                  label="Justificativa"
                  height="210px"
                  rows="11"
                  class="textArea"
                  counter="500"
                  v-model="solicitation"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
        </div>        
      </div>
    </ValidationObserver>  
  </div>
  </section>
</template>

<script>
import EducacionalModel from "../../../../models/educacional/educacional.js";
import { convertDate, getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import Classes from "../../../../services/classes";

export default {
  name: "AdiamentoDeTurma",
  data: () => ({
    classCode: null,
    date: null,
    responseClassCode: null,
    errorResponseClassCode: null,
    idAttendance: null,
    errorIdAttendance: null,
    selectedCourse: null,
    solicitation: null,
  }),

  methods: {

    getSubjectByClassCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Classes()
        .getSubjectByClassCode(this.classCode)
        .then(({ data }) => {
          this.responseClassCode = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseClassCode = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId('E1-2');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCÓDIGO TURMA: ${this.classCode}\nDISCIPLINA: ${this.selectedCourse}\nNOVA DATA INICIAL: ${convertDate(this.date)}\nJustificativa: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },
    resetForm() {
      this.selectedCourse = null;
      this.date = null;
      this.solicitation = null;
      this.classCode = null;
      this.responseClassCode = null;

      this.$nextTick(() => {
          this.$refs.observer.reset();
          this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>