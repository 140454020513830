<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'cogs']" />
              <h2 class="tituloAtendimento">
                 Manutenção
              </h2>
            </div>
          </vs-row>

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"                  
                      placeholder="Selecione uma Filial"
                      v-model="selectedUnit"
                    >
                      <vs-select-item
                        :key="item.CODFILIAL"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="item in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>                   
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Assunto <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Assunto"
                    rules="required|max: 500"
                     
                  >
                    <vs-input v-model="subject" placeholder="Digite o Assunto" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Texto Solicitação *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>                  
        </ValidationObserver>      
      </div>
    </div>
  </section>
</template>

<script>
import Units from "../../../services/units";
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage, poloTextFormat } from "../../../helpers/storage.js";
import AdministrativoModel from "../../../models/administrativo/administrativo";

export default {
  name: "Manutencao",

  data: () => ({
    units: null,
    selectedUnit: null,
    subject: null,
    errorUnit: null,
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A8');

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = this.subject.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${poloTextFormat(this.selectedUnit,this.units)}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()      
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.subject = "";
      this.selectedUnit = "";
      this.solicitation = "";

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  }
};
</script>
