import { https } from './api';

export default class {
    constructor() {
        this.instance = https('commercial')
    }

    getMatriculationBySeller = () => {
        return this.instance({
            method: 'GET',
            url: `/matriculationBySeller`,
        });
    }
}