export default class {
    constructor() {
        this.instance = 
            [
                {
                    typeAttendanceId: "P1", /* DP-RH / CONTRATAÇÃO PESSOAL / AUMENTO DE QUADRO DOCENTE */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "2751", //FILA RECURSOS HUMANOS
                        respColligateId: "1",
                        classificationId: "00115",
                        subject: "RH - CONTRATAÇÃO DE DOCENTE",
                        typeAttendanceId: "226", 
                        groupAttendanceId: "9", 
                        personId: "",
                        requestText: ``,
                        productId: "7",
                        accessLevel: "5",
                        clientType: "Pessoa",
                        attendantId: "",
                        deadline: "",
                    }
                },

                {
                    typeAttendanceId: "P1-2", /* DP-RH / CONTRATAÇÃO PESSOAL / AUMENTO DE QUADRO */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "2372",
                        respColligateId: "1",
                        classificationId: "00020",
                        subject: "RH - CONTRATAÇÃO DE PESSOAL",
                        typeAttendanceId: "77",
                        groupAttendanceId: "9",
                        personId: "",
                        requestText: ``,
                        productId: "7",
                        accessLevel: "7",
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P1-3", /* DP-RH / CONTRATAÇÃO PESSOAL / SUBSTITUIÇÃO DE COLABORADOR */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "2751",
                        respColligateId: "1",
                        classificationId: "00036",
                        subject: "RH - CONTRATAÇÃO DE TUTOR(A) / INSTRUTOR / PROFESSOR(A)",
                        typeAttendanceId: "100", 
                        groupAttendanceId: "9", 
                        personId: "",
                        requestText: ``,
                        productId: "7",
                        accessLevel: "5",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "P1-4", /* DP-RH / CONTRATAÇÃO PESSOAL / SUBSTITUIÇÃO DE COLABORADOR */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "2372",
                        respColligateId: "1",
                        classificationId: "00020",
                        subject: "RH - CONTRATAÇÃO DE PESSOAL",
                        typeAttendanceId: "77",
                        groupAttendanceId: "9",
                        personId: "",
                        requestText: ``,
                        productId: "7",
                        accessLevel: "7",
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P2", /* DP-RH / DEPARTAMENTO PESSOAL */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "2372",
                        respColligateId: "1",
                        classificationId: "00020",
                        subject: "Solicitação ao DP",
                        typeAttendanceId: "63",
                        groupAttendanceId: "12",
                        personId: "",
                        requestText: "",
                        productId: "7",
                        accessLevel: "7",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P3", /* DP-RH / MOVIMENTAÇÃO DE PESSOAL / DESLIGAMENTO - INSTRUTOR / TUTOR / PROFESSOR */
                    data:  {
                        colligateId: "1",
                        respAttendantId: "2933",
                        productId: "7",
                        locationId: "",
                        personId: "",
                        classificationId: "00036", 
                        subject: "RH - MOVIMENTAÇÃO DE PESSOAL - DESLIGAMENTO INSTRUTOR / TUTOR / PROFESSOR",
                        accessLevel: "5",
                        typeAttendanceId: "99",
                        groupAttendanceId: "9",
                        respColligateId: "1",
                        requestText: ``,
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P3-2", /* DP-RH / MOVIMENTAÇÃO DE PESSOAL / DESLIGAMENTO - PEDIDO DE DEMISSÃO  */
                                              /* DP-RH / MOVIMENTAÇÃO DE PESSOAL / FÉRIAS */
                                              /* DP-RH / MOVIMENTAÇÃO DE PESOAL / TRANSFERÊNCIA - PROMOÇÃO / TRANSFERÊNCIA DE FUNÇÃO, PROMOÇÃO, TRANSFERÊNCIA DE UNIDADE, TRANSFERÊNCIA DE HORÁRIO */
                    data:  {
                        colligateId: "1",
                        respAttendantId: "2372",
                        productId: "7",
                        locationId: "",
                        personId: "",
                        classificationId: "00023", 
                        subject: "",
                        accessLevel: "7",
                        typeAttendanceId: "27",
                        groupAttendanceId: "9",
                        respColligateId: "1",
                        requestText: ``,
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P4", /* DP-RH / SOLICITAÇÃO DE BOLSA */
                    data:  {
                        colligateId: "1",
                        respAttendantId: "2372",
                        productId: "7",
                        locationId: "",
                        personId: "",
                        classificationId: "00025", 
                        subject: "INTRANET - SOLICITACAO DE DESCONTO DE CURSO",
                        accessLevel: "7",
                        typeAttendanceId: "206",
                        groupAttendanceId: "9",
                        respColligateId: "1",
                        requestText: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P5", /* DP-RH / TREINAMENTO */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "3585",
                        respColligateId: "1",
                        classificationId: "00020",
                        subject: "RH - Solicitação de Treinamento",
                        typeAttendanceId: "26",
                        groupAttendanceId: "9",
                        personId: "",
                        requestText: "",
                        productId: "7",
                        accessLevel: "7",
                        clientType: "Pessoa",
                        attendantId: "",
                    } 
                },

                {
                    typeAttendanceId: "P6", /* DP-RH / BENEFÍCIO DE PROFESSOR/INSTRUTOR */
                    data: {     
                        respAttendantId: "3868", 
                        productId: "7",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00030",
                        subject: "BENEFÍCIO DE PROFESSOR/INSTRUTOR",
                        accessLevel: "7",
                        typeAttendanceId: "257",
                        groupAttendanceId: "12",
                        personId: "",
                        requestText: "",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },
            ]
    }

    getDataByAttendanceId(typeAttendanceId) {
        for (let i=0; i<this.instance.length; i++){
            if(this.instance[i].typeAttendanceId == typeAttendanceId)
                return this.instance[i];
        }
    }
}