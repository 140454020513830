import { https } from './api'

export default class {
  constructor() {
    this.instance = https()
  }

  login = (data) => {
    return this.instance({
      method: 'POST',
      url: '/auth/login',
      timeout: 10000,
      data,
    })
  }

  loginSpecial = (data) => {
    return this.instance({
      method: 'POST',
      url: '/auth/loginSpecial',
      timeout: 10000,
      data,
    })
  }
}
