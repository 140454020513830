<template>
  <section>
    <div>
      <vs-row class="pd-t">
        <vs-col>
          <fieldset v-if="this.codAtendente == '0'">
            <p>
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              Nenhum atendimento encontrado
            </p>
          </fieldset>
          <fieldset v-else>
            <legend>Fila <b style="color: red;">*</b></legend>
            <vs-select
              class="selectExample"
              v-model="selectedQueue"
              placeholder="Selecione uma Fila"
              @change="getCallsByDepartment()"
            >
              <vs-select-item
                :key="item.CODATENDENTE"
                :value="item.CODATENDENTE"
                :text="item.RESPONSAVEL"
                v-for="item in queues"
              />
            </vs-select>
          </fieldset>
        </vs-col>
      </vs-row>

      <div v-if="callsByDepartment">
        <vs-row class="pd-table">
          <vs-col>
            <fieldset v-if="this.callsByDepartment == ''">
              <p>
                <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
                Nenhum atendimento encontrado
              </p>
            </fieldset>
            <vs-table
              v-else
              :data="callsByDepartment"
              hoverFlat
              search
              pagination
              max-items="10"
              class="table_intranet"
            >
              <template slot="thead">
                <vs-th> ATENDIMENTO </vs-th>
                <vs-th> DATA ABERTURA </vs-th>
                <vs-th> ASSUNTO </vs-th>
                <vs-th> CLASSIFICACAO </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-popup 
                  style="z-index: 999999 !important" 
                  :title="data[idPopup] ? 'Atendimento - ' + data[idPopup].CODATENDIMENTO : 'Atendimento'"  
                  :active.sync="popupAtivo"
                >
                  <vs-col>
                    <vs-tabs alignment="center">
                      <vs-tab icon="library_add" label="Solicitação" @click="type='solicitation'"/>
                      <vs-tab icon="group" label="Discussão" @click="type='discussion'"/>
                      <vs-tab icon="check_circle" label="Solução" @click="type='solution'"/>       
                    </vs-tabs>        
                  </vs-col>
                  <fieldset v-if="type == 'solicitation'">
                    <p
                      v-html="
                        data[idPopup] ? 
                        parseNewLine(data[idPopup].SOLICITACAO) : 
                        ''
                      "
                    ></p>
                  </fieldset>
                  <fieldset v-if="type == 'discussion'">
                    <p
                      v-html="
                        data[idPopup] ? 
                        parseNewLine(data[idPopup].DISCUSSAO) : 
                        ''
                      "
                    ></p>
                  </fieldset>
                  <fieldset v-if="type == 'solution'">
                    <p
                      v-html="
                        data[idPopup] ? 
                        parseNewLine(data[idPopup].SOLUCAO) : 
                        ''
                      "
                    ></p>
                  </fieldset>
                </vs-popup>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <a href="#" @click="handleClickDetails(indextr)">{{
                      data[indextr].CODATENDIMENTO
                    }}</a>
                  </vs-td>

                  <vs-td>
                    {{ data[indextr].DATA_ABERTURA }}
                  </vs-td>

                  <vs-td>
                    {{ data[indextr].ASSUNTO }}
                  </vs-td>

                  <vs-td>
                    {{ data[indextr].CLASSIFICACAO }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </section>
</template>
<script>
import Units from "../../services/units";
import Management from "../../services/management";
import {
  getLoginDataFromStorage,
  parseNewLine,
} from "../../helpers/storage";

export default {
  name: "AtendMeusDepart",
  data: () => ({
    queues: null,
    type: 'solicitation',
    selectedQueue: null,
    codAtendente: null,
    errorQueue: null,
    callsByDepartment: null,
    errorCallsByDepartment: null,
    popupAtivo: false,
    idPopup: null,
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    const user1 = user.userData;
    const { CODATENDENTE } = user1;

    this.codAtendente = CODATENDENTE;

    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getQueues(CODATENDENTE)
      .then(({ data }) => {
        this.queues = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorQueue = response.data;
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    getCallsByDepartment() {
      this.$vs.loading({ text: "Carregando..." });
      const { ...user } = getLoginDataFromStorage();
      const user1 = user.userData;
      const { CODATENDENTE } = user1;

      new Management()
        .getCallsByDepartment(CODATENDENTE, this.selectedQueue)
        .then(({ data }) => {
          this.callsByDepartment = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorCallsByDepartment = response.data;
          }
          this.$vs.loading.close();
        });
    },

    handleClickDetails(indextr) {
      this.idPopup = indextr;
      setTimeout(() => {
        this.popupAtivo = true;
      }, 100);
    },

    parseNewLine,
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}

.tituloAtendimento {
  padding-left: 0.2em;
  margin-top: 5px;
}

.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 440px) {
  .percentageInput {
    width: 9em;
  }
}

@media screen and (max-width: 400px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
