<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
    <div class="subContainer">
      <vs-row class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'file-contract']" />
          <h2 class="tituloAtendimento">
            Contrato de Direitos Autorais
          </h2>
        </div>
      </vs-row>
      
      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Filial <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Filial"
                rules="required"
                 
              >
                <vs-select
                  class="selectExample"
                  placeholder="Selecione uma Filial"
                  v-model="selectedUnit"
                >
                  <vs-select-item
                    :key="item.CODFILIAL"
                    :value="item.CODFILIAL"
                    :text="item.POLO"
                    v-for="item in units"
                  />
                </vs-select>
               
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>
      </div>

      <div v-if="selectedUnit">
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Nome Completo <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Nome Completo"
                rules="required"
                 
              >
                <vs-input v-model="name" placeholder="Digite o Nome Completo" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Telefone <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Telefone"
                rules="required|min:15"
                 
              >
                <vs-input v-model="phone" placeholder="Digite o Telefone" v-mask="'(##) #####-####'" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Endereço/Número <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Endereço/Número"
                rules="required"
                 
              >
                <vs-input
                  v-model="address"
                  placeholder="Digite o Endereço com Número"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider> 
            </fieldset>
          </vs-col>          

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Bairro/Complemento <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Bairro/Complemento"
                rules="required"
                 
              >
                <vs-input
                  v-model="complement"
                  placeholder="Digite o Bairro e Complemento"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Enviar Documento <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Enviar Documento"
                  ref="provider"
                  rules="required"
                   
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                   
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Cidade/Estado <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Cidade/Estado"
                rules="required"
                 
              >
                <vs-input
                  v-model="city"
                  placeholder="Digite Cidade e Estado"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>CEP <b style="color: red;">*</b></legend>
               <ValidationProvider
                name="CEP"
                rules="required"
                 
              >
                <vs-input
                  v-model="cep"
                  placeholder="Digite o CEP"
                  class="selectExampleDouble"
                  v-mask="'#####-###'"
                />
                 
              </ValidationProvider>
            </fieldset>   
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>PIS <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="PIS"
                rules="required"
                 
              >
                <vs-input v-model="pis" placeholder="Digite o PIS" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>CNPJ <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="CNPJ"
                rules="required"
                                 
              >
                <vs-input v-model="cnpj" v-mask="'##.###.###/####-##'" placeholder="Digite o CNPJ" class="selectExampleDouble"/>
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Operação <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Operação"
                rules="required"
                                 
              >
                <vs-input v-model="operation" v-mask="'###'" placeholder="Digite a Operação" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Banco <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Banco"
                rules="required"
                 
              >
                <vs-input v-model="bank" placeholder="Digite o Banco" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Agência <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Agência"
                rules="required"
                                 
              >
                <vs-input v-model="agency" v-mask="'####'" placeholder="Digite a Agência" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Conta <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Conta"
                rules="required"
                                 
              >
                <vs-input v-model="account" v-mask="'########-#'" placeholder="Digite a Conta" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Razão Social <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Razão Social"
                rules="required"
                 
              >
                <vs-input
                  v-model="corporateName"
                  placeholder="Digite a Razão Social"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Valor do Serviço <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Valor do Serviço"
                rules="required"
                 
              >
                <vs-input
                  v-model="serviceValue"
                  placeholder="Digite o Valor do Serviço"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Duração do Contrato <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Duração do Contrato"
                rules="required"
                 
              >
                <vs-input
                  v-model="contractDuration"
                  placeholder="Digite a Duração do Contrato"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Local da Prestação <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Local Prestação"
                rules="required"
                 
              >  
                <vs-input
                  v-model="localProvision"
                  placeholder="Digite o Local da Prestação"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Prazo <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Prazo"
                rules="required"
                 
              >  
                <vs-input v-model="deadline" placeholder="Digite o Prazo" class="selectExampleDouble" />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Forma de Pagamento <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Forma de Pagamento"
                rules="required"
                 
              >  
                <vs-input
                  v-model="paymentMethod"
                  placeholder="Digite a Forma de Pagamento"
                  class="selectExampleDouble"
                />
               
              </ValidationProvider>
            </fieldset>
          </vs-col>          
        </vs-row> 
        
        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider
              name="Descrição do Material Produzido"
              rules="required|max: 500"
                
            >
              <vs-textarea
                rows="11"
                label="Descrição do Material Produzido *"
                class="textArea"
                height="15em"
                counter="500"
                v-model="materialDescription"
              />
                
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Assunto <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Assunto"
                rules="required"
                  
              >  
                <vs-input v-model="subject" placeholder="Digite o Assunto" class="selectExample" />
                  
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider
              name="Texto Solicitação"
              rules="required|max: 500"
                
            >
              <vs-textarea
                rows="11"
                label="Texto Solicitação *"
                class="textArea"
                height="15em"
                counter="500"
                v-model="solicitation"
              />
                
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center;">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>
            
            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>            
        </vs-row>
      </div>                        
    </div>
    </ValidationObserver>
  </section>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import { getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
export default {
  name: "ContratoDeDireitosAutorais",
  data: () => ({
    files: null,
    base64Image: null,
    units: null,
    errorUnit: null,
    selectedUnit: null,
    name : null,
    phone : null,
    address : null,
    complement : null,
    cep : null,
    city: null,
    pis : null,
    cnpj : null,
    operation : null,
    bank : null,
    agency : null,
    account : null,
    corporateName : null,
    serviceValue : null,
    contractDuration : null,
    localProvision : null,
    deadline: null,
    paymentMethod : null,
    materialDescription : null,
    subject : null,
    solicitation : null,
    idAttendance: null,
    errorIdAttendance: null,
    visibleButton: false,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      });
  },

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A7-2');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.attachment = this.base64Image;      
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nNOME COMPLETO: ${this.name}\nTELEFONE: ${this.phone}\nENDEREÇO E NÚMERO: ${this.address}\nCOMPLEMENTO E BAIRRO: ${this.complement}\nCIDADE/ESTADO ${this.city}\nCEP: ${this.cep}\nPIS: ${this.pis}\nCNPJ: ${this.cnpj}\nOPERAÇÃO: ${this.operation}\nBANCO: ${this.bank}\nAGÊNCIA: ${this.agency}\nCONTA: ${this.account}\nRAZÃO SOCIAL: ${this.corporateName}\nVALOR DO SERVIÇO: ${this.serviceValue}\nDURAÇÃO DO CONTRATO: ${this.contractDuration}\nLOCAL DA PRESTAÇÃO: ${this.localProvision}\nPRAZO: ${this.deadline}\nFORMA DE PAGAMENTO: ${this.paymentMethod}\nDESCRIÇÃO DO MATERIAL PRODUZIDO: ${this.materialDescription}\nASSUNTO: ${this.subject}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.name = null;
      this.phone = null;
      this.address = null;
      this.complement = null;
      this.cep = null;
      this.city = null;
      this.pis = null;
      this.cnpj = null;
      this.operation = null;
      this.bank = null;
      this.agency = null;
      this.account = null;
      this.corporateName = null;
      this.serviceValue = null;
      this.contractDuration = null;
      this.localProvision = null;
      this.deadline = null;
      this.paymentMethod = null;
      this.materialDescription = null;
      this.subject = null;
      this.solicitation = null;
      this.visibleButton = false;
      this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  }
};
</script>
