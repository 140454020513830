import { https } from './api';

export default class {
    constructor() {
        this.instance = https()
    }

    sendEmailCancellation = (data) => {
        return this.instance({
            method: 'POST',
            url: '/email',
            timeout: 10000,
            data,
        })
    }
}