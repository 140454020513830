<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'chart-bar']" />
              <h2 class="tituloAtendimento">                
                 Processo de Regulação Escolar
              </h2>
            </div>
          </vs-row>

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Selecione uma regulação <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Regulação"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExample"                      
                      placeholder="Selecione uma opção"
                      v-model="regulation"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in options1"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Selecione uma Filial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExample"
                      v-model="selectedUnit"                      
                      placeholder="Selecione uma opção"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="(item, index) in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>                                            
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Selecione um Curso <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Curso"
                    rules="required"
                  >
                    <vs-select
                      class="selectExample"
                      v-model="nameCourse"
                      placeholder="Selecione uma opção"
                    >
                      <vs-select-item
                        :key="item.CODCURSO"
                        :value="item.NOME"
                        :text="item.NOME"
                        v-for="item in courses"
                      />                                    
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>   
          </div>           
        </ValidationObserver>
      </div>
    </div>
  </section>  
</template>

<script>
import Units from "../../../services/units";
import { getLoginDataFromStorage, poloTextFormat } from "../../../helpers/storage.js";
import Attendance from "../../../services/attendance";
import EducacionalModel from "../../../models/educacional/educacional.js";

export default {
  name: "RegulacaoEscolar",
  data: () => ({
    units: null,
    selectedUnit: null,
    error: null,
    regulation: "",
    nameCourse: null,
    idAttendance: null,
    errorIdAttendance: null,
    options1: [
      { text: "Recredenciamento", value: "RECREDENCIAMENTO" },
      { text: "Credenciamento", value: "CREDENCIAMENTO" },
      { text: "Readequação", value: "READEQUAÇÃO" },
    ],
    courses: [],
    errorCourses: null,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response)
        }
      });
    this.$vs.loading({ text: "Carregando..." });
    new Units()
    .getAllCourses()
    .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
        if (response && response.data) {
          this.errorCourses = response.data;
          console.log(response)
        }
    })
    .finally(() => {
        this.$vs.loading.close();
    });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E2");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nPROCESSO DE REGULAÇÃO ESCOLAR: ${this.regulation}\nCURSO: ${this.nameCourse}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },

    resetForm() {
      this.regulation = null;
      this.selectedUnit = null;
      this.nameCourse = null;     
      
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
