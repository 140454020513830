<template>
  <section>
    <NavBarAtendimentos />   
    <div class="columns">
      <SideBarAtendimentos class="menu" />                      
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'address-card']" />
              <h2 class="tituloAtendimento">
                Alteração de Cadastro
              </h2>
            </div>
          </vs-row>

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>
          </div>

          <div>          
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>RA do Aluno <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="RA"
                    rules="required|numeric"
                     
                  >
                    <vs-input
                      v-model="valueStudent"
                      placeholder="Digite o RA do Aluno"
                      class="selectExample"
                    />  
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                    <legend>Anexo <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Anexo"
                      ref="provider"
                      rules="required"
                       
                    >
                      <input class="selectExample" ref="image" type="file" @change="onFileUploadStudent">
                       
                    </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Texto Solicitação *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitationStudent"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>                                                       

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid || !visibleButton"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>    
    </div>
  </section>
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import AdministrativoModel from "../../../models/administrativo/administrativo";

export default {
  name: "AlteracaoCadastro",
  data: () => ({
    filesStudent: null,
    base64ImageStudent: null,
    valueStudent: null,
    solicitationStudent: null,
    counterDanger: false,
    idAttendance: null,
    errorIdAttendance: null,
    visibleButton: false,
  }),

  methods: {

    async onFileUploadStudent (event) {
      this.filesStudent = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.filesStudent);   
      if (valid) {                                   
        this.createBase64ImageStudent(this.filesStudent);
        this.visibleButton = true;
      }
    },

    createBase64ImageStudent(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64ImageStudent = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A2');

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = "ALTERAÇÃO DE CADASTRO"; 
      payload.data.ra = this.valueStudent;
      payload.data.attendantId = attendantId.toString();
      payload.data.attachment = this.base64ImageStudent;
      payload.data.fileName = this.filesStudent.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA: ${this.valueStudent}\nSOLICITAÇÃO: ${this.solicitationStudent}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.valueStudent = null;
      this.solicitationStudent = null;      
      this.visibleButton = false;
      this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },    
  },  
};
</script>
