<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'house-user']" />
            <h2 class="tituloAtendimento">Responsáveis Por Unidade QI</h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="responsibleBranch"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Filial </vs-th>
                    <vs-th class="table_th"> Responsável </vs-th>
                    <vs-th class="table_th"> Email Responsável </vs-th>
                    <vs-th class="table_th"> Email Filial </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        {{ data[indextr].NOMEFANTASIA }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].RESPONSÁVEL }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].EMAIL_RESPONSAVEL }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].EMAIL }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Units from "../../services/units";
export default {
  name: "ResponsavelFilial",
  data: () => ({
    responsibleBranch: [],
    errorResponsibleBranch: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getAffiliateAndResponsible()
      .then(({ data }) => {
        this.responsibleBranch = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorResponsibleBranch = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #D4D4D4;
}
</style>
