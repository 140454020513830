<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div class="container">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'retweet']" />
            <h2 class="tituloAtendimento">
              Solicitação de Bolsa
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Nome do Colaborador <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nome Colaborador"
                  rules="required"
                   
                >    
                  <vs-select
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample"
                    placeholder="Digite o Nome do Colaborador"
                    v-model="indexSelected"
                  >
                    <vs-select-item
                      :key="index"
                      :value="index"
                      :text="item.NOME"
                      v-for="(item, index) in employees"
                    />
                  </vs-select>
                   
                </ValidationProvider>  
              </fieldset>
            </vs-col>
          </vs-row>  

          <div v-if="indexSelected != null">          
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial <b style="color: red;">*</b></legend>
                  <vs-input
                    disabled
                    class="selectExampleDouble"
                    v-model="employees[indexSelected].FILIAL"
                  />
                </fieldset>
              </vs-col> 

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Portador da Bolsa <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Portador da Bolsa"
                    rules="required"
                     
                  >
                    <vs-select                
                      placeholder="Selecione o Portador da Bolsa"
                      class="selectExampleDouble"
                      v-model="carrier"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in carrierData"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>             
            </vs-row>

            <vs-row v-if="carrier == 'Dependente'" class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Grau do Dependente <b style="color: red;">*</b></legend>                    
                  <ValidationProvider
                    name="Grau do Dependente"
                    rules="required"
                     
                  >
                    <vs-input 
                      placeholder="Digite o Grau do Dependente"
                      v-model="carrierTyped"
                      class="selectExampleDouble" 
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial de Matrícula <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial de Matrícula"
                    rules="required"
                     
                  >
                    <vs-select
                      autocomplete
                      placeholder="Selecione a Filial de Matrícula"
                      class="selectExampleDouble"
                      v-model="selectedUnit"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="(item, index) in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Curso Pretendido <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Curso Pretendido"
                    rules="required"
                     
                  >
                    <vs-input
                      v-model="course"
                      class="selectExampleDouble"
                      placeholder="Digite o Curso Pretendido"
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>            
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Turno <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Turno"
                    rules="required"
                     
                  >
                    <div class="boxTurn">
                      <span v-for="(shifts, index) in selectedShift" :key="index">
                        <input type="checkbox" class="checkboxTurn" :id="index" v-model="shift" :value="shifts.value"/>
                        <label :for="index">{{ shifts.value }}</label>
                      </span>
                    </div>              
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>   

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data Início <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Data Início"
                    rules="required"
                     
                  >
                    <vs-input
                      type="date"
                      class="selectExampleDouble"
                      v-model="startDate"
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>         
            </vs-row>


            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Observação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Observação"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>
              
            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>         
          </div>
        </div>
      </div>
      </ValidationObserver>    
    </div>
  </section>
</template>

<script>
import Person from "../../../services/person";
import Units from "../../../services/units";
import Attendance from "../../../services/attendance";
import PessoalModel from "../../../models/pessoal/pessoal.js";
import {
  getLoginDataFromStorage,
  convertDate
} from "../../../helpers/storage.js";

export default {
  name: "SolicitacaoBolsa",
  data: () => ({
    indexSelected: null,
    employees: [],
    errorEmployees: null,
    idAttendance: null,
    errorIdAttendance: null,
    carrier: null,
    carrierTyped: null,
    shift: [],
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    units: null,
    errorUnits: null,
    selectedUnit: null,
    startDate: null,
    solicitation: null,
    course: null,
    carrierData: [
      { text: "Eu mesmo", value: "Eu mesmo" },
      { text: "Dependente", value: "Dependente" },
    ],
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Person()
      .getEmployees()
      .then(({ data }) => {
        this.employees = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorEmployees = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnits = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      let carrier = this.carrierTyped == null ? this.carrier : `${this.carrier}\nGRAU DO DEPENDENTE: ${this.carrierTyped}`;

      const payload = new PessoalModel().getDataByAttendanceId('P4');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME/CHAPA: ${this.employees[this.indexSelected].NOME} - ${this.employees[this.indexSelected].CHAPA}\nPORTADOR: ${carrier}\nCURSO PRETENDIDO: ${this.course}\nFILIAL DE CÓDIGO: ${this.selectedUnit}\nTURNOS: ${this.shift}\nDATA: ${convertDate(this.startDate)}\nOBSERVAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },
    resetForm() {
      this.indexSelected = null;
      this.carrierTyped = null;
      this.carrier = null;
      this.course = null;
      this.selectedUnit = null;
      this.shift = [];
      this.startDate = null;
      this.solicitation = null;      

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    inputChange(event) {
      return event.target.value;
    },
  },
};
</script>

<style scoped>
.checkboxTurn {
  margin: 1em;
  margin-bottom: .5em;
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: .9em;
  }
}
</style>
