<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'business-time']" />
            <h2 class="tituloAtendimento">
              QI em Tempo Real - Educacional
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-card>
            <vs-row class="top_chart">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'user-graduate']" />
                <h2 class="title_chart">Alunos por Tipo de Curso</h2>
              </div>
            </vs-row>
            <p class="totalStudents">
              <font-awesome-icon :icon="['fas', 'graduation-cap']" />
              Total: <strong>{{ totalStudents }} aluno(s)</strong>
            </p>
            <vs-divider/>
            <pie-chart :data="courseTypeStudents"> </pie-chart>
          </vs-card>

          <vs-card>
            <vs-row class="top_chart">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'chart-line']" />
                <h2 class="title_chart">Retenção por Polo - Mês atual</h2>
              </div>
            </vs-row>
            <vs-row class="pd-table">
              <vs-col>
                <fieldset>
                  <vs-table
                    class="table_intranet"
                    max-items="20"
                    hoverFlat
                    :data="retention"
                  >
                    <template slot="thead">
                      <vs-th> Filial </vs-th>
                      <vs-th> Liberados </vs-th>
                      <vs-th> Suspensos </vs-th>
                      <vs-th> Cancelados </vs-th>
                      <vs-th> Matrículas Novas </vs-th>
                      <vs-th> Rematrículas </vs-th>
                      <vs-th> Retornos Liberação </vs-th>
                      <vs-th> Retornos Suspensão </vs-th>
                      <vs-th> Total de Matrículas </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <strong>FL{{ tr.POLO }}</strong>
                        </vs-td>

                        <vs-td> {{ tr.LIBERADOS }} aluno(s) </vs-td>

                        <vs-td> {{ tr.SUSPENSOS }} aluno(s) </vs-td>

                        <vs-td> {{ tr.CANCELADOS }} aluno(s) </vs-td>

                        <vs-td> {{ tr.MATRÍCULAS_NOVAS }} aluno(s) </vs-td>

                        <vs-td> {{ tr.REMATRÍCULAS_NOVAS }} aluno(s) </vs-td>
                        <vs-td> {{ tr.RETORNOS_LIBERACAO }} aluno(s) </vs-td>
                        <vs-td> {{ tr.RETORNOS_SUSPENSAO }} aluno(s) </vs-td>
                        <vs-td>
                          <strong>{{ tr.MATRICULADOS_TOTAL }} aluno(s)</strong>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </fieldset>
              </vs-col>
            </vs-row>
          </vs-card>

          <vs-card>
            <vs-row class="top_chart">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'file-contract']" />
                <h2 class="title_chart">Matrículas Concluídas no Mês</h2>
              </div>
            </vs-row>
            <column-chart
              xtitle="Polo"
              ytitle="Qtd. de Matrículas Novas"
              :data="matriculationMAInMonth"
            >
            </column-chart>
            <column-chart
              xtitle="Polo"
              ytitle="Qtd. de Rematrículas"
              :data="matriculationRCInMonth"
            >
            </column-chart>
          </vs-card>

          <vs-card>
            <vs-row class="top_chart">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'clipboard']" />
                <h2 class="title_chart">Matriculados por Curso</h2>
              </div>
            </vs-row>
            <bar-chart
              height="600px"
              :colors="['#9b0e1c']"
              :data="enrollCourse"
            >
            </bar-chart>
          </vs-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import Vue from "vue";
import EnrollCourses from "../../services/realTime";
import CourseTypeStudents from "../../services/realTime";
import MatriculationMAInMonth from "../../services/realTime";
import MatriculationRCInMonth from "../../services/realTime";
import Retention from "../../services/realTime";

Vue.use(Chartkick.use(Chart));

export default {
  name: "TempoRealEducacional",
  data: () => ({
    enrollCourse: [],
    courseTypeStudents: [],
    matriculationMAInMonth: [],
    matriculationRCInMonth: [],
    retention: [],
    error: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new EnrollCourses()
      .getEnrollCourses()
      .then(({ data }) => {
        this.enrollCourse = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new CourseTypeStudents()
      .getCourseTypeStudents()
      .then(({ data }) => {
        this.courseTypeStudents = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new MatriculationMAInMonth()
      .getMatriculationMAInTheCurrentMonth()
      .then(({ data }) => {
        this.matriculationMAInMonth = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new MatriculationRCInMonth()
      .getMatriculationRCInTheCurrentMonth()
      .then(({ data }) => {
        this.matriculationRCInMonth = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando..." });
    new Retention()
      .getRetentionData()
      .then(({ data }) => {
        this.retention = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
        this.$vs.loading.close();
      });
  },

  computed: {
    totalStudents() {
      return this.retention.reduce((a, b) => {
        return a - -b.MATRICULADOS_TOTAL;
      }, 0);
    },
  },
};
</script>

<style scoped>
.top_chart {
  height: 3.125em;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
  color: #9b0e1c;
  font-size: 1.55em;
}

.title_chart {
  font-size: 0.9em;
  padding-left: 0.5em;
}

.totalStudents {
  border: 1px solid;
  border-radius: 5px;
  padding: 1%;
  text-align: center;
  font-size: 1.65em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

@media screen and (max-width: 400px) {
  .totalStudents {
    font-size: 1.45em;
  }
}
</style>
