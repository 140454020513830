var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('vs-row',{staticClass:"pd-table"},[_c('vs-col',[(this.callsInTheUserQueue == '')?_c('fieldset',[_c('p',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle']}}),_vm._v(" Nenhum atendimento encontrado ")],1)]):_c('vs-table',{staticClass:"table_intranet",attrs:{"data":_vm.callsInTheUserQueue,"hoverFlat":"","search":"","pagination":"","max-items":"10"},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('vs-popup',{staticStyle:{"z-index":"999999 !important"},attrs:{"title":data[_vm.idPopup] ? 'Atendimento - ' + data[_vm.idPopup].CODATENDIMENTO : 'Atendimento',"active":_vm.popupAtivo},on:{"update:active":function($event){_vm.popupAtivo=$event}}},[_c('vs-col',[_c('vs-tabs',{attrs:{"alignment":"center"}},[_c('vs-tab',{attrs:{"icon":"library_add","label":"Solicitação"},on:{"click":function($event){return _vm.solicitationAtt()}}}),_c('vs-tab',{attrs:{"icon":"group","label":"Discussão"},on:{"click":function($event){return _vm.discussionAtt()}}}),_c('vs-tab',{attrs:{"icon":"check_circle","label":"Solução"},on:{"click":function($event){return _vm.solutionAtt()}}})],1)],1),(_vm.type == 'solicitation')?_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                    data[_vm.idPopup] ? 
                    _vm.parseNewLine(data[_vm.idPopup].SOLICITACAO) : 
                    ''
                  )}})]):_vm._e(),(_vm.type == 'discussion')?_c('div',[(!_vm.editDiscussion)?_c('div',[_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                        data[_vm.idPopup] ? 
                        _vm.parseNewLine(data[_vm.idPopup].DISCUSSAO) : 
                        ''
                      )}})])]):_c('div',[_c('fieldset',[_c('legend',[_vm._v("Digite a Discussão "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('vs-textarea',{staticClass:"textArea",attrs:{"rows":"11","label":"","height":"15em"},model:{value:(_vm.discussion),callback:function ($$v) {_vm.discussion=$$v},expression:"discussion"}})],1),_c('vs-button',{staticClass:"button-discussion",attrs:{"color":"success","type":"gradient","disabled":!_vm.discussion},on:{"click":function($event){return _vm.updateDiscussion(data[_vm.idPopup])}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}}),_vm._v(" "+_vm._s(data[_vm.idPopup].DISCUSSAO == 'SEM DISCUSSÃO' ? 'Adicionar Discussão' : 'Atualizar Discussão')+" ")],1)],1)]):_vm._e(),(_vm.type == 'solution')?_c('div',[(!_vm.editSolution)?_c('div',[_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                        data[_vm.idPopup] ? 
                        _vm.parseNewLine(data[_vm.idPopup].SOLUCAO) : 
                        ''
                      )}})])]):_c('div',[_c('fieldset',[_c('legend',[_vm._v("Digite a Solução "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('vs-textarea',{staticClass:"textArea",attrs:{"rows":"11","label":"","height":"15em"},model:{value:(_vm.solution),callback:function ($$v) {_vm.solution=$$v},expression:"solution"}})],1),_c('vs-button',{staticClass:"button-solution",attrs:{"color":"success","type":"gradient","disabled":!_vm.solution},on:{"click":function($event){return _vm.updateSolution(data[_vm.idPopup])}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}}),_vm._v(" "+_vm._s(data[_vm.idPopup].SOLUCAO == 'SEM SOLUÇÃO' ? 'Adicionar Solução' : 'Atualizar Solução')+" ")],1)],1)]):_vm._e()],1),_vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleClickDetails(indextr)}}},[_vm._v(_vm._s(data[indextr].CODATENDIMENTO))])]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].DATA_ABERTURA)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].ASSUNTO)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].CLASSIFICACAO)+" ")])],1)})]}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" ATENDIMENTO ")]),_c('vs-th',[_vm._v(" DATA ABERTURA ")]),_c('vs-th',[_vm._v(" ASSUNTO ")]),_c('vs-th',[_vm._v(" CLASSIFICAÇÃO ")])],1)],2)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }