<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'sticky-note']" />
              <h2 class="tituloAtendimento">
                Solicitar Correção De Problemas Estruturais
              </h2>
            </div>
          </vs-row>

          <div>
            <vs-row class="pd-t">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"
                />
              </vs-col>
            </vs-row>
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Problema <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Problema estrutural"
                    rules="required"
                  >
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione o Problema"
                      v-model="structuralProblemSelected"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in structuralProblemsTypes"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial"
                    rules="required"
                  >
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione uma Filial"
                      v-model="selectedUnit"
                    >
                      <vs-select-item
                        :key="item.CODFILIAL"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="item in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Observação"
                  rules="required|max: 500"
                >
                  <vs-textarea
                    rows="11"
                    label="Observação"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Units from "../../services/units";
import Attendance from "../../services/attendance";
import {
  getLoginDataFromStorage,
  poloTextFormat,
} from "../../helpers/storage.js";
import AdministrativoModel from "../../models/administrativo/administrativo";

export default {
  name: "ProblemasEstruturais",
  data: () => ({
    structuralProblemSelected: null,
    idAttendance: null,
    errorIdAttendance: null,
    selectedUnit: null,
    units: null,
    solicitation: "",
    errors: [],
    error: null,
    respAttendantId: null,
    structuralProblemsTypes: [
      { text: "ESTRUTURA FISICA", value: "ESTRUTURA FISICA" },
      { text: "ESTRUTURA TECNOLÓGICA", value: "ESTRUTURA TECNOLÓGICA" },
      { text: "OUTROS", value: "OUTROS" },
    ],
  }),
  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },
  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId("A12");

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = this.structuralProblemSelected.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nPROBLEMA: ${
        this.structuralProblemSelected
      }\nFILIAL: ${poloTextFormat(
        this.selectedUnit,
        this.units
      )}\nSOLICITAÇÃO: ${this.solicitation.toString()} `;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },
    resetForm() {
      this.selectedUnit = "";
      this.structuralProblemSelected = "";
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>


<style scoped>
@media screen and (max-width: 900px) {
  .pd-c {
    padding-top: 1em;
  }
}
</style>