<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div class="subContainer">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'university']" />
            <h2 class="tituloAtendimento">Transferência de Unidade</h2>
          </div>
        </vs-row>
        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Nome Colaborador <b style="color: red">*</b></legend>
                <ValidationProvider name="Nome Colaborador" rules="required">
                  <vs-select
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample"
                    placeholder="Digite o Nome do Colaborador"
                    v-model="indexSelectedSub"
                  >
                    <vs-select-item
                      :key="index"
                      :value="index"
                      :text="item.NOME"
                      v-for="(item, index) in data.employees"
                    />
                  </vs-select>
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <div v-if="indexSelectedSub != null">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Setor <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    class="selectExampleDouble"
                    v-model="data.employees[indexSelectedSub].SETOR"
                  />
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Horário <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    class="selectExampleDouble"
                    v-model="data.employees[indexSelectedSub].HORARIO"
                  />
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Escola Atual <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    class="selectExampleDouble"
                    v-model="data.employees[indexSelectedSub].FILIAL"
                  />
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Escola destino <b style="color: red">*</b></legend>
                  <ValidationProvider name="Escola destino" rules="required">
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione a Escola destino"
                      v-model="selectedUnit"
                      v-on:input="getJobsUnits()"
                    >
                      <vs-select-item
                        :key="item.CODFILIAL"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="item in data.units"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Área destino <b style="color: red">*</b></legend>
                  <ValidationProvider name="Área destino" rules="required">
                    <vs-select
                      autocomplete
                      class="selectExampleDouble"
                      placeholder="Selecione a Área destino"
                      v-model="selectedArea"
                      @input-change="inputChange"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.SECAO"
                        :text="item.SECAO"
                        v-for="(item, index) in data.jobsFilial"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data de Início <b style="color: red">*</b></legend>
                  <ValidationProvider name="Data de Início" rules="required">
                    <vs-input
                      type="date"
                      class="selectExampleDouble"
                      v-model="startDate"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Dias <b style="color: red">*</b></legend>
                  <ValidationProvider name="Dias" rules="required">
                    <div class="boxTurn">
                      <span v-for="(weeks, index) in selectedWeek" :key="index">
                        <input
                          type="checkbox"
                          class="checkboxTurn"
                          :id="index"
                          v-model="week"
                          :value="weeks.value"
                        />{{ weeks.value }}
                      </span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Novo Horário <b style="color: red">*</b></legend>
                  <ValidationProvider name="Novo Horário" rules="required">
                    <div class="horario">
                      <vs-input
                        class="input-horario"
                        v-model="time[0]"
                        v-mask="'##:##'"
                      />
                      <span>às</span>
                      <vs-input
                        class="input-horario"
                        v-model="time[1]"
                        v-mask="'##:##'"
                      />
                      <span>e</span>
                      <vs-input
                        class="input-horario"
                        v-model="time[2]"
                        v-mask="'##:##'"
                      />
                      <span>às</span>
                      <vs-input
                        class="input-horario"
                        v-model="time[3]"
                        v-mask="'##:##'"
                      />
                    </div>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Sábado <b style="color: red">*</b></legend>
                  <ValidationProvider name="Sábado" rules="required">
                    <vs-select
                      class="selectExample"
                      placeholder="Selecione"
                      v-model="indexSelectedWeekend"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.weekend"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row
              v-if="
                indexSelectedWeekend == 'Todos os Sabados' ||
                indexSelectedWeekend == 'Sábados alternados'
              "
              class="pd-t"
            >
              <vs-col>
                <fieldset>
                  <legend>Horário Sábado <b style="color: red">*</b></legend>
                  <ValidationProvider name="Horário Sábado" rules="required">
                    <div class="horario">
                      <vs-input
                        class="input-horario"
                        v-model="timeSaturday[0]"
                        v-mask="'##:##'"
                      />
                      <span>às</span>
                      <vs-input
                        class="input-horario"
                        v-model="timeSaturday[1]"
                        v-mask="'##:##'"
                      />
                      <span>e</span>
                      <vs-input
                        class="input-horario"
                        v-model="timeSaturday[2]"
                        v-mask="'##:##'"
                      />
                      <span>às</span>
                      <vs-input
                        class="input-horario"
                        v-model="timeSaturday[3]"
                        v-mask="'##:##'"
                      />
                    </div>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Turno <b style="color: red">*</b></legend>
                  <ValidationProvider name="Turno" rules="required">
                    <div class="boxTurn">
                      <span
                        v-for="(shifts, index) in selectedShift"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          class="checkboxTurn"
                          :id="index"
                          v-model="shift"
                          :value="shifts.value"
                        />{{ shifts.value }}
                      </span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider name="Observação" rules="required|max: 500">
                  <vs-textarea
                    label="Observação"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="solicitation"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>

                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </section>
</template>

<script>
import Person from "../../../../../services/person";
import Units from "../../../../../services/units";
import {
  getLoginDataFromStorage,
  convertDate,
  poloTextFormat,
} from "../../../../../helpers/storage";
import Attendance from "../../../../../services/attendance";
import PessoalModel from "../../../../../models/pessoal/pessoal.js";
export default {
  name: "TransfUnidade",
  data: () => ({
    indexSelectedSub: null,
    selectedUnit: null,
    selectedArea: null,
    startDate: null,
    week: [],
    indexSelectedWeekend: null,
    time: [],
    timeSaturday: [],
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
    ],
    shift: [],
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    data: {
      employees: null,
      errorEmployees: null,
      units: null,
      errorUnits: null,
      fields: null,
      errorFields: null,
      jobsFilial: null,
      errorJobsFilial: null,
      weekend: [
        { text: "Não trabalha Sábados", value: "Não trabalha Sábados" },
        { text: "Todos os Sábados", value: "Todos os Sabados" },
        { text: "Sábados alternados", value: "Sábados alternados" },
      ],
    },
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Person()
      .getEmployees()
      .then(({ data }) => {
        this.data.employees = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.errorEmployees = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.data.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.errorUnits = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.resetForm();
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new PessoalModel().getDataByAttendanceId("P3-2");

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.subject =
        "RH - MOVIMENTAÇÃO DE PESSOAL - TRANSFERENCIA DE UNIDADE";
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME/CHAPA: ${
        this.data.employees[this.indexSelectedSub].NOME
      } - ${this.data.employees[this.indexSelectedSub].CHAPA}\nAREA ATUAL: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nNOVA AREA: ${this.selectedArea}\nESCOLA ATUAL: ${
        this.data.employees[this.indexSelectedSub].FILIAL
      }\nESCOLA DESTINO: ${poloTextFormat(
        this.selectedUnit,
        this.data.units
      )}\nDATA DE INICIO: ${convertDate(this.startDate)}\nHORARIO ATUAL: ${
        this.data.employees[this.indexSelectedSub].HORARIO
      }\nNOVO HORÁRIO: ${this.time[0]} ATÉ ${this.time[1]} E DAS ${
        this.time[2]
      } ATÉ ${this.time[3]}\nDIAS: ${this.week}\nTURNO: ${this.shift}\n${
        this.indexSelectedWeekend
      }\nHORARIO SABADO: ${this.timeSaturday[0]} ATÉ ${
        this.timeSaturday[1]
      } E DAS ${this.timeSaturday[2]} ATÉ ${
        this.timeSaturday[3]
      }\nOBSERVAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.resetForm;
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.selectedUnit = "";
      this.selectedArea = "";
      this.startDate = null;
      this.week = [];
      this.solicitation = null;
      this.shift = [];
      this.indexSelectedWeekend = null;
      this.time = [];
      this.timeSaturday = [];
      this.indexSelectedSub = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getJobsUnits() {
      new Units()
        .getJobsUnits(this.selectedUnit)
        .then(({ data }) => {
          this.data.jobsFilial = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.data.errorJobsFilial = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    inputChange(event) {
      return event.target.value;
    },
  },
};
</script>

<style scoped>
.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 580px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .boxTurn {
    flex-direction: column;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 440px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
