<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu"/>
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'graduation-cap']" />
            <h2 class="tituloAtendimento">
              Ramais QI
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="newRamal"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Usuário </vs-th>
                    <vs-th class="table_th"> Departamento </vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        {{ data[indextr].name }} <br><br>
                        <font-awesome-icon :icon="['fas', 'headset']" />
                        {{ data[indextr].username }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].service ? data[indextr].service.name : '-' }}
                      </vs-td>

                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Ramais from "../services/ramais";
export default {
  name: "Ramais",
  data: () => ({
    ramais: [],
    newRamal: [],
    filteredRamais: [],
    errorRamais: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Ramais()
      .getRamais()
      .then(({ data }) => {
        this.ramais = Array.isArray(data) ? data : [data];        
        this.filteredRamais = this.ramais.filter(function(ramal) {
          return ramal.hideOnAgenda == false;
        });
        
        const camposDesejados = ['name', 'username', 'hideOnAgenda'];

        this.newRamal = this.filteredRamais.map(
          (objeto) => {
          const novoObjeto = {};
          camposDesejados.forEach((campo) => {
            novoObjeto[campo] = objeto[campo];
          });
          novoObjeto.service = objeto.Groups[0];
          return novoObjeto;
        });
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorRamais = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #D4D4D4;
}
</style>