<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu"/>
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'hospital']" />
            <h2 class="tituloAtendimento">
              Companhias Associadas 
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>               
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination       
                  search                
                  :data="associatedCompanies"
                >
                  <template slot="thead">
                    <vs-th sort-key="CURSO" class="table_th"> Empresa </vs-th>
                    <vs-th class="table_th" width="15%"> Cidade </vs-th>
                    <vs-th class="table_th"> Nome Bolsa </vs-th>
                    <vs-th class="table_th"> Percentual </vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >                     
                      <vs-td>
                        {{ data[indextr].EMPRESA }} <br><br>
                        <b>CNPJ:</b> {{ data[indextr].CNPJ != null ? data[indextr].CNPJ : '-' }} <br><br>
                        <b><font-awesome-icon :icon="['fas', 'phone-square']" /></b> {{ data[indextr].TELEFONE != null ? data[indextr].TELEFONE : '-' }} <br>
                        <b><font-awesome-icon :icon="['fas', 'envelope-square']" /></b> {{ data[indextr].EMAIL != null ? data[indextr].EMAIL : '-' }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].CIDADE != null ? data[indextr].CIDADE : '-' }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].NOMEBOLSA }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].PERCENTUAL * 1 + "%" }}
                      </vs-td>                                                 
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>                
      </div>
    </div>
  </section>
</template>

<script>
import Management from "../services/management";
export default {
  name: "EmpresasConveniadas",
  data: () => ({
    associatedCompanies: [],
    errorAssociatedCompanies: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Management()
      .getAssociatedCompanies()
      .then(({ data }) => {
        this.associatedCompanies = Array.isArray(data) ? data : [data];        
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorAssociatedCompanies = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #D4D4D4;
}
</style>