<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <section class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'hospital']" />
            <h2 class="tituloAtendimento">Alunos/Turma</h2>
          </div>
        </vs-row>

        <vs-row class="pd-t" v-if="!teacherClass[0]">
          <p class="message_error">Sem resultados para mostrar</p>
        </vs-row>

        <vs-row
          v-else
          class="box-center"
          v-for="(i, index) in teacherClass"
          :key="index.user"
        >
          <vs-col class="column_info">
            <h5>FL{{ i.CODFILIAL }} - {{ i.CODTURMA }} - {{ i.NOMETURMA }}</h5>
            <h2>{{ i.DISCIPLINA }}</h2>
            <p>{{ i.CURSO }}</p>
            <vs-divider />
            <span v-if="i.IDCURMOODLE">
              Moodle:
              <a :href="i.LINKMOODLE" target="_blank">
                {{ i.LINKMOODLE }}
              </a>
            </span>

            <div class="confirm-integrate">
              <vs-button
                class="button-integrate"
                v-if="i.DIASPARAFIM <= 7 && i.INTEGRANOTA == 'S'"
                type="filled"
                @click="popupIntegrate(i)"
              >
                <font-awesome-icon :icon="['fas', 'plus-square']" />
                Importar Notas Moodle
              </vs-button>

              <vs-button
                class="button-integrate"
                v-if="i.DIASPARAFIM <= 7"
                type="filled"
                @click="finalResults(i)"
              >
                <font-awesome-icon :icon="['fas', 'tasks']" />
                Apurar Resultados Finais
              </vs-button>
            </div>

            <vs-popup title="Um momento..." :active.sync="confirmPopup">
              <vs-card outlined>
                <p style="font-size: 1.25em; text-align: justify;">
                  Este processo iniciará a importação de notas do Moodle para o
                  RM.Isto poderá levar alguns minutos, então após clicar em
                  <b>Confirmar</b> aguarde até o processo concluir.
                </p>

                <vs-divider/>

                <div v-show="errorIntegrate">
                  <h4 class="error-alert">
                    <font-awesome-icon
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    {{ errorIntegrate }}
                  </h4>
                </div>

                <div class="confirm-integrate">
                  <vs-button @click="confirmPopup = false">
                    <font-awesome-icon :icon="['fas', 'times-circle']" />
                    Cancelar
                  </vs-button>
                  
                  <vs-button
                    class="button-integrate"
                    color="success"
                    type="gradient"
                    @click="integrateNotes(i)"
                  >
                    <font-awesome-icon :icon="['fas', 'check']" />
                    Confirmar
                  </vs-button>
                </div>
              </vs-card>
            </vs-popup>

            <vs-popup title="Ops..." :active.sync="popupFinalResult">
              <vs-card outlined>
                <h4 class="error-alert">{{ errorFinalResult }}</h4>
              </vs-card>
            </vs-popup>
          </vs-col>
          <vs-col class="column_class">
            <p>Alunos: {{ i.ALUNOSMAT }}</p>
            <p>Período: {{ i.DTINICIAL }} até {{ i.DTFINAL }}</p>
            <p>Disponível por: {{ i.DIASATRASO }} de 30 dias</p>
            <p>Aulas Planejadas: {{ i.AULASPLANEJADAS }}</p>
            <p>Aulas Dadas: {{ i.AULASDADAS }}</p>

            <vs-divider />

            <div style="text-align: center">
              <vs-button
                class="moodle_button"
                type="filled"
                @click="moodleLink(i.NOTAS)"
              >
                <font-awesome-icon :icon="['fas', 'graduation-cap']" />
              </vs-button>

              <vs-button
                class="class_button"
                type="filled"
                @click="getStudentsByClass(i.CODCOLIGADA, i.IDTURMADISC)"
              >
                <font-awesome-icon :icon="['fas', 'search']" />
                Visualizar Alunos
              </vs-button>

              <vs-button class="link_button" type="filled" @click="openLinks()">
                <font-awesome-icon :icon="['fas', 'link']" />
              </vs-button>
            </div>
          </vs-col>
          <vs-divider/>
          <vs-popup
            fullscreen
            title="Detalhes da Turma"
            :active.sync="popupClass"
          >
            <vs-card outlined>
              <vs-table
                class="table_intranet"
                search
                hoverFlat
                pagination
                max-items="15"
                :data="studentsOfClass"
              >
                <template slot="thead">
                  <vs-th> RA </vs-th>
                  <vs-th> Nome do Aluno </vs-th>
                  <vs-th> Telefone (s) </vs-th>
                  <vs-th> E-mail </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr v-for="(i, index) in data" :key="index">
                    <vs-td>
                      {{ i.RA }}
                    </vs-td>

                    <vs-td>
                      {{ i.NOME }}
                    </vs-td>

                    <vs-td>
                      {{ i.TEL01 }} <br />
                      {{ i.TEL02 }} <br />
                      {{ i.TEL03 }} <br />
                      {{ i.TEL04 }} <br />
                      {{ i.TEL05 }} <br />
                      {{ i.TEL06 }} <br />
                      {{ i.TEL07 }} <br />
                    </vs-td>

                    <vs-td>
                      <a :href="['mailto:' + i.EMAIL]" target="_blank">{{
                        i.EMAIL
                      }}</a>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-card>
          </vs-popup>

          <vs-popup title="Sala de Aula" :active.sync="popupLink">
            <vs-card outlined>
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <form method="post">
                  <label>Link da Sala de Aula</label>
                  <vs-row>
                    <input
                      id="CODCOLIGADA"
                      type="hidden"
                      v-bind:value="i.CODCOLIGADA"
                    />
                    <input
                      id="IDTURMADISC"
                      type="hidden"
                      v-bind:value="i.IDTURMADISC"
                    />

                    <ValidationProvider
                      ref="providerClass"
                      name="do Classroom"
                      rules="min:47"
                       
                    >
                      <vs-row>
                        <vs-input
                          class="linkClassroom"
                          id="LINKCLASSROOM"
                          placeholder="Preencha com um link do Classroom"
                          v-mask="maskClassroom"
                          v-model="valueClassroom"
                          type="url"
                        />
                        <vs-button
                          color="white"
                          class="button_classroom"
                          type="filled"
                          @click="openClassroom(i.LINKCLASSROOM)"
                        >
                          <font-awesome-icon :icon="['fas', 'share-square']" />
                        </vs-button>
                      </vs-row>
                    </ValidationProvider>
                    <vs-row class="empty_link">
                      <input
                        id="checkClassroom"
                        type="checkbox"
                        @click="resetClassroom()"
                      />
                      <label>Deixar em branco</label>
                    </vs-row>
                  </vs-row>

                  <label>Link da Aula Remota</label>
                  <vs-row>
                    <ValidationProvider
                      ref="providerRemote"
                      name="da Aula Remota"
                      rules="min:36"
                       
                    >
                      <vs-row>
                        <vs-input
                          class="linkRemoteClass"
                          id="LINKAULAREMOTA"
                          placeholder="Preencha com um link do Meet"
                          v-mask="maskRemote"
                          v-model="valueRemoteClass"
                          type="url"
                        />
                        <vs-button
                          color="white"
                          class="button_remote"
                          type="filled"
                          @click="openRemoteClass(i.LINKAULAREMOTA)"
                        >
                          <font-awesome-icon :icon="['fas', 'share-square']" />
                        </vs-button>
                      </vs-row>
                    </ValidationProvider>
                    <vs-row class="empty_link">
                      <input
                        id="checkRemote"
                        type="checkbox"
                        @click="resetRemoteClass()"
                      />
                      <label>Deixar em branco</label>
                    </vs-row>
                  </vs-row>

                  <vs-row>
                    <vs-button
                      color="primary"
                      type="filled"
                      :disabled="invalid"
                      @click="getSendLink()"
                    >
                      <font-awesome-icon :icon="['fas', 'paper-plane']" />
                      Enviar
                    </vs-button>

                    <p v-if="registered == true" class="register">
                      Cadastrado com Sucesso
                    </p>

                    <p v-if="errorRegistered == true" class="errorRegister">
                      Preencha os Campos
                    </p>
                  </vs-row>
                </form>
              </ValidationObserver>
            </vs-card>
          </vs-popup>
        </vs-row>
      </section>
    </div>
  </div>
</template>

<script>
import TeacherClass from "../services/teachers";
import StudentsInformation from "../services/classes";
import { getLoginDataFromStorage } from "../helpers/storage";

export default {
  name: "AlunoTurma",
  data: () => ({
    teacherClass: [],
    studentsOfClass: [],
    noteIntegrate: null,
    errorIntegrate: null,
    popupFinalResult: false,
    resultsFinal: null,
    errorFinalResult: null,
    popupLink: false,
    popupClass: false,
    valueCodColigada: null,
    valueIdTurmaDisc: null,
    valueClassroom: null,
    maskClassroom: "https://classroom.google.com/c/NNNNNNNNNNNNNNNN",
    valueRemoteClass: null,
    maskRemote: "https://meet.google.com/AAA-AAAA-AAA",
    registered: false,
    errorRegistered: false,
    linksClassRoom: null,
    error: null,
    confirmPopup: false,
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    const { COLIGADAATENDENTE, CODUSUARIO } = user.userData;

    new TeacherClass()
      .getTeacherClasses(COLIGADAATENDENTE, CODUSUARIO)
      .then(({ data }) => {
        this.teacherClass = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    moodleLink(NOTAS) {
      window.open(NOTAS, "_blank");
    },

    popupIntegrate(tr) {
      this.confirmPopup = true;
      this.classId = tr.IDTURMADISC;
      this.colligateId = tr.CODCOLIGADA;
    },

    integrateNotes() {
      this.confirmPopup = false;

      const payloadIntegrate = {
        classId: this.classId,
        colligateId: this.colligateId,
      };

      this.$vs.loading({ text: "Processando..." });
      new StudentsInformation()
        .integrateNotes(payloadIntegrate)
        .then(({ data }) => {
          this.noteIntegrate = data;

          this.$vs.notify({
            time: 10000,
            title: "SUCESSO",
            text: "Integração realizada com sucesso!!!",
            color: "#139B0E",
            position: "top-center",
            icon: "check_box",
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIntegrate = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    finalResults(tr) {
      this.popupFinalResult = false;
      this.classId = tr.IDTURMADISC;
      this.colligateId = tr.CODCOLIGADA;

      this.discipline = tr.DISCIPLINA;

      const payloadFinalResults = {
        classId: this.classId,
        colligateId: this.colligateId,
      };

      this.$vs.loading({ text: "Processando..." });
      new StudentsInformation()
        .calculateFinalResult(payloadFinalResults)
        .then(({ data }) => {
          this.resultsFinal = data;

          this.$vs.notify({
            time: 10000,
            title: this.discipline,
            text: "Apuração de notas realizada com sucesso!!!",
            color: "#139B0E",
            position: "bottom-center",
            icon: "check_box",
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorFinalResult = response.data;
            this.popupFinalResult = true;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    getStudentsByClass(CODCOLIGADA, IDTURMADISC) {
      this.studentsOfClass = [];
      this.$vs.loading({ text: "Carregando..." });
      new StudentsInformation()
        .getStudentsOfClass(CODCOLIGADA, IDTURMADISC)
        .then(({ data }) => {
          this.studentsOfClass = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
          this.$vs.loading.close();
        });

      setTimeout(() => {
        this.popupClass = true;
      }, 100);
    },

    openLinks() {
      setTimeout(() => {
        this.popupLink = true;
      }, 100);
    },

    resetClassroom() {
      if (this.valueClassroom == null || this.valueClassroom != "") {
        this.valueClassroom = "";
        this.maskClassroom = "https://classroom.google.com/c/NNNNNNNNNNNNNNNN";
        document.getElementById("LINKCLASSROOM").disabled = true;
      } else {
        this.valueClassroom = null;
        document.getElementById("LINKCLASSROOM").disabled = false;
      }
    },

    openClassroom(LINKCLASSROOM) {
      if (LINKCLASSROOM != null) {
        window.open(LINKCLASSROOM, "_blank");
      } else {
        window.open("https://classroom.google.com/", "_blank");
      }
    },

    resetRemoteClass() {
      if (this.valueRemoteClass == null || this.valueRemoteClass != "") {
        this.valueRemoteClass = "";
        this.maskRemote = "https://meet.google.com/AAA-AAAA-AAA";
        document.getElementById("LINKAULAREMOTA").disabled = true;
      } else {
        this.valueRemoteClass = null;
        document.getElementById("LINKAULAREMOTA").disabled = false;
      }
    },

    openRemoteClass(LINKAULAREMOTA) {
      if (LINKAULAREMOTA != null) {
        window.open(LINKAULAREMOTA, "_blank");
      } else {
        window.open("https://meet.google.com/", "_blank");
      }
    },

    getSendLink() {
      this.registered = false;
      this.errorRegistered = false;

      if (this.valueClassroom == null || this.valueRemoteClass == null) {
        this.errorRegistered = true;
      } else {
        this.$vs.loading({ text: "Carregando..." });
        const payload = {
          codColigada: document.getElementById("CODCOLIGADA").value,
          idTurmaDisc: document.getElementById("IDTURMADISC").value,
          linkClassroom: document.getElementById("LINKCLASSROOM").value,
          linkAulaRemota: document.getElementById("LINKAULAREMOTA").value,
        };

        new StudentsInformation()
          .saveClassRoomLinks(payload)
          .then(({ data }) => {
            this.linksClassRoom = data;
            this.registered = true;

            setTimeout(() => {
              this.registered = false;
            }, 2500);

            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.error = response.data;
            }
            this.$vs.loading.close();
          })
          .finally(() => {
            this.$vs.loading.close();
            this.resetForm();
          });
      }
    },

    resetForm() {
      setTimeout(() => {
        this.valueClassroom = null;
        this.valueRemoteClass = null;
        document.getElementById("checkClassroom").checked = false;
        document.getElementById("LINKCLASSROOM").disabled = false;
        document.getElementById("checkRemote").checked = false;
        document.getElementById("LINKAULAREMOTA").disabled = false;

        this.$nextTick(() => {
          this.$refs[`observer`][0].reset();
        });
      }, 200);
    },
  },
};
</script>

<style scoped>
.box-center {
  padding: 1.5%;
}

.column_info {
  width: 50% !important;
  margin-right: 6%;
}

.column_info > h5,
.column_info > h2,
.column_info > p {
  font-size: 1.25em;
}

.column_class {
  width: 40% !important;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

.confirm-integrate {
  text-align: center;
}

.button-integrate {
  margin-left: 3.5%;
}

.error-alert {
  margin-top: 2.5%;
  font-size: 0.95em;
  color: #ff0000;
  font-weight: bold;
}

.availability {
  color: #9b0e1c;
  font-weight: bold;
}

.class_button {
  width: 50%;
  margin: 0 5%;
}

.linkClassroom,
.linkRemoteClass {
  margin-top: 1.5%;
  width: 35em;
}

.button_classroom,
.button_remote {
  color: #9b0e1c;
  margin-top: 1%;
}

.empty_link {
  margin-bottom: 4%;
}

.register,
.errorRegister {
  border: 0.15em solid;
  border-radius: 0.5em;
  font-weight: bold;
  font-size: 1.2em;
  margin-left: 3%;
  padding: 1.5%;
}

.register {
  background-color: #d4edda;
  border-color: #155724;
  color: #155724;
}

.errorRegister {
  background-color: #ff8e8e;
  border-color: #571515;
  color: #571515;
}

input {
  margin-right: 1%;
}

@media screen and (max-width: 1110px) {
  .column_info > h5,
  .column_info > h2,
  .column_info > p {
    font-size: 1em;
  }

  .info_class {
    font-size: 0.9em;
  }

  .moodle_button,
  .link_button {
    width: 15%;
  }
}

@media screen and (max-width: 965px) {
  .column_info > h5,
  .column_info > h2,
  .column_info > p {
    font-size: 1.3em;
  }

  .column_info {
    width: 100% !important;
  }

  .column_class {
    margin-top: 3%;
    width: 100% !important;
  }

  .info_class {
    font-size: 1em;
  }
}

@media screen and (max-width: 745px) {
  .column_info {
    width: 100%;
  }

  .column_info > h5,
  .column_info > h2,
  .column_info > p {
    font-size: 1.1em;
  }

  .column_class {
    margin-top: 3%;
    width: 100%;
  }
}

@media screen and (max-width: 570px) {
  .linkClassroom,
  .linkRemoteClass {
    width: 25em;
  }
}

@media screen and (max-width: 505px) {
  .message_error {
    font-size: 0.85em;
  }

  .column_info > h5,
  .column_info > h2,
  .column_info > p,
  .column_class > p {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 440px) {
  .info_class {
    font-size: 0.85em;
  }

  .linkClassroom,
  .linkRemoteClass {
    width: 15.5em;
  }
}

@media screen and (max-width: 415px) {
  .register,
  .errorRegister {
    font-size: 1.1em;
    margin-left: 3%;
    padding: 2%;
  }
}

@media screen and (max-width: 395px) {
  .message_error {
    font-size: 0.9em;
  }

  .column_info > h5,
  .column_info > h2,
  .column_info > p,
  .column_class > p {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 340px) {
  .register,
  .errorRegister {
    font-size: 1.3em;
    margin-top: 3%;
    margin-left: 0%;
  }
}
</style>