<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'cogs']" />
              <h2 class="tituloAtendimento">
                 Ajuste Diário de Classe
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in options"
                  />
                </vs-select>
              </fieldset>              
            </vs-col>  
          </vs-row>
        </div>
        <AjusteDeFrequencia v-if="typeSelected == 1" />

        <AjusteDeNota v-if="typeSelected == 2" />

        <ConteudoProgramatico v-if="typeSelected == 3"/>

        <IncluirRetirarStatus v-if="typeSelected == 4" />

        <RetornoTrancamentoLiberacao v-if="typeSelected == 5"/>

        <Trancamento v-if="typeSelected == 6" />

        <TransferenciaRetroativa v-if="typeSelected == 7" />
      </div>
    </div>        
  </section>
</template>

<script>
import AjusteDeFrequencia from "./FormAjusteDiario/AjusteDeFrequencia.vue";
import AjusteDeNota from "./FormAjusteDiario/AjusteDeNota.vue";
import ConteudoProgramatico from "./FormAjusteDiario/ConteudoProgramatico.vue"
import IncluirRetirarStatus from "./FormAjusteDiario/IncluirRetirarStatus.vue";
import RetornoTrancamentoLiberacao from "./FormAjusteDiario/RetornoTrancamentoLiberacao.vue"
import Trancamento from "./FormAjusteDiario/Trancamento.vue";
import TransferenciaRetroativa from "./FormAjusteDiario/TransferenciaRetroativa.vue"

export default {
  name: "AjusteDiarioDeClasse",
  components: {
    AjusteDeFrequencia,
    AjusteDeNota,
    ConteudoProgramatico,
    IncluirRetirarStatus,
    RetornoTrancamentoLiberacao,
    Trancamento,
    TransferenciaRetroativa,
  },
  data: () => ({
    typeSelected: "",
    options: [
      { text: "Ajuste de Frequência", value: 1 },
      { text: "Ajuste de Nota", value: 2 },
      { text: "Conteúdo Programático", value: 3 },
      { text: "Incluir / Retirar Status (Letras)", value: 4 },
      { text: "Retorno Trancamento / Liberação", value: 5 },
      { text: "Trancamento", value: 6 },
      { text: "Transferência / Transferência Retroativa", value: 7 },
    ],
  }),
};
</script>
