<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu"/>
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'headset']" />
            <h2 style="font-size: 0.9em;margin: 0.1em 0 0 0.5em;">
              Atendimentos
            </h2>
          </div>
        </vs-row>

        <vs-row class="breadcrumb">
          <vs-col>
            <vs-tabs alignment="center">
              <vs-tab icon="add_circle_outline" label="Abertos por Mim" @click="type=1"/>
              <vs-tab icon="list" label="Minha Fila" @click="type=2"/>
              <vs-tab icon="account_circle" label="Meus Departamentos" @click="type=3"/>
            </vs-tabs>        
          </vs-col>
        </vs-row>

        <AtendAbertosPorMimVue v-if="this.type == '1'" />
        <MinhaFila v-if="this.type == '2'" />
        <AtendMeusDepart v-if="this.type == '3'" />
      </div>    
    </div>
  </section>
</template>
<script>
import AtendMeusDepart from "./AtendMeusDepart.vue";
import AtendAbertosPorMimVue from "./AtendAbertosPorMim.vue";
import MinhaFila from "./MinhaFila.vue";
export default {
  name: "Atendimentos",
  components: {
    AtendMeusDepart,
    AtendAbertosPorMimVue,
    MinhaFila,    
  },
  data: () => ({
    type: 1,
    index: null,    
  }), 
};
</script>

<style scoped>

.tituloAtendimento {
  padding-left: 0.2em;
  margin-top: 5px;
}
.breadcrumb {
  overflow: hidden;  
  max-width: 80%;
  width: 100%;
  margin: 12px auto; 
}
</style>