<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'sync-alt']" />
              <h2 class="tituloAtendimento">
                Tipo de Movimentação de Pessoal
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>            
                <vs-select
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                  class="selectExample"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.type"
                  />
                </vs-select>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
        <Desligamento v-if="typeSelected === 'Desligamento'" />
        <Ferias v-if="typeSelected === 'Férias'" />
        <TransferenciaPromocao v-if="typeSelected === 'Transferência / Promoção'" />
      </div>
    </div>    
  </section>
</template>

<script>
import Desligamento from "./FormMovimentacao/Desligamento.vue"
import Ferias from "./FormMovimentacao/Ferias.vue"
import TransferenciaPromocao from "./FormMovimentacao/TransferenciaPromocao.vue"

export default {
  name: "MovimentacaoPessoal",
  components: {
    Desligamento,
    Ferias,
    TransferenciaPromocao
  },
  data: () => ({
    typeSelected: null,
    data: {
      type: [
        { text: "Desligamento", value: "Desligamento" },
        { text: "Férias", value: "Férias" },
        { text: "Transferência / Promoção", value: "Transferência / Promoção" },
      ],
      employees: null,
      errorEmployees: null,
    },
  }),

};
</script>
