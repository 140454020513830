 <template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
             Certificado Palestrante
          </h2>
        </div>
      </vs-row>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Nome <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nome"
                  rules="required"
                   
                >                                                 
                  <vs-input
                    class="selectExample"
                    placeholder="Digite o Nome"
                    v-model="name"
                  />
                   
                </ValidationProvider>
              </fieldset>                       
            </vs-col>      
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Email<b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Email"
                  rules="required"
                   
                >                                                 
                  <vs-input
                    class="selectExample"
                    placeholder="Digite o Email"
                    v-model="email"
                  />
                   
                </ValidationProvider>
              </fieldset>                       
            </vs-col>  
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Tema <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Tema"
                  rules="required"
                   
                >
                  <vs-input
                    class="selectExampleDouble"                    
                    placeholder="Digite o Tema"
                    v-model="theme"
                  />
                   
                </ValidationProvider>
              </fieldset>                  
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Carga Horária <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Carga Horária"
                  rules="required"
                   
                >
                  <vs-input
                    class="selectExampleDouble"                      
                    placeholder="Digite a Carga Horária em Horas"
                    v-model="workload"
                    type="number"
                  />
                   
                </ValidationProvider>
              </fieldset>                    
            </vs-col>
          </vs-row> 

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Filial <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Filial"
                  rules="required"
                   
                >
                  <vs-select
                    v-model="selectedUnit"
                    class="selectExampleDouble"
                    placeholder="Selecione uma Filial"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.CODFILIAL"
                      :text="item.POLO"
                      v-for="(item, index) in units"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>                    
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Data <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data"
                  rules="required"
                   
                >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="date"
                    type="date"
                  />
                   
                </ValidationProvider>
              </fieldset>                    
            </vs-col>
          </vs-row>

        <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>            
      </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Units from "../../../../services/units";
import { getLoginDataFromStorage, poloTextFormat, convertDate } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
    name: "Palestrante",
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    name: null,
    email: null,
    theme: null,
    units: null,
    errorUnits: null,
    selectedUnit: null,
    date: null,
    workload: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.null = response.data;
          console.log(response)
        }
      })      
      .finally(() => {
        this.$vs.loading.close();
      });
  },
  methods: {

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E4");

      payload.data.locationId = personLocal.toString();
      payload.data.ra = this.ra;
      payload.data.attendantId = attendantId.toString();
      payload.data.subject = "CERTIFICADO - PALESTRANTE";
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME: ${this.name}\nEMAIL: ${this.email}\nTEMA: ${this.theme}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nDATA: ${convertDate(this.date)}\nCARGA HORÁRIA: ${this.workload}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.theme = null;
      this.name = null;
      this.email = null;
      this.selectedUnit = null;
      this.date = null;
      this.workload = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
