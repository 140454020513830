<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'business-time']" />
            <h2 class="tituloAtendimento">QI em Tempo Real - Comercial</h2>
          </div>
        </vs-row>

        <div>
          <vs-card>
            <vs-row class="top_chart">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'file-contract']" />
                <h2 class="title_chart">
                  Matrículas por Vendedor ({{ this.matriculation[0].DATA_INICIAL_META }} - {{ this.matriculation[0].DATA_FINAL_META }})
                </h2>
              </div>
            </vs-row>

            <vs-row class="pd-table">
              <vs-col v-if="matriculation == ''">
                <p>Não foram localizados registros de metas</p>
              </vs-col>
              <vs-col v-else>
                <fieldset>
                  <p class="totalMatriculation">
                    <font-awesome-icon :icon="['fas', 'graduation-cap']" />
                    Total: <strong>{{ totalMatriculation }} matrícula(s)</strong>
                  </p>
                  <vs-divider/>
                  <vs-table
                    max-items="50"
                    hoverFlat
                    :data="matriculation"
                    search
                    notSpacer
                  >
                    <template slot="thead">
                      <vs-th> QI </vs-th>
                      <vs-th> Vendedor </vs-th>
                      <vs-th sort-key="MATRICULAS"> Matrículas </vs-th>
                      <vs-th sort-key="META"> Meta % </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          FL{{ tr.CODLOCAL }}
                        </vs-td>

                        <vs-td>
                          {{ tr.VENDEDOR }} <br>
                          <p style="margin-top: 5px; font-size: 0.915em;"><b>Supervisor/Coordenador: </b> {{tr.SUPERVISOR == tr.COORDENADOR ? "" : tr.SUPERVISOR + " / "}}{{ tr.COORDENADOR }}</p>
                        </vs-td>

                        <vs-td> {{ tr.QTDMATRICULAS }} de {{ tr.META }} </vs-td>

                        <vs-td> {{ tr.PORCENTAGEM }} </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </fieldset>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MatriculationBySeller from "../../services/commercial";

export default {
  name: "TempoRealComercial",
  data: () => ({
    matriculation: [],
    error: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new MatriculationBySeller()
      .getMatriculationBySeller()
      .then(({ data }) => {
        this.matriculation = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  computed: {
    totalMatriculation() {
      return this.matriculation.reduce((a, b) => {
        return a - -b.QTDMATRICULAS;
      }, 0);
    },
  },
};
</script>

<style scoped>
.top_chart {
  height: 3.125em;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
  color: #9b0e1c;
  font-size: 1.55em;
}

.title_chart {
  font-size: 0.9em;
  padding-left: 0.5em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

.totalMatriculation {
  border: 1px solid;
  border-radius: 5px;
  padding: 1%;
  text-align: center;
  font-size: 1.65em;
}

@media screen and (max-width: 400px) {
  .totalMatriculation {
    font-size: 1.45em;
  }
}
</style>