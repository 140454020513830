<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
             Conteúdo Programático 
          </h2>
        </div>
      </vs-row>

        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <div>
            <vs-row class="pd-t">
              <vs-col>
                <fieldset class="search">
                  <legend>Cód. Turma <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Cód. Turma"
                    rules="required|numeric"
                     
                  >
                    <vs-input
                      @keyup.enter="getCoursesAndSubjectByClassCode()" 
                      v-model="classCode"
                      placeholder="Digite o Cód. Turma"
                      class="selectExampleRA"
                    >
                    </vs-input>
                     
                  </ValidationProvider>
                  <vs-button
                    color="primary"
                    class="buscar"
                    type="filled"
                    :disabled="invalid"
                    @click="getCoursesAndSubjectByClassCode()"
                  >
                    <font-awesome-icon :icon="['fas', 'search']" />
                    <span> Buscar </span></vs-button
                  >
                </fieldset>                  
              </vs-col>  
            </vs-row>            
          </div>
        </ValidationObserver>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
        </div>

        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div   v-if="responseClassCode != null">
            <div v-if="responseClassCode == ''">
              <p class="validacao">Cód. Turma Inválido</p>
            </div>
            <div v-else>
              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Curso <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Curso"
                      rules="required"
                    >
                      <vs-select
                        class="selectExampleDouble"
                        v-model="course"
                        placeholder="Selecione um Curso"
                      >
                        <vs-select-item
                          :value="responseClassCode[0].NOME"
                          :text="responseClassCode[0].NOME"
                        />
                      </vs-select>
                    </ValidationProvider>
                  </fieldset>                      
                </vs-col> 

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Disciplina <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Disciplina"
                      rules="required"
                    >
                      <vs-select
                        class="selectExampleDouble"
                        v-model="subject"
                        placeholder="Selecione uma Disciplina"
                      >
                        <vs-select-item
                          v-for="(i, index) in responseClassCode"
                          :key="index"
                          :value="i.DISCIPLINA"
                          :text="i.DISCIPLINA"
                        />
                      </vs-select>
                    </ValidationProvider>
                  </fieldset>                      
                </vs-col> 
              </vs-row>                               

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Descrição do Conteúdo"
                    rules="required|max:500"
                     
                  >
                    <vs-textarea
                      label="Descrição do Conteúdo"
                      height="210px"
                      rows="11"
                      class="textArea"
                      counter="500"
                      v-model="description"
                    />
                     
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Justificativa"
                    rules="required|max:500"
                     
                  >
                    <vs-textarea
                      label="Justificativa"
                      height="210px"
                      rows="11"
                      class="textArea"
                      counter="500"
                      v-model="solicitation"
                    />
                     
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
            </div>
          </div>
        </ValidationObserver>
  </div>
  </section>  
</template>

<script>
import Classes from "../../../../services/classes";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  data: () => ({
    classCode: null,
    responseClassCode: null,
    errorResponseClassCode: null,
    course: null,
    subject: null,
    description: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),
  methods: {
    getCoursesAndSubjectByClassCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Classes()
        .getCoursesAndSubjectByClassCode(this.classCode)
        .then(({ data }) => {
          this.responseClassCode = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseClassCode = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      
      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E6-3");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId} \nCÓDIGO TURMA: ${this.classCode}\nCURSO: ${this.course}\nDISCIPLINA: ${this.subject}\nDESCRIÇÃO DO CONTEÚDO: ${this.description}\nJustificativa: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.course = null;
      this.subject = null;
      this.description = null;
      this.solicitation = null;
      this.classCode = null;
      this.responseClassCode = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
