<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'graduation-cap']" />
            <h2 class="tituloAtendimento">Cronograma de Capacitação</h2>
          </div>
        </vs-row>

        <vs-row class="pd-table">
          <vs-alert
            :active.sync="alert"
            closable
            close-icon="cancel"
            title="Inscrição"
            color="danger"
          >
            {{ error }}
          </vs-alert>
          <vs-col v-if="training == ''">
            <p>Sem resultados para mostrar</p>
          </vs-col>
          <vs-col v-else>
            <vs-table
              class="table_intranet"
              max-items="20"
              hoverFlat
              pagination
              search
              :data="training"
            >
              <template slot="thead">
                <vs-th class="table_th"> Treinamento </vs-th>
                <vs-th class="table_th"> Público Alvo </vs-th>
                <vs-th class="table_th"> Local </vs-th>
                <vs-th class="table_th"> Data/Hora </vs-th>
                <vs-th class="table_th"> Instrutor </vs-th>
                <vs-th class="table_th"> Inscritos/Vagas </vs-th>
                <vs-th class="table_th"> Inscrição </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :data="tr"
                  :key="indextr"
                  class="table_body"
                  v-for="(tr, indextr) in data"
                >
                  <vs-td>
                    <strong>{{ data[indextr].NOMECURSO }}</strong>
                  </vs-td>

                  <vs-td :title="data[indextr].PUBLICOALVO">
                    {{ data[indextr].PUBLICOALVO }}
                  </vs-td>

                  <vs-td>
                    {{ data[indextr].LOCAL }}
                  </vs-td>

                  <vs-td>
                    {{ data[indextr].DATAINICIO }} <br />
                    {{ data[indextr].HORAINICIO }} -
                    {{ data[indextr].HORATERMINO }}
                  </vs-td>

                  <vs-td> {{ data[indextr].INSTRUTOR }}</vs-td>

                  <vs-td>
                    {{ data[indextr].INSCRITOS }}/{{ data[indextr].VAGAS }}
                  </vs-td>

                  <vs-td v-if="data[indextr].INSCRITO == 0">
                    <Vs-button @click="handleClickDetails(data[indextr])"
                      >Inscrever-se</Vs-button
                    >
                  </vs-td>

                  <vs-td v-else>
                    <p>
                      Inscrito <br />
                      <span v-if="data[indextr].LINK"
                        ><a :href="data[indextr].LINK" target="_blank"
                          >Acessar meet</a
                        ></span
                      >
                    </p>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </section>
</template>
  
  <script>
import Management from "../../services/management";
import { getLoginDataFromStorage } from "../../helpers/storage";
export default {
  name: "Capacitacao",
  data: () => ({
    training: [],
    error: null,
    alert: false,
  }),
  mounted() {
    this.getTrainingSchedule();
  },

  methods: {
    getTrainingSchedule() {
      this.$vs.loading({ text: "Carregando..." });
      const { ...userProfile } = getLoginDataFromStorage();
      new Management()
      .getTrainingSchedule(userProfile.userData.CODIGO)
      .then(({ data }) => {
        this.training = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        } else this.error = "Não foi possível carregar a agenda";
        this.alert = true;
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },

    handleClickDetails(tr) {
      const { ...userProfile } = getLoginDataFromStorage();
      let payload = {
        personId: userProfile.userData.CODIGO,
        classId: tr.CODTURMA,
      };

      this.error = null;

      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .register(payload)
        .then(({ data }) => {
          console.log(data);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          } else {
            this.error =
              "Ocorreu um erro ao tentar realizar a sua inscrição. Por favor contate o suporte.";
          }
          this.alert = true;
        })
        .finally(() => {
          this.$vs.loading.close();
          this.getTrainingSchedule();
        });
    },

    limitCaracter(texto) {
      if (texto.length < 60) {
        return texto;
      } else {
        return texto.slice(0, 60) + "...";
      }
    },
  },
};
</script>
  
  <style scoped>
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}
</style>