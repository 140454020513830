<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'file-signature']" />
            <h2 class="tituloAtendimento">
              Processos Seletivos de Docentes
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="selectiveProcess"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Atendimento </vs-th>
                    <vs-th class="table_th"> Solicitante/Data</vs-th>
                    <vs-th class="table_th"> Responsável Atual</vs-th>
                    <vs-th class="table_th"> Etapa Atual </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <a href="#" @click="showDetaisl(data[indextr])">
                          {{ data[indextr].CODATENDIMENTO }}</a
                        >
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].SOLICITANTE }}<br /><span>
                          Aberto em {{ data[indextr].ABERTURA }}
                        </span>
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].RESPONSÁVEL }}
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].TAREFA }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-popup title="Detalhes" :active.sync="popupAtivo">
            <div v-if="processSelected">
              <h3>Solicitação</h3>
              <p>{{ processSelected.TEXTOSOLICITACAO }}</p>
              <h3>Histórico</h3>
              <vs-table
                class="table_intranet"
                max-items="20"
                hoverFlat
                pagination
                :data="selectiveProcessData"
              >
                <template slot="thead">
                  <vs-th class="table_th"> Tarefa </vs-th>
                  <vs-th class="table_th"> Data</vs-th>
                  <vs-th class="table_th"> Usuário</vs-th>
                  <vs-th class="table_th"> Responsável </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    class="table_body"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>
                      {{ data[indextr].TAREFA }}
                    </vs-td>

                    <vs-td>
                      {{ data[indextr].ENTRADATAREFA }}
                    </vs-td>

                    <vs-td>
                      {{ data[indextr].USUARIO }}
                    </vs-td>
                    <vs-td>
                      {{ data[indextr].RESPONSAVEL }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-popup>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
import Teachers from "../../services/teachers";
export default {
  name: "ProcessoSeletivoDocente",
  data: () => ({
    selectiveProcess: [],
    error: null,
    popupAtivo: false,
    processSelected: null,
    selectiveProcessData: [],
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
      .getSelectiveProcess()
      .then(({ data }) => {
        this.selectiveProcess = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
  methods: {
    showDetaisl(process) {
      this.popupAtivo = true;
      this.processSelected = process;
      this.getSelectiveProcessData(process);
    },

    getSelectiveProcessData(process) {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getSelectiveProcessData(
          process.CODCOLIGADA,
          process.CODATENDIMENTO,
          process.CODLOCAL
        )
        .then(({ data }) => {
          this.selectiveProcessData = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
  
  <style scoped>
h3 {
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}
</style>
  