<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'graduation-cap']" />
          <h2 class="tituloAtendimento">            
            Comunicação de Substituição de Professor/Instrutor
          </h2>
        </div>
      </vs-row>

      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>Cód. Turma <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Cód. Turma"
                  rules="required|numeric"
                >
                  <vs-input
                    @keyup.enter="getSubjectByClassCode()" 
                    v-model="classCode"
                    placeholder="Digite o Cód. Turma"
                    class="selectExampleRA"
                  />
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getSubjectByClassCode()"
                >
                  <font-awesome-icon :icon="['fas', 'search']"/>
                  Buscar
                </vs-button>                          
              </fieldset>
            </vs-col>
          </vs-row> 
        </ValidationObserver>                             
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div   v-if="responseClassCode != null">
          <div v-if="responseClassCode == ''">
            <p class="validacao">Cód. Turma Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>Desconto da Falta <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Desconto da Falta"
                    rules="required"
                  >
                    <vs-select
                      autocomplete
                      @input-change="inputChange"
                      class="selectExample"
                      v-model="discountMissing"                                    
                      placeholder="Selecione o Desconto da Falta"
                    >
                      <vs-select-item
                        :key="item.missingDescount"
                        :value="item.value"
                        :text="item.text"
                        v-for="item in missing"
                      />                                    
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-table">  
              <vs-col>  
                <fieldset>
                  <ValidationProvider
                    name="Disciplina"
                    rules="required"
                     
                  >
                    <vs-table
                      class="table_intranet"
                      hoverFlat
                      multiple
                      v-model="selected"
                      :data="responseClassCode"
                      v-on:input="selectedItens"
                    >
                      <template slot="thead">
                        <vs-th> Disciplina </vs-th>
                        <vs-th> Início </vs-th>
                      </template>

                      <template slot-scope="{data}">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"                                
                        >
                          <vs-td>
                            {{ data[indextr].DISCIPLINA }} <br>
                            <div style="margin-top: 8.5px;">
                              <font-awesome-icon :icon="['fas', 'user-circle']" />
                              {{ data[indextr].TITULAR ? data[indextr].TITULAR : '-' }}
                            </div>
                          </vs-td>

                          <vs-td>
                            {{ data[indextr].DATA_INICIAL }}
                          </vs-td>                              
                        </vs-tr>
                      </template>
                    </vs-table>
                     
                  </ValidationProvider>
                </fieldset>  
              </vs-col>
            </vs-row>  

            <vs-row class="pd-t">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>Professor Substituto <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Professor Substituto"
                    rules="required"
                     
                  >
                    <vs-select
                      autocomplete
                      @input-change="inputChange"
                      class="selectExample"
                      v-model="selectedTeacherSubstitute"                                    
                      placeholder="Selecione um Professor Substituto"
                    >
                      <vs-select-item
                        :key="item.teacherSubstitute"
                        :value="item.NOME + ' (' + item.SECAO + ')'"
                        :text="item.NOME + ' (' + item.SECAO + ')'"
                        v-for="item in activeTeachers"
                      />                                    
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Periodo da Substituição <b style="color: red;">*</b></legend>
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12">                                  
                    <ValidationProvider
                      name="Data Início"
                      rules="required"
                       
                    >
                      <vs-input
                        label="Data de Início"
                        class="selectExampleDouble"
                        v-model="startReplacementPeriod"
                        type="date"
                      />
                    </ValidationProvider>                
                  </vs-col>

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <ValidationProvider
                        name="Data de Término"
                        rules="required"
                         
                      >
                        <vs-input
                          label="Data de Término"
                          class="selectExampleDouble"
                          v-model="endReplacementPeriod"
                          type="date"
                        />
                      </ValidationProvider>
                  </vs-col>
                </fieldset>                
              </vs-col>                            
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Anexo <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Anexo"
                    ref="provider"
                     
                  >
                    <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col>
                <div class="flexTextArea">
                  <ValidationProvider
                    name="Justificativa"
                    rules="required|max:500"
                     
                  >
                    <vs-textarea
                      label="Justificativa *"
                      height="210px"
                      rows="11"
                      class="textArea"
                      counter="500"
                      v-model="solicitation"
                    />
                     
                  </ValidationProvider>
                </div>
              </vs-col>                  
            </vs-row>              

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Classes from "../../../../services/classes";
import Teachers from "../../../../services/teachers";
import {convertDate, getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  name: "AjusteDeHorarioDiasDaSemana",
  data: () => ({
    activeTeachers: null,
    discountMissing: null,
    missing: [],
    errorActiveTeachers: null,
    selectedTeacherSubstitute: null,
    files: "",
    base64Image: "",
    classCode: null,
    responseClassCode: null,
    errorResponseClassCode: null,
    selected: [],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    substituteTeacher: null,
    startReplacementPeriod: null,
    endReplacementPeriod: null,
    visibleButton: false,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
    .getActiveTeachers()
    .then(({ data }) => {
      this.activeTeachers = Array.isArray(data) ? data : [data];
      this.$vs.loading.close();
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.errorActiveTeachers = response.data;
        console.log(response)
      }
      this.$vs.loading.close();
    });

    this.missing = [
      { text: "Descontada", value: "Descontada" },
      { text: "Abonada", value: "Abonada" },
    ];
  },
  
  methods: {
    inputChange(event) {
      return event.target.value;
    },

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    getSubjectByClassCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Classes()
        .getSubjectByClassCode(this.classCode)
        .then(({ data }) => {
          this.responseClassCode = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseClassCode = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },    

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1-10");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCÓDIGO TURMA: ${this.classCode}\nDISCIPLINA: ${this.selectedItens()}\nFALTA: ${this.discountMissing}\nPROFESSOR SUBSTITUTO: ${this.selectedTeacherSubstitute}\nPERÍODO DA SUBSTITUIÇÃO \nDATA DE INÍCIO: ${convertDate(this.startReplacementPeriod)}\nDATA DE TÉRMINO: ${convertDate(this.endReplacementPeriod)}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },

    selectedItens() {
      let text = "";
      for (let i = 0; i < this.selected.length; i++) {
        text += this.selected[i].DISCIPLINA+" ("+(this.selected[i].TITULAR?this.selected[i].TITULAR:'-')+"), ";
      }
      return text;
    },

    resetForm() {
      this.selected = [];
      this.solicitation = null;
      this.classCode = null;
      this.responseClassCode = null;
      this.startReplacementPeriod = null;
      this.endReplacementPeriod = null;
      this.selectedTeacherSubstitute = null;
      this.visibleButton = false;
      this.$refs.image.value=null;
      this.files = null;
      this.base64Image = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
