<template>              
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">
            Ementa - Cursos Técnicos
          </h2>
        </div>
      </vs-row>
      
      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col>
                <fieldset class="search">
                  <legend>RA do Aluno <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="RA"
                    rules="required|numeric"
                     
                  >
                    <vs-input
                      @keyup.enter="getCoursesByRA()" 
                      v-model="ra"
                      placeholder="Digite o RA do Aluno"
                      class="selectExampleRA"
                    >
                    </vs-input>
                     
                  </ValidationProvider>
                  <vs-button
                    color="primary"
                    class="buscar"
                    type="filled"
                    :disabled="invalid"
                    @click="getCoursesByRA()"
                  >
                    <font-awesome-icon :icon="['fas', 'search']" />
                    <span> Buscar </span></vs-button
                  >
                </fieldset>              
              </vs-col>
            </vs-row>          
        </ValidationObserver>
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">        
        <div   v-if="responseRa != null">
          <div v-if="responseRa == ''">
            <p class="validacao">RA Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Nome do Aluno</legend>
                  <vs-input
                    class="selectExampleDouble"
                    :placeholder="responseRa[0].NOMEALUNO"
                    disabled="disabled"
                  />
                </fieldset>                    
              </vs-col>              
            </vs-row>  
            
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Curso <b style="color: red">*</b></legend>
                  <ValidationProvider name="Curso" rules="required">
                    <vs-select
                      class="selectExample"
                      v-model="nameCourse"
                      placeholder="Selecione um Curso"
                      v-on:change="searchByCodCurso();searchDiscipline(nameCourse.CODCURSO)"
                    >
                      <vs-select-item
                        :key="item.CODCURSO"
                        :value="item"
                        :text="item.CURSO"
                        v-for="item in responseRa"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial/Polo</legend>
                  <ValidationProvider
                    name="Filial/Polo"
                    rules="required"
                     
                  >
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="finalFilialPolo"     
                      disabled="disabled"            
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row 
              class="pd-table"
              v-if="nameCourse"
            >
              <vs-col>
                <fieldset>
                  <vs-table
                    class="table_intranet"
                    search
                    hoverFlat
                    multiple
                    v-model="selectedDisciplines"
                    v-on:input="selectedItens"
                    pagination
                    max-items="10"
                    :data="disciplines"
                    v-if="disciplines != ''"
                  >
                    <template slot="thead">
                      <vs-th> Disciplina(s) </vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td>
                          {{ data[indextr].NOMEDISC }}
                        </vs-td>                            
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div v-else>
                    <p class="message_error">Sem resultados para mostrar</p>
                  </div>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>                                    
                  <legend>Comprovante de Pagamento <b style="color: red;">*</b></legend> 
                  <ValidationProvider
                    name="Comprovante de Pagamento"
                    rules="required"
                  >                                                                       
                    <input class="selectExample" type="file" @change="onFileUploadStudent">
                  </ValidationProvider>                                       
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>          
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Students from "../../../../services/students";
import Management from "../../../../services/management"
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  name: "Ementa",
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    filesStudent: null,
    base64ImageStudent: null,
    idAttendance: null,
    errorIdAttendance: null,
    disciplines: null,
    selectedDisciplines: [],
    nameCourse: null,
    finalNameCourse: null,
    finalFilialPolo: null,
  }),

  methods: {
    getCoursesByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCourseEmenta(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },

    async onFileUploadStudent (event) {
      this.filesStudent = event.target.files[0];                
      if (this.filesStudent != null) {                                   
        this.createBase64ImageStudent(this.filesStudent);                
      }
    },

    createBase64ImageStudent(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64ImageStudent = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    searchDiscipline(course) {
      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .getDisciplineEmenta(this.ra, course)
        .then(({ data }) => {
          this.disciplines = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorCourses = response.data.error;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const studentName = this.responseRa[0].NOMEALUNO;

      const payload = new EducacionalModel().getDataByAttendanceId("E5-21");

      payload.data.locationId = personLocal.toString();
      payload.data.ra = this.ra;
      payload.data.attachment = this.base64ImageStudent;
      payload.data.fileName = this.filesStudent.name;
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA DO ALUNO: ${this.ra}\nALUNO: ${studentName}\nCURSO: ${this.finalNameCourse}\nDISCIPLINA: ${this.selectedItens()}\nFILIAL/POLO: ${this.finalFilialPolo}`;  

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },

    searchByCodCurso() {
      const search = (key, inputArray) => {
        for (let i = 0; i < inputArray.length; i++) {
          if (inputArray[i].CODCURSO === key) {
            return inputArray[i];
          }
        }
      };
      let result = search(this.nameCourse.CODCURSO, this.responseRa);
      let finalNameCourse = result.CURSO;
      let finalFilialPolo = result.POLO == "[object Object]" ? result.FILIAL : result.POLO;

      this.finalNameCourse = finalNameCourse;
      this.finalFilialPolo = finalFilialPolo;
    },

    selectedItens() {
      let text = "";
      for (let i = 0; i < this.selectedDisciplines.length; i++) {
        text += this.selectedDisciplines[i].NOMEDISC+",";
      }
      return text;
    },

    resetForm() {
      this.nameCourse = null;
      this.responseRa = null;
      this.selectedDisciplines = [];
      this.ra = null;
      this.finalNameCourse = null;
      this.finalFilialPolo = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>