import { https } from './api';

export default class {
    constructor() {
        this.instance = https('person')
    }

    getBirthday = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/birthday',
            timeout: 30000, 
        });
    }

    getNewCollaborator = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/new-collaborator',
            timeout: 30000, 
        });
    }

    getDataUser = (codUsuario, chapa, codigo) => {
        return this.instance ({ 
            method: 'GET',
            url: `/dataUser/${codUsuario}/${chapa}/${codigo}`,
            timeout: 30000, 
        });
    }

    getEmployees = () => {
        return this.instance ({
            method: 'GET',
            url: `/employees`,
            timeout: 30000,
        })
    }

    getContratcParcels = (codContrato) => {
        return this.instance ({
            method: 'GET',
            url: `/contractParcels/${codContrato}`,
            timeout: 30000,
        })
    }

    getVacationByPlate = (chapa) => {
        return this.instance({
            method: 'GET',
            url: `/vacationByPlate/${chapa}`,
            timeout: 30000,
        })
    }
}