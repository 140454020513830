<template>
  <section>
    <div>
      <NavBarAtendimentos />
      <div class="columns">
        <SideBarAtendimentos class="menu" />
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'chart-bar']" />
              <h2 class="tituloAtendimento">              
                 Cursos Superiores EAD
              </h2>
            </div>
          </vs-row>

          <div>
            <ValidationObserver ref="observer2" v-slot="{ invalid }">
                <vs-row class="pd-t">
                  <vs-col>
                    <fieldset class="search">
                      <legend>RA do Aluno <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="RA"
                        rules="required|numeric" 
                      >
                        <vs-input
                          @keyup.enter="getEADCoursesByRA()" 
                          v-model="ra"
                          placeholder="Digite o RA do Aluno"
                          class="selectExampleRA"
                        >
                        </vs-input>
                      </ValidationProvider>
                      <vs-button
                        color="primary"
                        class="buscar"
                        type="filled"
                        :disabled="invalid"
                        @click="getEADCoursesByRA()"
                      >
                        <font-awesome-icon :icon="['fas', 'search']" />
                        <span> Buscar </span></vs-button
                      >
                    </fieldset>              
                  </vs-col>
                </vs-row>  
            </ValidationObserver>
          </div>

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>
          </div>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div   v-if="responseRa != null">
              <div v-if="responseRa == ''">
                <p class="validacao">RA Inválido</p>
              </div>
              <div v-else>                    
                <vs-row class="pd-t">
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                    <fieldset>
                      <legend>Nome do Aluno</legend>
                      <vs-input
                        class="selectExampleDouble"
                        :placeholder="responseRa[0].NOME_ALUNO"
                        disabled="disabled"
                      />
                    </fieldset>                    
                  </vs-col>

                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <fieldset>
                      <legend>Curso <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="Curso"
                        rules="required"
                      >
                        <vs-select
                          class="selectExampleDouble"
                          v-model="course"
                          placeholder="Selecione um Curso"
                        >
                          <vs-select-item
                            v-for="(i, index) in responseRa"
                            :key="index"
                            :value="i.CODCURSO"
                            :text="i.NOME"
                          />
                        </vs-select>
                      </ValidationProvider>
                    </fieldset>                    
                  </vs-col>
                </vs-row>

                <vs-row class="pd-t">
                  <vs-col>
                    <ValidationProvider
                      name="Justificativa"
                      rules="required|max: 500"
                    >
                      <vs-textarea
                        label="Justificativa"
                        height="210px"
                        rows="11"
                        class="textArea"
                        counter="500"
                        v-model="solicitation"
                      />
                    </ValidationProvider>
                  </vs-col>
                </vs-row>                   

                <vs-row class="pd-t">
                  <vs-col style="text-align: center;">
                    <vs-button
                      color="danger"
                      type="gradient"
                      class="button-protocol"
                      @click="resetForm()"
                    >
                      <font-awesome-icon :icon="['fas', 'times-circle']" />
                      Cancelar
                    </vs-button>
                    
                    <vs-button
                      class="button-protocol"
                      color="success"
                      type="gradient"
                      @click="createAttendance()"
                      :disabled="invalid"
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                      Gerar
                    </vs-button>
                  </vs-col>            
                </vs-row>
              </div>  
            </div>
          </ValidationObserver> 
        </div>
      </div>
  </div>
  </section>  
</template>

<script>
import Students from "../../../services/students";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import Attendance from "../../../services/attendance";
import EducacionalModel from "../../../models/educacional/educacional.js";

export default {
  name: "CursosSuperioresEAD",
  data: () => ({
    ra: null,
    course: null,
    responseRa: null,
    errorResponseRa: null,
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
  }),

  methods: {
    getEADCoursesByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getEADCoursesByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const studentName = this.responseRa[0].NOME_ALUNO;
      
      const search = (key, inputArray) => {
        for (let i = 0; i < inputArray.length; i++) {
          if (inputArray[i].CODCURSO === key) {
            return inputArray[i];
          }
        }
      };
      let result = search(this.course, this.responseRa);
      let finalNameCourse = result.NOME;

      const payload = new EducacionalModel().getDataByAttendanceId("E3");

      payload.data.locationId = personLocal.toString();
      payload.data.ra = this.ra;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nALUNO: ${studentName}\nCURSO: ${finalNameCourse}\nCÓDIGO DO CURSO: ${this.course}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.course = null;
      this.selectedUnit = null;
      this.solicitation = null;
      this.ra = null;
      this.responseRa = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
