<template>
  <section>
    <div>
      <vs-row class="pd-table">
        <vs-col>
          <fieldset v-if="this.callsInTheUserQueue == ''">
            <p>
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              Nenhum atendimento encontrado
            </p>
          </fieldset>
          <vs-table
            v-else
            :data="callsInTheUserQueue"
            hoverFlat
            search
            pagination
            max-items="10"
            class="table_intranet"
          >
            <template slot="thead">
              <vs-th> ATENDIMENTO </vs-th>
              <vs-th> DATA ABERTURA </vs-th>
              <vs-th> ASSUNTO </vs-th>
              <vs-th> CLASSIFICAÇÃO </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-popup 
                style="z-index: 999999 !important" 
                :title="data[idPopup] ? 'Atendimento - ' + data[idPopup].CODATENDIMENTO : 'Atendimento'" 
                :active.sync="popupAtivo"
              >
                <vs-col>
                  <vs-tabs alignment="center">
                    <vs-tab icon="library_add" label="Solicitação" @click="solicitationAtt()"/>
                    <vs-tab icon="group" label="Discussão" @click="discussionAtt()"/>
                    <vs-tab icon="check_circle" label="Solução" @click="solutionAtt()"/>       
                  </vs-tabs>        
                </vs-col>
                <fieldset v-if="type == 'solicitation'">
                  <p
                    v-html="
                      data[idPopup] ? 
                      parseNewLine(data[idPopup].SOLICITACAO) : 
                      ''
                    "
                  ></p>
                </fieldset>
                <div v-if="type == 'discussion'">
                  <div v-if="!editDiscussion">
                    <fieldset>
                      <p
                        v-html="
                          data[idPopup] ? 
                          parseNewLine(data[idPopup].DISCUSSAO) : 
                          ''
                        "
                      ></p>
                    </fieldset>
                    <!--<vs-button
                      color="success"
                      type="gradient"
                      class="button-discussion"
                      @click="editDiscussion=true;discussion=null"
                    >
                      <font-awesome-icon :icon="['fas', 'edit']" />
                      {{ data[idPopup].DISCUSSAO == 'SEM DISCUSSÃO' ? 'Adicionar Discussão' : 'Atualizar Discussão'}}
                    </vs-button>-->
                  </div>
                  <div v-else>
                    <fieldset>
                      <legend>Digite a Discussão <b style="color: red;">*</b></legend>
                      <vs-textarea
                        rows="11"
                        label=""
                        class="textArea"
                        height="15em"
                        v-model="discussion"
                      />
                    </fieldset>
                    <vs-button
                      color="success"
                      type="gradient"
                      class="button-discussion"
                      :disabled="!discussion"
                      @click="updateDiscussion(data[idPopup])"
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                      {{ data[idPopup].DISCUSSAO == 'SEM DISCUSSÃO' ? 'Adicionar Discussão' : 'Atualizar Discussão'}}
                    </vs-button>
                  </div>
                </div>
                <div v-if="type == 'solution'">
                  <div v-if="!editSolution">
                    <fieldset>
                      <p
                        v-html="
                          data[idPopup] ? 
                          parseNewLine(data[idPopup].SOLUCAO) : 
                          ''
                        "
                      ></p>
                    </fieldset>
                    <!--<vs-button
                      color="success"
                      type="gradient"
                      class="button-solution"
                      @click="editSolution=true;solution=null"
                    >
                      <font-awesome-icon :icon="['fas', 'edit']" />
                      {{ data[idPopup].SOLUCAO == 'SEM SOLUÇÃO' ? 'Adicionar Solução' : 'Atualizar Solução'}}
                    </vs-button>-->
                  </div>
                  <div v-else>
                    <fieldset>
                      <legend>Digite a Solução <b style="color: red;">*</b></legend>
                      <vs-textarea
                        rows="11"
                        label=""
                        class="textArea"
                        height="15em"
                        v-model="solution"
                      />
                    </fieldset>
                    <vs-button
                      color="success"
                      type="gradient"
                      class="button-solution"
                      :disabled="!solution"
                      @click="updateSolution(data[idPopup])"
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                      {{ data[idPopup].SOLUCAO == 'SEM SOLUÇÃO' ? 'Adicionar Solução' : 'Atualizar Solução'}}
                    </vs-button>
                  </div>
                </div>
              </vs-popup>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <a href="#" @click="handleClickDetails(indextr)">{{
                    data[indextr].CODATENDIMENTO
                  }}</a>
                </vs-td>

                <vs-td>
                  {{ data[indextr].DATA_ABERTURA }}
                </vs-td>

                <vs-td>
                  {{ data[indextr].ASSUNTO }}
                </vs-td>

                <vs-td>
                  {{ data[indextr].CLASSIFICACAO }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </div>
  </section>
</template>
<script>
import Management from "../../services/management";
import Attendance from "../../services/attendance";
import {
  getLoginDataFromStorage,
  parseNewLine,
} from "../../helpers/storage.js";

export default {
  name: "AtendAbertosPorMim",
  data: () => ({
    callsInTheUserQueue: [],
    codUser: null,
    type: 'solicitation',
    editDiscussion: false,
    discussion: null,
    editSolution: false,
    solution: null,
    discussionUpdated: null,
    solutionUpdated: null,
    errorCallsInTheUserQueue: null,
    errorUpdateAttendance: null,
    errorSolutionAttendance: null,
    popupAtivo: false,
    idPopup: null,
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    const user1 = user.userData;
    const { CODUSUARIO } = user1;

    this.codUser = CODUSUARIO;

    this.$vs.loading({ text: "Carregando..." });
    new Management()
      .getCallsInTheUserQueue(this.codUser)
      .then(({ data }) => {
        this.callsInTheUserQueue = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorCallsInTheUserQueue = response.data;
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    handleClickDetails(indextr) {
      this.editDiscussion = false;
      this.editSolution = false;
      this.idPopup = indextr;
      setTimeout(() => {
        this.popupAtivo = true;
      }, 100);
    },

    parseNewLine,

    solicitationAtt() {
      this.type='solicitation';
    },

    discussionAtt() {
      this.type='discussion';
      this.editDiscussion = false;
      this.discussion = null;
    },

    solutionAtt() {
      this.type='solution';
      this.editSolution = false;
      this.solution = null;
    },

    updateDiscussion(attendance) {
      this.popupAtivo = false;
      this.$vs.loading();

      const payload = {
        text: this.discussion,
        atttendanceId: attendance.CODATENDIMENTO,
        localId: attendance.CODLOCAL,
        associatedId: attendance.CODCOLIGADA
      };

      new Attendance()
      .updateDiscussion(payload)
      .then(( data ) => {
        this.discussionUpdated = Array.isArray(data) ? data : [data];
        this.editDiscussion = false;

        this.$vs.notify({
          title: 'SUCESSO',
          text: 'Discussão alterada com sucesso!!',
          position:'bottom-center',
          color: 'success'
        })

        this.$vs.loading({ text: "Carregando..." });
        new Management()
          .getCallsInTheUserQueue(this.codUser)
          .then(({ data }) => {
            this.callsInTheUserQueue = Array.isArray(data) ? data : [data];
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.errorCallsInTheUserQueue = response.data;
            }
            this.$vs.loading.close();
          });
      })
      .catch(( response ) => {
        this.errorUpdateAttendance = response.data;

        this.$vs.notify({
          title: 'ERRO',
          text: this.errorUpdateAttendance,
          position:'bottom-center',
          color: 'danger'
        })
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },

    updateSolution(attendance) {
      this.popupAtivo = false;
      this.$vs.loading();

      const payload = {
        text: this.solution,
        atttendanceId: attendance.CODATENDIMENTO,
        localId: attendance.CODLOCAL,
        associatedId: attendance.CODCOLIGADA
      };

      new Attendance()
      .updateSolution(payload)
      .then(( data ) => {
        this.solutionUpdated = Array.isArray(data) ? data : [data];
        this.editDiscussion = false;

        this.$vs.notify({
          title: 'SUCESSO',
          text: 'Solução alterada com sucesso!!',
          position:'bottom-center',
          color: 'success'
        })

        this.$vs.loading({ text: "Carregando..." });
        new Management()
          .getCallsInTheUserQueue(this.codUser)
          .then(({ data }) => {
            this.callsInTheUserQueue = Array.isArray(data) ? data : [data];
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.errorCallsInTheUserQueue = response.data;
            }
            this.$vs.loading.close();
          });
      })
      .catch(( response ) => {
        this.errorSolutionAttendance = response.data;

        this.$vs.notify({
          title: 'ERRO',
          text: this.errorSolutionAttendance,
          position:'bottom-center',
          color: 'danger'
        })
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    }
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}
.tituloAtendimento {
  padding-left: 0.2em;
  margin-top: 5px;
}

.button-discussion,
.button-solution {
  margin: 10px 0.75% 0 0.75%; 
  width: 98.5%;
}

.selectExample,
.selectExampleRA,
.selectExampleDouble {
  width: 25em;
  margin-left: 1em;
}

.erro {
  color: red;
  margin: 0.8em;
}

.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 1180px) {
  .selectExampleDouble {
    width: 17em;
  }
}

@media screen and (max-width: 900px) {
  .selectExampleDouble {
    width: 25em;
  }

  .pd-c {
    padding-top: 1em;
  }
}

@media screen and (max-width: 800px) {
  .selectExampleRA {
    width: 17em;
  }
}

@media screen and (max-width: 650px) {
  .selectExampleRA {
    width: 13em;
    margin-left: 0em;
  }

  .selectExample,
  .selectExampleDouble {
    width: 15em;
  }

  .input-horario {
    width: 5em;
  }
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .selectExample,
  .selectExampleRA,
  .selectExampleDouble {
    width: 11em;
  }

  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }

  .horario > span {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 440px) {
  .percentageInput {
    width: 9em;
  }
}

@media screen and (max-width: 400px) {
  .selectExampleRA {
    width: 9em;
  }

  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
