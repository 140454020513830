<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
             Ajuste de Nota
          </h2>
        </div>
      </vs-row>

      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>RA do Aluno <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="RA"
                  rules="required|numeric"
                   
                >
                  <vs-input
                    @keyup.enter="getCoursesByRA()" 
                    v-model="ra"
                    placeholder="Digite o RA do Aluno"
                    class="selectExampleRA"
                  >
                  </vs-input>
                   
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getCoursesByRA()"
                >
                  <font-awesome-icon :icon="['fas', 'search']" />
                  <span> Buscar </span></vs-button>  
              </fieldset>                        
            </vs-col>  
          </vs-row>            
        </ValidationObserver>
      </div>    
      
      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div   v-if="responseRa != null">
          <div v-if="responseRa == ''">
            <p class="validacao">RA Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Nome do Aluno</legend>
                  <vs-input
                    class="selectExampleDouble"
                    :placeholder="responseRa[0].NOME_ALUNO"
                    disabled="disabled"
                  />
                </fieldset>                    
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Curso <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Curso"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"
                      v-model="course"
                      placeholder="Selecione um Curso"
                      @change="getClassByCourseCode()"
                    >
                      <vs-select-item
                        v-for="(i, index) in responseRa"
                        :key="index"
                        :value="i.CODCURSO"
                        :text="i.NOME"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>                    
              </vs-col>
            </vs-row>             
            
            <vs-row class="pd-t" v-if="responseCourse != null">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Turma <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Turma"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"
                      v-model="selectedClass"
                      placeholder="Selecione uma Turma"
                      @change="getSubjectByRAAndClassCode()"
                    >
                      <vs-select-item
                        v-for="(i, index) in responseCourse"
                        :key="index"
                        :value="i.CODTURMA"
                        :text="i.CODTURMA"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>                    
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Disciplina <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Disciplina"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"
                      v-model="subject"
                      placeholder="Selecione uma Disciplina"
                    >
                      <vs-select-item
                        v-for="(i, index) in responseSubject"
                        :key="index"
                        :value="i.DISCIPLINA"
                        :text="i.DISCIPLINA"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>                                    
              </vs-col>
            </vs-row>

             <vs-row class="pd-t" v-if="subject != null">
              <vs-col>
                <fieldset>
                  <legend>Digite somente as notas que deseja alterar <b style="color: red;">*</b></legend>            
                    <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
                      <ValidationProvider
                        name="N1"
                         
                      >
                        <vs-input 
                          value="-"
                          v-model="N1" 
                          label="N1:" 
                          placeholder="Digite a N1"
                          class="selectExampleDouble"
                          v-mask="'##,##'"
                        />
                         
                      </ValidationProvider>
                    </vs-col>
                    <vs-col class="pd-c" vs-lg="4" vs-sm="12" vs-xs="12">
                      <ValidationProvider
                        name="N2"
                         
                      >
                        <vs-input 
                          v-model="N2" 
                          label="N2:" 
                          placeholder="Digite a N2" 
                          class="selectExampleDouble"
                          v-mask="'##,##'"
                          />
                         
                      </ValidationProvider> 
                    </vs-col> 
                    <vs-col class="pd-c" vs-lg="4" vs-sm="12" vs-xs="12">
                      <ValidationProvider
                        name="R1"
                         
                      >
                        <vs-input 
                          v-model="R1" 
                          label="R1:" 
                          placeholder="Digite a R1" 
                          class="selectExampleDouble"
                          v-mask="'##,##'"
                          />
                         
                      </ValidationProvider> 
                    </vs-col>                  
                </fieldset>            
              </vs-col>                        
            </vs-row>     

            <vs-row class="pd-t" v-if="subject != null">
              <vs-col>
                <ValidationProvider
                  name="Justificativa"
                  rules="required"
                   
                >
                  <vs-textarea
                    label="Justificativa"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Students from "../../../../services/students";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    course: null,
    responseCourse: null,
    errorResponseCourse: null,
    selectedClass: null,
    responseSubject: null,
    errorResponseSubject: null,
    subject: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    N1:"",
    N2:"",
    R1:"",
  }),
  methods: {
    getCoursesByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCoursesByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
    getClassByCourseCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getClassByCourseId(this.ra, this.course)
        .then(({ data }) => {
          this.responseCourse = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseCourse = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
    getSubjectByRAAndClassCode() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getSubjectByRAAndClassCode(this.ra, this.selectedClass)
        .then(({ data }) => {
          this.responseSubject = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseSubject = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E6-2");

      const studentName = this.responseRa[0].NOME_ALUNO;

      const search = (key, inputArray) => {
        for (let i = 0; i < inputArray.length; i++) {
          if (inputArray[i].CODCURSO === key) {
            return inputArray[i];
          }
        }
      };
      let result = search(this.course, this.responseRa);
      let finalNameCourse = result.NOME;

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.ra = this.ra;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA: ${this.ra}\nNOME ALUNO: ${studentName}\nCURSO: ${finalNameCourse}\nCÓDIGO TURMA: ${this.selectedClass}\nDISCIPLINA: ${this.subject}\n\nNOTAS PARA ALTERAÇÃO: \nN1: ${this.N1}\nN2: ${this.N2}\nR1: ${this.R1}\n\nSOLICITAÇÃO: ${this.solicitation}`
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.course = null;
      this.selectedClass = null;
      this.subject = "";
      this.solicitation = null;
      this.ra = null;
      this.responseRa = null;
      this.N1 = "";
      this.N2 = "";
      this.R1 = "";

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
