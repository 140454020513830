import axios from 'axios'

export const https = (token) => {
  const instance = axios.create({
    baseURL: 'https://native01.qi.edu.br/api',    
    headers: {
      'accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })
  return instance
}

export const https2 = () => {    
  const instance = axios.create({
    baseURL: 'https://native01.qi.edu.br/api',
    headers: {
        'Authorization': 'Basic ',
        'Content-type': 'application/json'
    }, 
  })
  return instance
}