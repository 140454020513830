export default class {
    constructor() {
        this.instance =
            [
                {
                    typeAttendanceId: "M1", /* MARKETING / CRIAÇÃO OU COMUNICAÇÃO */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "3057",
                        respColligateId: "1",
                        classificationId: "00055",
                        subject: "CRIAÇÃO OU COMUNICAÇÃO",
                        typeAttendanceId: "16", 
                        groupAttendanceId: "8", 
                        personId: "",
                        requestText: ``,
                        productId: "33",
                        accessLevel: "2",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "M2", /* MARKETING / PUBLICAÇÃO DE RELEASES */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "3057",
                        respColligateId: "1",
                        classificationId: "00056",
                        subject: "PUBLICAÇÃO DE RELEASES",
                        typeAttendanceId: "16", 
                        groupAttendanceId: "8", 
                        personId: "",
                        requestText: ``,
                        productId: "33",
                        accessLevel: "2",
                        attachment: "",
                        fileName: "",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },

                {
                    typeAttendanceId: "M3", /* MARKETING / SOLICITAÇÃO DE MATERIAL */
                    data:  {
                        colligateId: "1",
                        locationId: "",
                        respAttendantId: "3057",
                        respColligateId: "1",
                        classificationId: "00054",
                        subject: "SOLICITAÇÃO DE MATERIAL",
                        typeAttendanceId: "16", 
                        groupAttendanceId: "8", 
                        personId: "",
                        requestText: ``,
                        productId: "33",
                        accessLevel: "2",
                        clientType: "Pessoa",
                        attendantId: "",
                    }
                },
            ]
    }

    getDataByAttendanceId(typeAttendanceId) {
        for (let i=0; i<this.instance.length; i++){
            if(this.instance[i].typeAttendanceId == typeAttendanceId)
                return this.instance[i];
        }
    }
}  