import { https } from './api';

export default class {
    constructor() {
        this.instance = https('events')
    }

    getTopNews = () => {
        return this.instance({
            method: 'GET',
            url: '/top',
        });
    }

    getTimeLine = () => {
        return this.instance({
            method: 'GET',
            url: `/timeline`,
        });
    }

    addNews = (data) => {
        return this.instance({
            method: 'POST',
            url: `/`,
            data,
        });
    }

    updateNews = (data) => {
        return this.instance({
            method: 'PUT',
            url: `/`,
            data,
        });
    }
}