<template>
  <div>
    <Navbar />
    <div class="columns">
    <SideBarHome class="menu"/>
      <section class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'newspaper']" />
            <h2 class="tituloAtendimento">
              Acontecimentos QI
            </h2>
          </div>
        </vs-row>

        <vs-row>
          <vs-col>
            <vs-col
              v-if="addNotice == true"
              class="alignButton"
            >
              <vs-button @click="openPopupAddNew()">
                <font-awesome-icon :icon="['fas', 'plus']" />
                Adicionar Notícia
              </vs-button>
            </vs-col>

            <TimeLine />
          </vs-col>
        </vs-row>

        <vs-popup style="z-index: 15000 !important" title="Adicionar Notícia" :active.sync="popupAddNew">
          <vs-card outlined>
            <div>
              <vs-row class="pd-t">
                <vs-col>
                  <fieldset v-if="newDestination == true">
                    <legend style="font-size: 1.15em;">Destino <b style="color: red;">*</b></legend>
                    <vs-select
                      class="selectNew"
                      v-model="selectedDestination"
                      placeholder="Selecione o Destino da Notícia"
                    >
                      <vs-select-item
                        :key="item.destinationNew"
                        :value="item.value"
                        :text="item.text"
                        v-for="item in destinationNew"
                      />
                    </vs-select>
                  </fieldset>

                  <fieldset v-else>
                    <legend style="font-size: 1.15em;">Destino</legend>
                    <vs-select
                      class="selectNew"
                      placeholder="Intranet"
                      disabled
                    >
                    </vs-select>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <div>
                    <vs-alert :color="colorAlert" v-if="visibleNews">
                      <span class="centerx"> {{ textNews }} </span>
                    </vs-alert>
                  </div>
                </vs-col>
              </vs-row>

              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <vs-row class="pd-t">
                  <vs-col>
                    <fieldset>
                      <legend style="font-size: 1.15em;">Título <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="Título"
                        rules="required|max:100"
                      >
                        <vs-input
                          class="selectNew"
                          v-model="titleNew"
                          placeholder="Digite o Título da Notícia"
                        />
                      </ValidationProvider>
                      <p style="margin-top:10px;color:#888;font-style:italic;">* Tamanho máximo: 100 caracteres</p>
                    </fieldset>
                  </vs-col>
                </vs-row>

                <vs-row class="pd-t" v-if="selectedDestination != 'Intranet'">
                  <vs-col>
                    <fieldset>
                      <legend style="font-size: 1.15em;">Subtítulo <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="Subtítulo"
                        rules="required|max:100"
                      >
                        <vs-input
                          class="selectNew"
                          v-model="captionNew"
                          placeholder="Digite o Subtítulo da Notícia"
                        />
                      </ValidationProvider>
                      <p style="margin-top:10px;color:#888;font-style:italic;">* Tamanho máximo: 100 caracteres</p>
                    </fieldset>
                  </vs-col>
                </vs-row>

                <vs-row class="pd-t">
                  <vs-col>
                    <fieldset>
                      <legend style="font-size: 1.15em;">Anexo <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="Anexo"
                        ref="provider"
                        rules="required|size: 400|image"
                      >
                        <input
                          class="selectNew"
                          ref="image"
                          type="file"
                          @change="onfilesUpload"
                        />
                      </ValidationProvider>
                      <p style="margin-top:10px;color:#888;font-style:italic;">* Tamanho máximo: 400kb</p>
                    </fieldset>
                  </vs-col>
                </vs-row>

                <vs-row class="pd-t">
                  <vs-col>
                    <fieldset>
                      <legend style="font-size: 1.15em;">Texto da Notícia <b style="color: red;">*</b></legend>
                      <ValidationProvider
                        name="Texto da Notícia"
                        rules="required"
                      >
                        <ckeditor
                          :editor="editor"
                          v-model="editorData"
                          :config="editorConfig"
                        >
                        </ckeditor>
                      </ValidationProvider>
                    </fieldset>
                  </vs-col>
                </vs-row>

                <vs-row class="pd-t">
                  <vs-col style="text-align: center;">
                    <vs-button
                      v-if="visibleAddButton"
                      class="button-protocol"
                      color="success"
                      type="gradient"
                      @click="addNew()"
                      :disabled="invalid"
                    >
                      Adicionar
                    </vs-button>

                    <vs-button
                      color="danger"
                      type="gradient"
                      class="button-protocol"
                      @click="resetForm()"
                    >
                      Cancelar
                    </vs-button>
                  </vs-col>
                </vs-row>
              </ValidationObserver>
            </div>
          </vs-card>
        </vs-popup>
     </section>
    </div>
  </div>
</template>

<script>
import { getLoginDataFromStorage } from "../../helpers/storage";
import TimeLine from "../../components/TimeLine.vue";
import Events from "../../services/events";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    TimeLine,   
  },
  name: "Acontecimentos",
  data:()=>({
    active: false,
    popupAddNew: false,
    profileAddNotice: null,
    addNotice: false,
    profileDestination: null,
    newDestination: false,
    error: null,
    files: null,
    visibleNews: false,
    colorAlert: "success",
    profiles: [],
    visibleAddButton: false,
    errorAddNew: "",
    destinationNew: [
      { text: "Intranet", value: "Intranet" },
      { text: "Portal do Aluno", value: "PortalAluno" },
      { text: "Intranet e Portal do Aluno", value: "Intra/Portal" },
    ],
    titleNew: "",
    captionNew: "",
    textNews: "",
    selectedDestination: "Intranet",
    editor: ClassicEditor,
    editorData: "",
    editorConfig: {
      toolbar: {
        removeItems: ["uploadImage"],
      },
      placeholder: "Digite o Texto Da Notícia",
    },
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    this.profiles = user.profiles;

    const profileAddNotice = this.profiles.find(element => element == "DBA" || element == "PostaNoticias" || element == "Marketing_QI" || element == "Gerente_QI");
    const profileDestination = this.profiles.find(element => element == "Marketing_QI" || element == "DBA");
    
    if(profileAddNotice) {
      this.addNotice = true;
    } else {
      this.addNotice = false;
    }

    if(profileDestination) {
      this.newDestination = true;
    } else {
      this.newDestination = false;
    }
  },

  methods: {
    async onfilesUpload(event) {
      this.files = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.files);
      if (valid) {
        this.visibleAddButton = true;
      }
    },

    openPopupAddNew() {
      this.popupAddNew = true;
      this.resetForm();
    },

    addNew() {
      this.$vs.loading({ text: "Carregando..." });

      let title = this.titleNew.replace(/`/g, "&#39");
      let caption = this.captionNew.replace(/`/g, "&#39");
      let textNew = this.editorData.replace(/`/g, "&#39").replace(/"/g, "&quot;");

      const payloadAddNew = `{        
        "title": "${title}",
        "caption": "${caption}",
        "text": "${textNew}",
        "destination": "${
          this.selectedDestination == "PortalAluno" ? "PortalAluno" : 
          this.selectedDestination == "Intra/Portal" ? "Intra/Portal" :
          "Intranet"
      }"}`;

      const formData = new FormData();
      formData.append("imageFile", this.files, this.files.name);
      formData.append("payload", payloadAddNew.toString());

      new Events()
      .addNews(formData)
      .then(({ data }) => {
        this.resetForm();
        this.visibleNews = true;
        this.colorAlert = "success";

        if (this.selectedDestination == "PortalAluno") {
          this.textNews = `Notícia ${title} adicionada no Portal do Aluno`;
        } else if (this.selectedDestination == "Intra/Portal") {
          this.textNews = `Notícia ${title} adicionada na Intranet e no Portal do Aluno`;
        } else {
          this.textNews = `Notícia ${title} adicionada na Intranet`;
        }
        data;

        this.$vs.loading.close();
        this.resetForm();          
        this.getNews();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.$vs.loading.close();
          this.visibleNews = true;
          this.textNews = "Desculpe ocorreu um erro. Por favor entre em contato com o Suporte.";
          this.colorAlert = "danger";
          this.errorAddNew = response.data;
          console.log(response);
          this.resetForm();      

          setTimeout(() => {            
            this.visibleNews = false;                                                          
            this.getNews();                        
          }, 3500);
        }
      });
    },

    resetForm() {
      this.files = null;
      this.titleNew = "";
      this.selectedDestination = "Intranet";
      this.captionNew = "";
      this.editorData = "";
      this.visibleAddButton = false;
      this.$refs.image.value = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  }
};
</script>

<style scoped>
.alignButton {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerx {
  font-size: 1.15em;
  font-weight: bold;
  white-space: normal;
}

.selectNew {
  width: 100%;
}

@media screen and (max-width: 550px) {
  .centerx {
    font-size: 1em;
  }
}

@media screen and (max-width: 495px) {
  .centerx {
    padding-bottom: 1%;
  }
}

@media screen and (max-width: 460px) {
  .selectNew {
    width: 18.5em;
  }
}

@media screen and (max-width: 380px) {
  .selectNew {
    width: 14.5em;
  }
}
</style>
