import { render, staticRenderFns } from "./TurmasFuturas.vue?vue&type=template&id=784f623f&scoped=true"
import script from "./TurmasFuturas.vue?vue&type=script&lang=js"
export * from "./TurmasFuturas.vue?vue&type=script&lang=js"
import style0 from "./TurmasFuturas.vue?vue&type=style&index=0&id=784f623f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784f623f",
  null
  
)

export default component.exports