<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'gavel']" />
              <h2 class="tituloAtendimento">
                Solicitações NETEP
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.value"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>
              </fieldset>                                  
            </vs-col>  
          </vs-row>
        </div>

        <AlunoQI v-if="this.selectedTypes == 1" />
        <AlunoExterno v-if="this.selectedTypes == 2" />
        <SolicitacaoInterna v-if="this.selectedTypes == 3" />
   
      </div>      
    </div>            
  </section>
</template>

<script>
import AlunoQI from "./FormNETEP/AlunoQI.vue"
import AlunoExterno from "./FormNETEP/AlunoExterno.vue"
import SolicitacaoInterna from "./FormNETEP/SolicitacaoInterna.vue"

export default {
  name: "SolicitaçãoNETEP",
  components: {
    AlunoQI,
    AlunoExterno,
    SolicitacaoInterna,
  },
  data: () => ({
    types: [
      { text: "Solicitação para Aluno QI", value: 1 },
      { text: "Solicitação para Aluno Externo", value: 2},
      { text: "Solicitação Interna", value: 3},
    ],
    selectedTypes: null,
  }),
};
</script>
