<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'book']" />
            <h2 class="tituloAtendimento">Ocupação de Sala</h2>
          </div>
        </vs-row>

        <div class="flex">
          <span v-for="(i, index) in subtitle" :key="index" class="itens">
            <figure
              class="figure-flex"
              :style="{ 'background-color': i.COR }"
            ></figure>
            <p class="texto-legenda">{{ i.NOME }}</p>
          </span>
        </div>

        <vs-divider/>

        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <vs-row class="itensOptions">
            <ValidationProvider 
              name="Filial" 
              rules="required"
            >
              <vs-col>
                <vs-select
                  class="opcao_select"
                  v-model="selectedUnit"
                  placeholder="Selecione uma Filial"
                >
                  <vs-select-item
                    :key="item.CODFILIAL"
                    :value="item.CODFILIAL"
                    :text="item.POLO"
                    v-for="item in units"
                  />
                </vs-select>
              </vs-col>
            </ValidationProvider>

            <ValidationProvider 
              name="Mês" 
              rules="required"
            >
              <vs-col>
                <vs-select
                  class="opcao_select"
                  v-model="mesSelecionado"
                  placeholder="Selecione um mês"
                >
                  <vs-select-item
                    :key="item.meses"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in meses"
                  />
                </vs-select>
              </vs-col>
            </ValidationProvider>

            <ValidationProvider 
              name="Ano" 
              rules="required"
            >
              <vs-col>
                <vs-select
                  class="opcao_select"
                  v-model="anoSelecionado"
                  placeholder="Selecione um ano"
                >
                  <vs-select-item
                    :key="item.anos"
                    v-for="item in anos"
                    :text="item.i"
                    :value="item.i"
                  />
                </vs-select>
              </vs-col>
            </ValidationProvider>
            
            <vs-col style="text-align: center;">
              <vs-button
                color="success"
                type="gradient"
                class="button-protocol"
                :disabled="invalid"
                @click="getOccupancyClasses()"
              >
                <font-awesome-icon :icon="['fas', 'search']" />
                Buscar
              </vs-button>

              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="reset()"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
                Limpar
              </vs-button>
            </vs-col>
          </vs-row>
        </ValidationObserver>

        <vs-row class="box-center" v-if="classes != null">
          <div v-if="classes == ''">
            <p>Sem resultados para mostrar</p>
          </div>

          <div v-else>
            <div v-for="(tr, index) in payload" :key="index">
              <vs-divider style="margin-top: 0;"/>
              <fieldset class="calendar">
                <p class="text_rooms">{{ payload[index][0].SALA }}</p>
                <FullCalendar :options="eventsCalendar[index]" />
              </fieldset>
            </div>
          </div>
        </vs-row>
      </div>
    </div>
  </section>
</template>

<script>
import SubtitleQuery from "../../services/units";
import Units from "../../services/units";
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { convertDate } from "../../helpers/storage";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    FullCalendar,
  },
  data: () => ({
    subtitle: [],
    error: null,
    units: [],
    mesSelecionado: null,
    meses: [
      { text: "Janeiro", value: 1 },
      { text: "Fevereiro", value: 2 },
      { text: "Março", value: 3 },
      { text: "Abril", value: 4 },
      { text: "Maio", value: 5 },
      { text: "Junho", value: 6 },
      { text: "Julho", value: 7 },
      { text: "Agosto", value: 8 },
      { text: "Setembro", value: 9 },
      { text: "Outubro", value: 10 },
      { text: "Novembro", value: 11 },
      { text: "Dezembro", value: 12 },
    ],
    selectedUnit: null,
    anos: [],
    anoSelecionado: null,
    dia: null,
    occupancyClasses: null,
    classes: null,
    payload: [],
    eventsCalendar: [],
  }),

  mounted() {
    this.reset();

    this.$vs.loading({ text: "Carregando..." });
    new SubtitleQuery()
      .getSubtitleQuery()
      .then(({ data }) => {
        this.subtitle = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    getOccupancyClasses() {
      this.classes = null;
      this.eventsCalendar = [];
      this.occupancyClasses = [];
      this.payload = [];
      if (this.selectedUnit && this.mesSelecionado && this.anoSelecionado) {
        this.$vs.loading({ text: "Carregando..." });
        new Units()
          .getOccupancyClasses(
            this.selectedUnit,
            `${this.anoSelecionado}-${this.mesSelecionado}-${this.dia}`
          )
          .then(({ data }) => {
            this.occupancyClasses = Array.isArray(data) ? data : [data];

            this.classes = this.mountArrayOfClasses(
              this.occupancyClasses,
              "SALA"
            );

            for (let i in this.classes) {
              this.payload.push(this.classes[i]);
            }

            for (let i = 0; i < this.payload.length; i++) {
              this.eventsCalendar.push({
                plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
                initialDate:
                  this.anoSelecionado +
                  "-" +
                  (this.mesSelecionado < 10
                    ? "0" + this.mesSelecionado
                    : this.mesSelecionado),
                locale: "pt",
                slotDuration: "01:00",
                slotMinTime: "08:00",
                slotMaxTime: "23:00",
                timeZone: "UTC",
                navLinks: true,
                dayMaxEventRows: true,
                editable: false,
                initialView: "timeGridWeek",
                allDaySlot: false,
                height: "auto",
                hiddenDays: [0],
                events: [],

                eventClick: function (info) {
                  alert(info.event.extendedProps.description);
                },

                titleFormat: {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                },

                views: {
                  dayGridMonth: {
                    titleFormat: {
                      year: "numeric",
                      month: "long",
                    },
                    dayHeaderFormat: {
                      weekday: "short",
                    },
                    dayMaxEventRows: 3,
                  },
                },

                headerToolbar: {
                  left: "prev dayGridMonth",
                  center: "title",
                  right: "timeGridWeek next",
                },

                buttonText: {
                  dayGridMonth: "Mês",
                  timeGridWeek: "Semana",
                },

                dayHeaderFormat: {
                  weekday: "short",
                  month: "numeric",
                  day: "numeric",
                },
              });
            }

            for (let i = 0; i < this.eventsCalendar.length; i++) {
              let obj = this.payload[i];
              let payload = this.eventsCalendar[i].events;
              for (let i = 0; i < obj.length; i++) {
                payload.push({
                  id: obj[i].ID,
                  groupId: obj[i].SALA,
                  title: obj[i].PROFESSOR,
                  start: obj[i].start,
                  end: obj[i].end,
                  color: obj[i].color,
                  description: `Professor Titular: ${obj[i].PROFESSOR}\nProfessor Substituto: ${obj[i].PROFESSORSUBS}\nCurso: ${
                    obj[i].NOME
                  }\nDisciplina: ${obj[i].DISCIPLINA}\nSala: ${
                    obj[i].SALA
                  }\nQuantidade de Alunos: ${obj[i].QTDALUNOS}\nID da Turma: ${
                    obj[i].ID
                  }\nCódigo da Turma: ${
                    obj[i].CODTURMA
                  }\nPeríodo: ${convertDate(obj[i].DTINICIAL)} - ${convertDate(
                    obj[i].DTFINAL
                  )}\nTurno: ${obj[i].TURNO}\nHorário:  ${
                    obj[i].HORAINICIAL
                  } - ${obj[i].HORAFINAL}`,
                });
              }
            }

            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.error = response.data;
            }
            this.$vs.loading.close();
          });
      }
    },

    mountArrayOfClasses(array, property) {
      return array.reduce((object, element) => {
        const group = element[property];
        if (!object[group]) {
          object[group] = [];
        }
        object[group].push(element);
        return object;
      }, {});
    },

    reset() {
      this.selectedUnit = null;
      this.mesSelecionado = null;
      this.anoSelecionado = null;
      this.classes = null;
      this.eventsCalendar = [];
      this.occupancyClasses = [];
      this.payload = [];
      this.anos = [];

      this.date = new Date();
      var currentMonth = this.date.getMonth() + 1;
      this.mesSelecionado = currentMonth;
      this.dia = 1;
      let ano = this.date.getFullYear();
      for (let i = 2020; i <= ano + 1; i++) {
        this.anos.push({ i });
      }
      var test = this.anos[this.anos.length - 1];
      this.anoSelecionado = test.i - 1;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.box-center {
  position: sticky;
  z-index: 2;
}

.fc-toolbar-chunk {
  font-size: 1.5em !important;
  margin: 0;
}

.flex {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 600px));
  column-gap: 1em;
  row-gap: 0.3em;
  padding: 1em 0.5em 0.5em 0.5em;
}

.itens {
  display: flex;
}

.itensOptions {
  display: flex;
  justify-content: center;
  margin: 1em 0;
  gap: 1em;
}

.opcao_select {
  width: 15em;
}

.figure-flex {
  width: 0.65em;
  height: 0.25em;
  border-radius: 0.7em;
  margin: 0.2em 0.4em;
}

.texto-legenda {
  font-size: 0.65em;
  font-weight: bold;
}

.calendar {
  margin: 0 0.85em 0.85em 0.85em !important;
}

.text_rooms {
  border: 1px solid;
  border-radius: 50px;
  padding: 5px;
  font-size: 1.5em;
  margin-bottom: 1.85%;
  text-align: center;
}

@media screen and (max-width: 1185px) {
  .flex {
    grid-template-columns: repeat(2, minmax(auto, auto));
  }
}

@media screen and (max-width: 1080px) {
  .opcao_select {
    width: 10.5em;
  }
}

@media screen and (max-width: 920px) {
  .flex {
    grid-template-columns: none;
  }

  .opcao_select {
    width: 9.5em;
  }
}

@media screen and (max-width: 790px) {
  .opcao_select {
    width: 10.5em;
  }
}

@media screen and (max-width: 650px) {
  .text_rooms {
    font-size: 1.35em;
  }
}

@media screen and (max-width: 600px) {
  .opcao_select {
    width: 25em;
  }
}

@media screen and (max-width: 465px) {
  .texto-legenda {
    font-size: 0.6em;
  }

  .figure-flex {
    width: 0.6em;
    border-radius: 0.9em;
  }

  .opcao_select {
    width: 21.5em;
  }

  .text_rooms {
    font-size: 1.15em;
  }
}

@media screen and (max-width: 430px) {
  .texto-legenda {
    font-size: 0.55em;
  }

  .figure-flex {
    width: 0.55em;
    border-radius: 0.95em;
  }
}

@media screen and (max-width: 400px) {
  .texto-legenda {
    font-size: 0.5em;
  }

  .figure-flex {
    width: 0.5em;
    border-radius: 1em;
  }

  .opcao_select {
    width: 17.5em;
  }
}
</style>
