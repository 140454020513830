<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'desktop']" />
            <h2 class="tituloAtendimento">                        
               Publicação de Releases
            </h2>
          </div>
        </vs-row>            

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Nome do Evento <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Nome do Evento"
                    rules="required"
                     
                  >
                    <vs-input v-model="eventName" placeholder="Digite o Nome do Evento" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col> 

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Local do Evento <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Local do Evento"
                    rules="required"
                     
                  >
                    <vs-input v-model="eventPlace" placeholder="Digite o Local do Evento" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>                      
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Data da Realização <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Data da Realização"
                    rules="required"
                     
                  >
                    <vs-input type="date" v-model="dateOfRealization" class="selectExample"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>                        
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Objetivos do Evento"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Objetivos do Evento:"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="eventObjective"
                  />
                 
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Participantes"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Participantes:"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="participant"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Principais Momentos"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Principais Momentos:"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="mainMoments"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Declaração do Gerente da Escola sobre a importância ou o resultado do evento"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Declaração do Gerente da Escola sobre a importância ou o resultado do evento:"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="managerStatement"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Anexo <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Anexo"
                    ref="provider"
                    rules="required"
                     
                  >
                    <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Depoimentos recolhidos durante o evento. (Se houver)"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Depoimentos recolhidos durante o evento. (Se houver):"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="testimonialEvent"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row> 
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import Attendance from "../../../services/attendance";
    import { getLoginDataFromStorage, convertDate } from "../../../helpers/storage.js";
    import MarketingModel from "../../../models/marketing/marketing.js"
    export default {
      name: "PublicacaoDeReleases",
      data: () => ({
        files: null,
        base64Image: null,
        idAttendance: null,
        errorIdAttendance: null,
        eventName: null,
        dateOfRealization: null,
        eventPlace: null,
        eventObjective: null,
        participant: null,
        mainMoments: null,
        managerStatement: null,
        testimonialEvent: null,
        visibleButton: false,
      }),

    methods: {

      async onFileUpload (event) {
        this.files = event.target.files[0];                            
        const { valid } = await this.$refs.provider.validate(this.files);   
        if (valid) {                                   
          this.createBase64Image(this.files);
          this.visibleButton = true;
        }
      },

      createBase64Image(file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          var bynaryData = event.target.result;

          this.base64Image = window.btoa(bynaryData);

        };
        reader.readAsBinaryString(file);
      },

      async createAttendance() {
        this.idAttendance = null;
        this.errorIdAttendance = null;
        
        const { ...userProfile } = getLoginDataFromStorage();

        const personId = userProfile.userData.CODIGO;
        const personLocalId = userProfile.userData.CODLOCAL;
        const personName = userProfile.userData.NOME;
        const attendantId = userProfile.userData.CODATENDENTE;

        const payload = new MarketingModel().getDataByAttendanceId('M2');

        payload.data.locationId = personLocalId.toString();
        payload.data.personId = personId.toString();
        payload.data.attachment = this.base64Image;
        payload.data.fileName = this.files.name;
        payload.data.attendantId = attendantId.toString();
        payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME DO EVENTO: ${this.eventName}\nDATA DA REALIZAÇÃO: ${convertDate(this.dateOfRealization)}\nLOCAL DO EVENTO: ${this.eventPlace}\nOBJETIVOS DO EVENTOS: ${this.eventObjective}\nPARTICIPANTES: ${this.participant}\nPRINCIPAIS MOMENTOS: ${this.mainMoments}\nDECLARAÇÃO DO GERENTE DA ESCOLA SOBRE A IMPORTÂNCOA OU O RESULTADO DO EVENTO: ${this.managerStatement}\nDEPOIMENTOS RECOLHIDOS DURANTE O EVENTO: ${this.testimonialEvent}`;

        this.$vs.loading({ text: "Carregando..." });
        new Attendance()
          .createAttendance(payload.data)
          .then(({ data }) => {
          this.idAttendance = data;
          })
          .catch(({ response }) => {
          if (response && response.data) {
              this.errorIdAttendance = response.data;
              console.log(response)
          }
          })
          .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
          });
      },

        resetForm() {
          this.eventName = null;
          this.dateOfRealization = null;
          this.eventPlace = null;
          this.eventObjective = null;
          this.participant = null;
          this.mainMoments = null;
          this.managerStatement = null;
          this.testimonialEvent = null;
          this.visibleButton = false;
          this.$refs.image.value=null;

          this.$nextTick(() => {
              this.$refs.observer.reset();
          });
        },
      }
    }
</script>
