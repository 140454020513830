<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'wallet']" />
              <h2 class="tituloAtendimento">
                Demanda Comercial
              </h2>
            </div>
          </vs-row>
          
          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Texto Solicitação *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>                   
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Units from "../../../services/units";
import Attendance from "../../../services/attendance";
import AdministrativoModel from "../../../models/administrativo/administrativo";
import {
  getLoginDataFromStorage,
} from "../../../helpers/storage.js";

export default {
  name: "DemandaComercial",

  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A16');   

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;


      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },
    resetForm() {
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 440px) {
  .percentageInput {
    width: 9em;
  }
}
</style>

