<template>
  <div>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'cogs']" />
              <h2 class="tituloAtendimento">
                Atendimento Solicitação de Alunos
              </h2>
            </div>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in options"
                  />
                </vs-select>
              </fieldset>
            </vs-col>                              
          </vs-row>                  
        </div>

        <Certificado v-if="typeSelected == 1" />
        <Historico v-if="typeSelected == 2" />
        <Diploma v-if="typeSelected == 3" />
        <NotaFiscal v-if="typeSelected == 4" />
        <RetornoDeTrancamento v-if="typeSelected == 5" />
        <TermoDeEstagio v-if="typeSelected == 6" />
        <SolicitacoesGraduacaoEAD v-if="typeSelected == 7" />
        <FormaturaGabinete v-if="typeSelected == 8" />
        <FormaturaSolene v-if="typeSelected == 9" />
        <OutrasSolicitacoes v-if="typeSelected == 10" />
        <TransferenciaDeFilial v-if="typeSelected == 11" />
        <TransferenciaDeTurmaETurno v-if="typeSelected == 12" />
        <NegociacaoDeParcelaEmAtraso v-if="typeSelected == 13" />
        <TrancamentoGraduacao v-if="typeSelected == 14" />
        <TrancamentoCursosLivres v-if="typeSelected == 15" />
        <TrocaDeCurso v-if="typeSelected == 16" />
        <Cancelamento v-if="typeSelected == 17" />
        <FormaturaGabineteCursosLivres v-if="typeSelected == 18" />
        <FormaturaSoleneCursosLivres v-if="typeSelected == 19" />
        <FormaturaGabineteCursosTecnicos v-if="typeSelected == 20" />
        <FormaturaSoleneCursosTecnicos v-if="typeSelected == 21" />
        <SolicitacaoNADD v-if="typeSelected == 22" />
        <Ementa v-if="typeSelected == 23" />
      </div>
    </div>        
  </div>
</template>

<script>
import Certificado from "./FormSolicitacaoDeAlunos/Certificado.vue";
import Historico from "./FormSolicitacaoDeAlunos/Historico.vue";
import Diploma from "./FormSolicitacaoDeAlunos/Diploma.vue";
import NotaFiscal from "./FormSolicitacaoDeAlunos/NotaFiscal.vue";
import RetornoDeTrancamento from "./FormSolicitacaoDeAlunos/RetornoDeTrancamento.vue";
import TermoDeEstagio from "./FormSolicitacaoDeAlunos/TermoDeEstagio.vue";
import SolicitacoesGraduacaoEAD from "./FormSolicitacaoDeAlunos/SolicitacoesGraduacaoEAD.vue";
import FormaturaGabinete from "./FormSolicitacaoDeAlunos/FormaturaGabinete.vue";
import FormaturaSolene from "./FormSolicitacaoDeAlunos/FormaturaSolene.vue";
import OutrasSolicitacoes from "./FormSolicitacaoDeAlunos/OutrasSolicitacoes.vue";
import TransferenciaDeFilial from "./FormSolicitacaoDeAlunos/TransferenciaDeFilial.vue";
import TransferenciaDeTurmaETurno from "./FormSolicitacaoDeAlunos/TransferenciaDeTurmaETurno.vue";
import NegociacaoDeParcelaEmAtraso from "./FormSolicitacaoDeAlunos/NegociacaoDeParcelaEmAtraso.vue";
import TrancamentoGraduacao from "./FormSolicitacaoDeAlunos/TrancamentoGraduacao.vue";
import TrancamentoCursosLivres from "./FormSolicitacaoDeAlunos/TrancamentoCursosLivres.vue";
import TrocaDeCurso from "./FormSolicitacaoDeAlunos/TrocaDeCurso.vue";
import Cancelamento from "./FormSolicitacaoDeAlunos/Cancelamento.vue";
import FormaturaGabineteCursosLivres from "./FormSolicitacaoDeAlunos/FormaturaGabineteCursosLivres.vue";
import FormaturaSoleneCursosLivres from "./FormSolicitacaoDeAlunos/FormaturaSoleneCursosLivres.vue";
import FormaturaGabineteCursosTecnicos from "./FormSolicitacaoDeAlunos/FormaturaGabineteCursosTecnicos.vue";
import FormaturaSoleneCursosTecnicos from "./FormSolicitacaoDeAlunos/FormaturaSoleneCursosTecnicos.vue";
import SolicitacaoNADD from "./FormSolicitacaoDeAlunos/SolicitacaoNADD.vue";
import Ementa from "./FormSolicitacaoDeAlunos/Ementa.vue";

export default {
  name: "AjusteDiarioDeClasse",
  components: {
    Certificado,
    Historico,
    Diploma,
    NotaFiscal,
    RetornoDeTrancamento,
    TermoDeEstagio,
    SolicitacoesGraduacaoEAD,
    FormaturaGabinete,
    FormaturaSolene,
    OutrasSolicitacoes,
    TransferenciaDeFilial,
    TransferenciaDeTurmaETurno,
    NegociacaoDeParcelaEmAtraso,
    TrancamentoGraduacao,
    TrancamentoCursosLivres,
    TrocaDeCurso,
    Cancelamento,
    FormaturaGabineteCursosLivres,
    FormaturaSoleneCursosLivres,
    FormaturaGabineteCursosTecnicos,
    FormaturaSoleneCursosTecnicos,
    SolicitacaoNADD,
    Ementa
  },
  data: () => ({
    typeSelected: "",
    options: [
      { text: "Certificado", value: 1 },      
      { text: "Histórico", value: 2 },
      { text: "Diploma", value: 3 },
      { text: "Nota Fiscal", value: 4 },     
      { text: "Retorno de Trancamento", value: 5 },
      { text: "Termo de Estágio", value: 6 },
      { text: "Solicitações Graduação EAD", value: 7 },
      { text: "Formatura Gabinete - Graduação", value: 8 },
      { text: "Formatura Solene - Graduação", value: 9 },
      { text: "Outras Solicitações", value: 10 },
      { text: "Transferência de Filial", value: 11 },
      { text: "Transferência de Turma e Turno", value: 12 },
      { text: "Negociação de Parcela em Atraso", value: 13 },
      { text: "Trancamento - Graduação", value: 14 },
      { text: "Trancamento - Cursos Livres", value: 15 },
      { text: "Troca de Curso", value: 16 },
      { text: "Cancelamento", value: 17 },
      { text: "Formatura Gabinete - Cursos Livres", value: 18 },
      { text: "Formatura Solene - Cursos Livres", value: 19 },
      { text: "Formatura Gabinete - Cursos Técnicos", value: 20 },
      { text: "Formatura Solene - Cursos Técnicos", value: 21 },
      { text: "Solicitação NADD", value: 22 },
      { text: "Ementa - Cursos Técnicos", value: 23 },
    ],
  }),
};
</script>
