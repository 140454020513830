<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'users']" />
              <h2 class="tituloAtendimento">
                 Departamento Pessoal
              </h2>
            </div>
          </vs-row>        

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>
            <vs-row class="pd-t">
              <vs-col>              
                <fieldset>
                  <legend>Anexo <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Anexo"
                    ref="provider"
                     
                  >
                    <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Texto Solicitação *"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>             

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>   
          </div>        
        </div>
      </ValidationObserver> 
    </div>
  </section>
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import PessoalModel from "../../../models/pessoal/pessoal.js";

export default {
  name: "DepartamentoPessoal",
  data: () => ({
    files: null,
    base64Image: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new PessoalModel().getDataByAttendanceId('P2');

      if (this.base64Image != null ) {
        payload.data.locationId = personLocalId.toString();
        payload.data.personId = personId.toString();
        payload.data.attachment = this.base64Image;
        payload.data.fileName = this.files.name;
        payload.data.attendantId = attendantId.toString();
        payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      } else {
        payload.data.locationId = personLocalId.toString();
        payload.data.personId = personId.toString();
        payload.data.attachment = "";
        payload.data.fileName = "";
        payload.data.attendantId = attendantId.toString();
        payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      }

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }          
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },
    resetForm() {
      this.solicitation = null;      
      this.visibleButton = false;
      this.files = null;
      this.base64Image = null;
      this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
