<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'user-circle']" />
          <h2 class="tituloAtendimento">
            Tipo de  Transferência / Promocão
          </h2>
        </div>
      </vs-row>

      <div>
        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Selecionar Transferência/Promoção <b style="color: red;">*</b></legend>
              <vs-select
                class="selectExample"
                v-model="typeSelected"
                placeholder="Selecione"
              >
                 <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in data.type"
                />
              </vs-select>
            </fieldset>          
          </vs-col>
        </vs-row> 
      </div>      
    </div>

    <Promocao v-if="typeSelected == 'Promoção' " />
    <TransfUnidade v-if="typeSelected == 'Transf. de Unidade'" />
    <TransFuncao v-if="typeSelected == 'Transf. de Função'" />
    <TransfHorario v-if="typeSelected == 'Transf. de Horário'" />

  </section>
</template>

<script>
import Promocao from "./transferencia-promocao/Promocao.vue"
import TransfUnidade from "./transferencia-promocao/TransfUnidade.vue"
import TransFuncao from "./transferencia-promocao/TransfFuncao.vue"
import TransfHorario from "./transferencia-promocao/TransfHorario.vue"

export default {
  name: "TransferenciaPromocao",
  components: {
    Promocao,
    TransfUnidade,
    TransFuncao,
    TransfHorario
  },
  data: () => ({
    typeSelected: null,
    data: {
        type: [
        { text: "Promoção", value: "Promoção" },
        { text: "Transf. de Unidade", value: "Transf. de Unidade" },
        { text: "Transf. de Função", value: "Transf. de Função" },
        { text: "Transf. de Horário", value: "Transf. de Horário" }
      ],
    },
  }),
};
</script>
