<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }"> 
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'check-circle']" />
            <h2 class="tituloAtendimento">
             Correção de Lançamentos Financeiros
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Assunto <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Assunto"
                  rules="required"
                   
                >
                  <vs-input v-model="subject" placeholder="Digite o Assunto" class="selectExampleDouble" />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
            
            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Referência <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Referência"
                  rules="required"
                   
                >
                  <vs-input v-model="reference" placeholder="Digite a referência" class="selectExampleDouble" />
                                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>C.C Origem <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="C.C Origem"
                  rules="required"
                   
                >
                  <vs-input v-model="origin" placeholder="Digite a C.C Origem" class="selectExampleDouble" />
                                 
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>C.C Destino <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="C.C Destino"
                  rules="required"
                   
                >
                  <vs-input v-model="destination" placeholder="Digite a C.C Destino" class="selectExampleDouble" />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>
        
          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Texto Solicitação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  rows="11"
                  label="Texto Solicitação *"
                  class="textArea"
                  height="15em"
                  counter="500"
                  v-model="solicitation"
                />
                 
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>      
        </div>                
      </div>
      </ValidationObserver>
    </div>
  </section>  
</template>

<script>
import Attendance from "../../../services/attendance";
import Units from "../../../services/units";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import AdministrativoModel from "../../../models/administrativo/administrativo";

export default {
  name: "CorrecaoLancamentosFinanceiros",

  data: () => ({
    subject: null,
    reference: null,
    origin: null,
    destination: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    error: null,
  }),
  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response)
        }
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A4');

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = this.subject;
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nREFERÊNCIA A SER CORRIGIDA: ${this.reference}\nCENTRO DE CUSTO DE ORIGEM: ${this.origin}\nCENTRO DE CUSTO DE DESTINO: ${this.destination}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.solicitation = null;
      this.subject = null;
      this.reference = null;
      this.origin = null;
      this.destination = null;
      
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
