<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'shopping-cart']" />
              <h2 class="tituloAtendimento">
               Compras
              </h2>
            </div>
          </vs-row>
          
          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione uma Filial"
                      v-model="selectedUnit"
                    >
                      <vs-select-item
                        :key="item.CODFILIAL"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="item in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
              
              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Assunto <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Assunto"
                    rules="required"
                     
                  >
                    <vs-input
                      v-model="subject"
                      class="selectExampleDouble"
                      placeholder="Digite o Assunto"
                    >
                    </vs-input>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-table">
              <vs-col>
                <fieldset>
                  <legend>Informações sobre o Rateio <b style="color: red;">*</b></legend>
                  <vs-table 
                    class="table_intranet"
                    hoverFlat
                    :data="units" 
                    v-if="units && units.length"
                  >
                    <template slot="thead">
                      <vs-th> Filiais </vs-th>
                      <vs-th> Porcentagem </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :key="indextr"
                        v-for="(tr, indextr) in data"          
                      >
                        <vs-td>
                          {{ data[indextr].POLO }}
                        </vs-td>
                        <vs-td>
                          <ValidationProvider
                            name="Porcentagem"
                             
                          >
                            <vs-input
                              placeholder="Porcentagem"
                              v-model="subjectBranch[indextr]"
                              type="number"
                              class="percentageInput"
                            />                  
                          </ValidationProvider>  
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </fieldset>
              </vs-col>              
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Texto Solicitação *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>                    
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Units from "../../../services/units";
import Attendance from "../../../services/attendance";
import AdministrativoModel from "../../../models/administrativo/administrativo";
import {
  getLoginDataFromStorage,
  poloTextFormat,
} from "../../../helpers/storage.js";

export default {
  name: "Compras",

  data: () => ({
    units: null,
    selectedUnit: null,
    subject: null,
    errorUnit: null,
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
    subjectBranch: [],
    errorInput: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A3');

      function armazenarObjeto(units, perc) {
        return [
          {
            ...units,
            perc,
          },
        ];
      }

      let unitsPerc = [];
      for (let i in this.units) {
        for (let ind in this.subjectBranch) {
          if (i === ind && this.subjectBranch[ind] != null) {
            unitsPerc.push(
              ...armazenarObjeto(this.units[i], this.subjectBranch[ind])
            );
          }
        }
      }
      function formatText(obj) {
        return obj
          .map(
            (linha) => ` ${linha.POLO}: ${linha.perc}% `
          )
          .join("\n");
      }      

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = this.subject.toUpperCase();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nSOLICITAÇÃO: ${this.solicitation}\n\n${formatText(unitsPerc)}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },
    resetForm() {
      this.solicitation = null;
      this.selectedUnit = null;
      this.subject = null;
      this.subjectBranch = [];

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 440px) {
  .percentageInput {
    width: 9em;
  }
}
</style>

