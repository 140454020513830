import { render, staticRenderFns } from "./NotaFiscal.vue?vue&type=template&id=3706fdc9&scoped=true"
import script from "./NotaFiscal.vue?vue&type=script&lang=js"
export * from "./NotaFiscal.vue?vue&type=script&lang=js"
import style0 from "./NotaFiscal.vue?vue&type=style&index=0&id=3706fdc9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3706fdc9",
  null
  
)

export default component.exports