<template>
  <div class="centerx">
    <vs-alert title="Ocorreu um Erro ao Abrir o Atendimento" color="danger" v-if="error">
      <p>Por Favor Entre em Contato com o Suporte.</p>
    </vs-alert>
    
    <vs-alert title="Atendimento Aberto com Sucesso" color="success" v-if="msg">
    <p><strong>Número do Atendimento:</strong> {{ formatedValue }} </p>
    </vs-alert>
  </div>
</template>

<script>
export default {
    name: "Message",
    props: {
        msg: String,
        error: String
    },  
    computed: {
      formatedValue: function() {
        let attendanceNumber = this.msg.split(";");
        return attendanceNumber[2];
      }
    }
 
}
</script>

<style scoped>


</style>