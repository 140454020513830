<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }" class="validator">
      <div class="subContainer">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'handshake']" />
            <h2 class="tituloAtendimento">
              Agendamento de Manutenção Preventiva - Predial
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Filial <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Filial"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExampleDouble"
                    placeholder="Selecione uma Filial"
                    v-model="selectedUnit"
                  >
                    <vs-select-item
                      :key="item.CODFILIAL"
                      :value="item.CODFILIAL"
                      :text="item.POLO"
                      v-for="item in units"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Data do Agendamento <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data"
                  rules="required"
                >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="limitDate"
                    type="date"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t" v-if="limitDate <= dateActual">
            <fieldset style="width: 100%">
              <p>
                <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
                Data do agendamento precisa ser maior que hoje
              </p>
            </fieldset>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid || limitDate <= dateActual"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
        </div>
      </div>
    </ValidationObserver>
  </section>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
import {
  getLoginDataFromStorage,
  convertDate,
  poloTextFormat,
} from "../../../../helpers/storage.js";
export default {
  name: "AgendaInfra",

  data: () => ({
    units: null,
    dateActual: null,
    selectedUnit: null,
    limitDate: null,
    errorUnit: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response);
        }
      });

    this.dateActual = (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1)) + '-' + (new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()));
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId("A14-1");

      payload.data.locationId = this.selectedUnit;
      payload.data.deadline = this.limitDate;
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL: ${poloTextFormat(
        this.selectedUnit,
        this.units
      )}\nDATA DO AGENDAMENTO: ${convertDate(this.limitDate)}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.subject = "";
      this.selectedUnit = "";
      this.solicitation = "";
      (this.maintenanceSelected = ""),
        (this.limitDate = ""),
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
    },
  },
};
</script>

