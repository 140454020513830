<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'users']" />
              <h2 class="tituloAtendimento">
                 Produção de Materiais
              </h2>
            </div>
          </vs-row>        

          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>
            <vs-col vs-lg="12">
              <fieldset>
                <legend>Nome da disciplina <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nome da Disciplina"
                  rules="required"
                   
                >
                  <vs-input type="text" v-model="nameSubmit"/>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>   

            <vs-col class="pd-c" vs-lg="12" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Data <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data Início"
                  rules="required"
                   
                >
                  <vs-input type="date" v-model="startDate" class="selectExampleDouble"/>
                  <p id="aviso"> * Data limite para entrega do material</p>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>   
            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Texto Solicitação *"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>             

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>  
          </div>        
        </div>
      </ValidationObserver> 
    </div>
  </section>
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage, convertDate } from "../../../helpers/storage.js";
import EducacionalModel from "../../../models/educacional/educacional.js";



export default {
  name: "ProducaoDeMateriais",
  data: () => ({
    solicitation: null,
    nameSubmit:null,
    startDate:null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  methods: {

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();
      

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E7");
      
      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = "";
      payload.data.fileName = "";
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME DA DISCIPLINA: ${this.nameSubmit}\nDATA LIMITE:  ${convertDate(this.startDate)}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },
    resetForm() {
      this.solicitation = null;      
      this.visibleButton = false;
      this.nameSubmit = null;
      this.startDate = null;


      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
  fieldset {
    margin: 1%;
    border-width: 1px;
    border-color: rgb(44, 44, 44);
    border-style: solid;
    border-radius: 4px;
  }
  #aviso{
    margin-top: 4px;
    font-size: small;
    font-weight: bold;
    font-style: italic;
    color: rgb(44, 44, 44);

  }

</style>
