<template>
  <div class="subContainer">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'retweet']" />
          <h2 class="tituloAtendimento">Substituição de Colaborador</h2>
        </div>
      </vs-row>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"
            />
          </vs-col>
        </vs-row>
        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Nome Colaborador <b style="color: red">*</b></legend>
              <ValidationProvider name="Nome Colaborador" rules="required">
                <vs-select
                  autocomplete
                  @input-change="inputChange"
                  class="selectExample"
                  placeholder="Digite o Nome do Colaborador"
                  v-model="indexSelectedSub"
                >
                  <vs-select-item
                    :key="index"
                    :value="index"
                    :text="item.NOME"
                    v-for="(item, index) in data.employees"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>
      </div>

      <div class="divSelect" v-if="indexSelectedSub != null">
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Função <b style="color: red">*</b></legend>
              <vs-input
                disabled
                v-model="data.employees[indexSelectedSub].FUNCAO"
                class="selectExampleDouble"
              />
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Horário <b style="color: red">*</b></legend>
              <vs-input
                disabled
                v-model="data.employees[indexSelectedSub].HORARIO"
                class="selectExampleDouble"
              />
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Escola destino <b style="color: red">*</b></legend>
              <vs-input
                disabled
                v-model="data.employees[indexSelectedSub].FILIAL"
                class="selectExampleDouble"
              />
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Vaga <b style="color: red">*</b></legend>
              <vs-input
                disabled
                v-model="data.employees[indexSelectedSub].FUNCAO"
                class="selectExampleDouble"
              />
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Nome Indicação <b style="color: red">*</b></legend>
              <ValidationProvider name="Nome indicação" rules="required">
                <vs-input
                  v-model="nameIndication"
                  placeholder="Digite o Nome da Indicação"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Telefone Indicação <b style="color: red">*</b></legend>
              <ValidationProvider
                name="Telefone indicação"
                rules="required|min:15"
              >
                <vs-input
                  v-model="phoneIndication"
                  placeholder="Digite o Telefone da Indicação"
                  v-mask="'(##) #####-####'"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Tipo Contratação <b style="color: red">*</b></legend>
              <ValidationProvider name="Tipo contratação" rules="required">
                <vs-select
                  class="selectExampleDouble"
                  placeholder="Selecione o Tipo de Contratação"
                  v-model="indexSelectedHiring"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.typeHiring"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Data <b style="color: red">*</b></legend>
              <ValidationProvider name="Data" rules="required">
                <vs-input
                  type="date"
                  v-model="startDate"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider
              name="Texto Solicitação"
              rules="required|max: 500"
            >
              <vs-textarea
                label="Descrição da Vaga *"
                height="210px"
                rows="11"
                class="textArea"
                counter="500"
                v-model="solicitation"
              />
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>

            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Person from "../../../../services/person";
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import PessoalModel from "../../../../models/pessoal/pessoal.js";
import {
  getLoginDataFromStorage,
  convertDate,
} from "../../../../helpers/storage";

export default {
  name: "SubstituicaoColaborador",
  data: () => ({
    typeSelected: null,
    indexSelectedSub: null,
    data: {
      employees: [],
      errorEmployees: null,
      units: null,
      null: null,
      typeHiring: [
        { text: "CLT", value: "CLT" },
        { text: "Estágio", value: "Estágio" },
        { text: "RPA", value: "RPA" },
      ],
    },
    week: {
      weekArray: [],
      weekend: [
        { text: "Não trabalha Sábados", value: "Não trabalha Sábados" },
        { text: "Todos os Sábados", value: "Todos os Sabados" },
        { text: "Sábados alternados", value: "Sábados alternados" },
      ],
    },
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
    ],
    selectedUnit: null,
    indexSelectedHiring: null,
    indexSelectedWeekend: null,
    nameIndication: null,
    phoneIndication: null,
    startDate: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Person()
      .getEmployees()
      .then(({ data }) => {
        this.data.employees = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.errorEmployees = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.data.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.null = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    inputChange(event) {
      return event.target.value;
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;

      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload1 = new PessoalModel().getDataByAttendanceId("P1-3");

      payload1.data.locationId = personLocalId.toString();
      payload1.data.personId = personId.toString();
      payload1.data.attendantId = attendantId.toString();
      payload1.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Substituição de colaborador : ${
        this.data.employees[this.indexSelectedSub].CHAPA
      } - ${this.data.employees[this.indexSelectedSub].NOME}\nPARA ESCOLA: ${
        this.data.employees[this.indexSelectedSub].FILIAL
      }\nINDICAÇÃO: (Nome: ${this.nameIndication.toUpperCase()} || Contato: ${
        this.phoneIndication
      })\nCONTRATAÇÃO: ${
        this.indexSelectedHiring
      }\nDATA DE INÍCIO: ${convertDate(this.startDate)}\nAREA DE ATUAÇÃO: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nDESCRIÇÃO DA VAGA: ${this.solicitation.toUpperCase()}`;

      const payload2 = new PessoalModel().getDataByAttendanceId("P1-4");

      payload2.data.locationId = personLocalId.toString();
      payload2.data.personId = personId.toString();
      payload2.data.attendantId = attendantId.toString();
      payload2.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Substituição de colaborador : ${
        this.data.employees[this.indexSelectedSub].CHAPA
      } - ${this.data.employees[this.indexSelectedSub].NOME}\nPARA ESCOLA: ${
        this.data.employees[this.indexSelectedSub].FILIAL
      }\nINDICAÇÃO: (Nome: ${this.nameIndication.toUpperCase()} || Contato: ${
        this.phoneIndication
      })\nCONTRATAÇÃO: ${
        this.indexSelectedHiring
      }\nDATA DE INÍCIO: ${convertDate(this.startDate)}\nAREA DE ATUAÇÃO: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nDESCRIÇÃO DA VAGA: ${this.solicitation.toUpperCase()}`;

      let vaga = this.data.employees[this.indexSelectedSub].FUNCAO.split(" ");
      let payloads =
        vaga[0] == "PROFESSOR(A)" ||
        vaga[0] == "PROF." ||
        vaga[0] == "INSTRUTOR" ||
        vaga[0] == "TUTOR" ||
        vaga[0] == "TUTOR(A)"
          ? payload1.data
          : payload2.data;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payloads)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },
    resetForm() {
      this.solicitation = null;
      this.nameIndication = null;
      this.phoneIndication = null;
      this.indexSelectedHiring = "";
      this.startDate = null;
      this.indexSelectedSalary = "";
      this.salary = null;
      this.currencyMask = null;
      this.time = [];
      this.shift = [];
      this.week.weekArray = [];
      this.indexSelectedWeekend = null;
      this.timeSaturday = [];
      this.indexSelectedSub = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped>
.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 580px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .boxTurn {
    flex-direction: column;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 440px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
