<template>
  <section>
    <div>
      <vs-row class="pd-table">
        <vs-col>
          <fieldset v-if="this.codAtendente == '0' || this.callsInLine == ''">
            <p>
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              Nenhum atendimento encontrado
            </p>
          </fieldset>
          <vs-table
            v-else
            :data="callsInLine"
            hoverFlat
            search
            pagination
            max-items="10"
            class="table_intranet"
          >
            <template slot="thead">
              <vs-th> ATENDIMENTO </vs-th>
              <vs-th> DATA ABERTURA </vs-th>
              <vs-th> ASSUNTO </vs-th>
              <vs-th> CLASSIFICAÇÃO </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-popup 
                style="z-index: 999999 !important" 
                :title="data[idPopup] ? 'Atendimento - ' + data[idPopup].CODATENDIMENTO : 'Atendimento'"  
                :active.sync="popupAtivo"
              >
                <vs-col>
                  <vs-tabs alignment="center">
                    <vs-tab icon="library_add" label="Solicitação" @click="type='solicitation'"/>
                    <vs-tab icon="group" label="Discussão" @click="type='discussion'"/>
                    <vs-tab icon="check_circle" label="Solução" @click="type='solution'"/>       
                  </vs-tabs>        
                </vs-col>
                <fieldset v-if="type == 'solicitation'">
                  <p
                    v-html="
                      data[idPopup] ? 
                      parseNewLine(data[idPopup].SOLICITACAO) : 
                      ''
                    "
                  ></p>
                </fieldset>
                <fieldset v-if="type == 'discussion'">
                  <p
                    v-html="
                      data[idPopup] ? 
                      parseNewLine(data[idPopup].DISCUSSAO) : 
                      ''
                    "
                  ></p>
                </fieldset>
                <fieldset v-if="type == 'solution'">
                  <p
                    v-html="
                      data[idPopup] ? 
                      parseNewLine(data[idPopup].SOLUCAO) : 
                      ''
                    "
                  ></p>
                </fieldset>
              </vs-popup>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <a href="#" @click="handleClickDetails(indextr)">{{
                    data[indextr].CODATENDIMENTO
                  }}</a>
                </vs-td>

                <vs-td>
                  {{ data[indextr].DATA_ABERTURA }}
                </vs-td>

                <vs-td>
                  {{ data[indextr].ASSUNTO }}
                </vs-td>

                <vs-td class="classificacao">
                  {{ data[indextr].CLASSIFICACAO }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </div>
  </section>
</template>
<script>
import Management from "../../services/management";
import {
  getLoginDataFromStorage,
  parseNewLine,
} from "../../helpers/storage.js";

export default {
  name: "AtendAbertosPorMim",
  data: () => ({
    type: 'solicitation',
    codAtendente: null,
    errorQueue: null,
    callsInLine: [],
    errorCallsInLine: null,
    popupAtivo: false,
    idPopup: null,
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    const user1 = user.userData;
    const { CODATENDENTE } = user1;

    this.codAtendente = CODATENDENTE;

    this.$vs.loading({ text: "Carregando..." });
    new Management()
      .getCallsInLine(CODATENDENTE)
      .then(({ data }) => {
        this.callsInLine = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorQueue = response.data;
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    handleClickDetails(indextr) {
      this.idPopup = indextr;
      setTimeout(() => {
        this.popupAtivo = true;
      }, 100);
    },

    parseNewLine,
  },
};
</script>

<style scoped>
.divSelect {
  padding: 1em;
}

.tituloAtendimento {
  padding-left: 0.2em;
  margin-top: 5px;
}

.selectExample,
.selectExampleRA,
.selectExampleDouble {
  width: 25em;
  margin-left: 1em;
}

.erro {
  color: red;
  margin: 0.8em;
}

.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 1180px) {
  .selectExampleDouble {
    width: 17em;
  }
}

@media screen and (max-width: 900px) {
  .selectExampleDouble {
    width: 25em;
  }

  .pd-c {
    padding-top: 1em;
  }
}

@media screen and (max-width: 800px) {
  .selectExampleRA {
    width: 17em;
  }
}

@media screen and (max-width: 650px) {
  .selectExampleRA {
    width: 13em;
    margin-left: 0em;
  }

  .selectExample,
  .selectExampleDouble {
    width: 15em;
  }

  .input-horario {
    width: 5em;
  }
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .selectExample,
  .selectExampleRA,
  .selectExampleDouble {
    width: 11em;
  }

  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }

  .horario > span {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 440px) {
  .percentageInput {
    width: 9em;
  }
}

@media screen and (max-width: 400px) {
  .selectExampleRA {
    width: 9em;
  }

  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
