import { https } from './api';

export default class {
    constructor() {
        this.instance = https('students');
    }

    getEADCoursesByRA = (RA) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/coursesEAD`
        })
    }

    getCoursesByRA = (RA) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/courses`
        })
    }

    getCourseEmenta = (RA) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/ementa`
        })
    }

    //getClassByCourseCode
    getClassByCourseId = (RA, courseId) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/${courseId}/classes`
        })
    }

    getSubjectByRAAndClassCode = (RA, codTurma) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/${codTurma}/subjects`
        })
    }

    //getCancellationByRA
    getCancelDataByRA = (RA) => {
        return this.instance({
            method: 'GET',
            url: `/${RA}/cancelData`
        })
    }
}