<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'chalkboard-teacher']" />
            <h2 class="tituloAtendimento">Docentes</h2>
          </div>
        </vs-row>

        <div class="con-select-example">
          <vs-row>
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetQuery()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Limpar Consulta
              </vs-button>
              
              <vs-button
                color="success"
                type="gradient"
                class="button-protocol"
                @click="popupAvailableTeachers = true"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Consultar Professores Disponíveis Por Data
              </vs-button>
            </vs-col>
          </vs-row>

          <vs-popup
            title="Consultar Professores Por Data"
            :active.sync="popupAvailableTeachers"
          >
            <ValidationObserver ref="observer2" v-slot="{ invalid }">
              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Turno <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Turno"
                      rules="required"
                    >
                      <vs-select
                        class="inputPopup"
                        placeholder="Selecione o Turno"
                        v-model="shift"
                      >
                        <vs-select-item
                          :key="index"
                          :value="item.value"
                          :text="item.text"
                          v-for="(item, index) in selectedShift"
                        />
                      </vs-select>
                       
                    </ValidationProvider>
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Data <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Data"
                      rules="required"
                    >
                      <vs-input type="date" v-model="date" class="inputPopup" />
                       
                    </ValidationProvider>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center;">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetFormPopup()"
                  >
                    <font-awesome-icon :icon="['fas', 'times-circle']" />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="getFreeTeacherByDateAndShift()"
                    :disabled="invalid"
                  >
                    <font-awesome-icon :icon="['fas', 'check']" />
                    Consultar
                  </vs-button>
                </vs-col>
              </vs-row>
            </ValidationObserver>
          </vs-popup>

          <vs-row class="pd-table" v-if="tableData">
            <vs-col>
              <vs-table
                class="table_intranet"
                max-items="20"
                hoverFlat
                pagination
                search
                :data="tableData"
              >
                <template slot="thead">
                  <vs-th class="table_th"> Nome </vs-th>
                  <vs-th class="table_th"> Cidade </vs-th>
                  <vs-th class="table_th"> Seção </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    class="table_body"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td class="nome">
                      <a
                        class="nomes"
                        href="#"
                        @click="handleClickDetails(tr)"
                        color="primary"
                        type="border"
                        >{{ data[indextr].NOME }}</a
                      >
                    </vs-td>
                    <vs-td>
                      {{ data[indextr].CIDADE }}
                    </vs-td>
                    <vs-td>
                      {{ data[indextr].SECAO }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
          </vs-row>

          <vs-popup
            style="z-index: 999999 !important" fullscreen title="Detalhes" :active.sync="popupAtivo">
            <vs-tabs>
              <vs-tab label="Dados">
                <div>
                  <vs-card>
                    <div slot="header">
                      <p>Professor: {{ nameTeacher }} ({{ idTeacher }})</p>
                    </div>
                    <p>Função: {{ occupationTeacher }}</p>
                    <p>
                      Telefone (s): {{ phoneTeacher1 }} / {{ phoneTeacher2 }}
                    </p>
                    <p>E-mail: {{ emailTeacher }}</p>
                  </vs-card>

                  <vs-card>
                    <div v-if="workload == null">
                      <vs-row>
                        <vs-col>
                          <p>Carga Horária Não Cadastrada</p>
                        </vs-col>
                      </vs-row>
                    </div>

                    <div v-else>
                      <vs-row vs-w="12" class="pd-table">
                        <vs-col>
                          <fieldset>
                            <legend>Carga Horária <b style="color: red;">*</b></legend>
                            <vs-table 
                              class="table_intranet"
                              hoverFlat
                              :data="workload"
                            >
                              <template slot="thead">
                                <vs-th> Dia da Semana </vs-th>
                                <vs-th> Entrada 1 </vs-th>
                                <vs-th> Saída 1 </vs-th>
                                <vs-th> Entrada 2 </vs-th>
                                <vs-th> Saída 2 </vs-th>
                                <vs-th> Entrada 3 </vs-th>
                                <vs-th> Saída 3 </vs-th>
                                <vs-th> Compensado </vs-th>
                                <vs-th> DSR </vs-th>
                              </template>
                              <template slot-scope="{ data }">
                                <vs-tr
                                  :key="indextr"
                                  v-for="(tr, indextr) in data"
                                >
                                  <vs-td>
                                    {{ data[indextr].DIASSEMANA }}
                                  </vs-td>
                                  <vs-td> {{ data[indextr].ENTRADA1 }} </vs-td>
                                  <vs-td> {{ data[indextr].SAIDA1 }} </vs-td>
                                  <vs-td> {{ data[indextr].ENTRADA2 }} </vs-td>
                                  <vs-td> {{ data[indextr].SAIDA2 }} </vs-td>
                                  <vs-td> {{ data[indextr].ENTRADA3 }} </vs-td>
                                  <vs-td> {{ data[indextr].SAIDA3 }} </vs-td>
                                  <vs-td>
                                    {{ data[indextr].COMPENSADO }}
                                  </vs-td>
                                  <vs-td> {{ data[indextr].DSR }} </vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                          </fieldset>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-card>
                </div>
              </vs-tab>

              <vs-tab label="Formação Acadêmica">
                <div>
                  <vs-card>
                    <div v-if="academicEducationByTeacher == ''">
                      <vs-row>
                        <vs-col>
                          <p>Formação Acadêmica Não Cadastrada</p>
                        </vs-col>
                      </vs-row>
                    </div>

                    <div v-else>
                      <vs-card
                        v-for="(i, index) in academicEducationByTeacher"
                        :key="index"
                      >
                        <div slot="header">
                          <p>Curso: {{ i.NOMECURSO }}</p>
                        </div>
                        <p>Nivel de Ensino: {{ i.NIVEL }}</p>
                        <p>Instituição: {{ i.NOMEINSTITUICAO }}</p>
                        <p>
                          Ano de Conclusão:
                          {{
                            i.ANOCONCLUSAO == null
                              ? "Em Andamento"
                              : i.ANOCONCLUSAO
                          }}
                        </p>
                      </vs-card>
                    </div>
                  </vs-card>
                </div>
              </vs-tab>

              <vs-tab label="Disponibilidade">
                <div>
                  <vs-card>
                    <div v-if="avaliabilityByTeacher == ''">
                      <vs-row>
                        <vs-col>
                          <p>Sem disponibilidade cadastrada.</p>
                        </vs-col>
                      </vs-row>
                    </div>

                    <div v-else>
                      <vs-row vs-w="12" class="pd-table">
                        <vs-col>
                          <vs-table
                            class="table_intranet"
                            max-items="20"
                            hoverFlat
                            pagination
                            search
                            :data="avaliabilityByTeacher"
                          >
                            <template slot="thead">
                              <vs-th> Filial </vs-th>
                              <vs-th> Dia Disponível </vs-th>
                              <vs-th> Turno </vs-th>
                            </template>
                            <template slot-scope="{ data }">
                              <vs-tr
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                              >
                                <vs-td> {{ data[indextr].FILIAL }} </vs-td>
                                <vs-td>
                                  {{ data[indextr].DIADISPONÍVEL }}
                                </vs-td>
                                <vs-td> {{ data[indextr].TURNO }} </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-card>
                </div>
              </vs-tab>

              <vs-tab label="Disciplinas">
                <div>
                  <vs-card>
                    <div v-if="disciplineByTeacher == ''">
                      <vs-row>
                        <vs-col>
                          <p>Professor Sem Disciplinas cadastradas</p>
                        </vs-col>
                      </vs-row>
                    </div>

                    <div v-else>
                      <vs-table
                        class="table_intranet"
                        max-items="20"
                        hoverFlat
                        pagination
                        search
                        :data="disciplineByTeacher"
                      >
                        <template slot="thead">
                          <vs-th> # </vs-th>
                          <vs-th> Disciplina </vs-th>
                          <vs-th> Carga Horária </vs-th>
                        </template>
                        <template slot-scope="{ data }">
                          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td> {{ data[indextr].CODDISC }} </vs-td>
                            <vs-td> {{ data[indextr].NOME }} </vs-td>
                            <vs-td> {{ data[indextr].CH }} </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </vs-card>
                </div>
              </vs-tab>

              <vs-tab label="Alocação Por Período">
                <div>
                  <vs-card>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <vs-row class="pd-t">
                        <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                          <fieldset>
                            <legend>Data Inicial <b style="color: red;">*</b></legend>
                            <ValidationProvider
                              name="Data Inicial"
                              rules="required"
                            >
                              <vs-input
                                type="date"
                                v-model="startDate"
                                class="selectExampleDouble"
                              />
                            </ValidationProvider>
                          </fieldset>
                        </vs-col>

                        <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                          <fieldset>
                            <legend>Data Final <b style="color: red;">*</b></legend>
                            <ValidationProvider
                              name="Data Final"
                              rules="required"
                            >
                              <vs-input
                                type="date"
                                v-model="finalDate"
                                class="selectExampleDouble"
                              />
                            </ValidationProvider>
                          </fieldset>
                        </vs-col>
                      </vs-row>

                      <div v-if="teacherAllocationByPeriod != null">
                        <vs-row
                          class="pd-t"
                          v-if="teacherAllocationByPeriod == ''"
                        >
                          <vs-col>
                            <fieldset>
                              <p>
                                Não foi localizada alocação no sistema para o
                                professor selecionado e as datas informadas.
                              </p>
                            </fieldset>
                          </vs-col>
                        </vs-row>

                        <div v-else>
                          <vs-row vs-w="12" class="pd-table">
                            <vs-col>
                              <fieldset>
                                <vs-table
                                  class="table_intranet"
                                  max-items="20"
                                  hoverFlat
                                  pagination
                                  search
                                  :data="teacherAllocationByPeriod"
                                >
                                  <template slot="thead">
                                    <vs-th class="table_th"> Curso </vs-th>
                                    <vs-th class="table_th"> Data Aula </vs-th>
                                    <vs-th class="table_th">
                                      Dia da Semana
                                    </vs-th>
                                    <vs-th class="table_th"> Disciplina </vs-th>
                                    <vs-th class="table_th"> Horário </vs-th>
                                    <vs-th class="table_th"> Turno </vs-th>
                                  </template>

                                  <template slot-scope="{ data }">
                                    <vs-tr
                                      class="table_body"
                                      :data="tr"
                                      :key="indextr"
                                      v-for="(tr, indextr) in data"
                                    >
                                      <vs-td>
                                        {{ data[indextr].CURSO }}
                                      </vs-td>
                                      <vs-td>
                                        {{ data[indextr].DATAAULA }}
                                      </vs-td>
                                      <vs-td>
                                        {{ data[indextr].DIADASEMANA }}
                                      </vs-td>
                                      <vs-td>
                                        {{ data[indextr].DISCIPLINA }}
                                      </vs-td>
                                      <vs-td>
                                        {{ data[indextr].HORARIO }}
                                      </vs-td>
                                      <vs-td>
                                        {{ data[indextr].TURNO }}
                                      </vs-td>
                                    </vs-tr>
                                  </template>
                                </vs-table>
                              </fieldset>
                            </vs-col>
                          </vs-row>
                        </div>
                      </div>

                      <vs-row class="pd-t">
                        <vs-col style="text-align: center;">
                          <vs-button
                            color="danger"
                            type="gradient"
                            class="button-protocol"
                            @click="resetForm()"
                          >
                            <font-awesome-icon :icon="['fas', 'times-circle']" />
                            Cancelar
                          </vs-button>

                          <vs-button
                            class="button-protocol"
                            color="success"
                            type="gradient"
                            @click="getTeacherAllocationByPeriod()"
                            :disabled="invalid"
                          >
                            <font-awesome-icon :icon="['fas', 'check']" />
                            Consultar</vs-button
                          >
                        </vs-col>
                      </vs-row>
                    </ValidationObserver>
                  </vs-card>
                </div>
              </vs-tab>
            </vs-tabs>
          </vs-popup>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Teachers from "../../services/teachers";

export default {
  name: "Docentes",
  data: () => ({
    activeTeachers: null,
    errorActiveTeachers: null,
    workload: null,
    errorWorkload: null,
    academicEducationByTeacher: null,
    errorAcademicEducationByTeacher: null,
    avaliabilityByTeacher: null,
    errorAvaliabilityByTeacher: null,
    disciplineByTeacher: null,
    errorDisciplineByTeacher: null,
    teacherAllocationByPeriod: null,
    errorTeacherAllocationByPeriod: null,
    popupAtivo: false,
    nameTeacher: null,
    phoneTeacher1: null,
    phoneTeacher2: null,
    emailTeacher: null,
    occupationTeacher: null,
    idTeacher: null,
    idTime: null,
    startDate: null,
    finalDate: null,
    popupAvailableTeachers: false,
    date: null,
    shift: null,
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
      { text: "Integral", value: "Integral" },
    ],
    availableTeachers: null,
    errorAvailableTeachers: null,
    tableData: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
      .getActiveTeachers()
      .then(({ data }) => {
        this.activeTeachers = Array.isArray(data) ? data : [data];
        this.tableData = this.activeTeachers;
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorActiveTeachers = response.data;
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    getFreeTeacherByDateAndShift() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getFreeTeacherByDateAndShift(this.date, this.shift)
        .then(({ data }) => {
          this.availableTeachers = Array.isArray(data) ? data : [data];
          this.tableData = this.availableTeachers;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorAvailableTeachers = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.popupAvailableTeachers = false;
          this.resetFormPopup();
        });
    },

    resetFormPopup() {
      this.date = null;
      this.shift = null;

      this.$nextTick(() => {
        this.$refs.observer2.reset();
      });
    },

    resetQuery() {
      this.$vs.loading({ text: "Carregando..." });
      setTimeout(() => {
        this.resetFormPopup();
        this.tableData = this.activeTeachers;
        this.$vs.loading.close();
      }, 800);
    },

    handleClickDetails(tr) {
      this.popupAtivo = true;

      this.nameTeacher = tr.NOME;
      this.phoneTeacher1 = tr.TELEFONE1;
      this.phoneTeacher2 = tr.TELEFONE2 == null ? "-" : tr.TELEFONE2;
      this.emailTeacher = tr.EMAIL;
      this.occupationTeacher = tr.FUNCAO;
      this.idTeacher = tr.CODPROF;
      this.idTime = tr.CODHORARIO;

      this.getWorkloadByTeacher();
      this.getAcademicEducationByTeacher();
      this.getAvaliabilityByTeacher();
      this.getDisciplineByTeacher();
      this.teacherAllocationByPeriod = null;
      this.startDate = null;
      this.finalDate = null;
    },

    getWorkloadByTeacher() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getWorkloadByTeacher(this.idTime)
        .then(({ data }) => {
          this.workload = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorWorkload = response.data;
          }
          this.$vs.loading.close();
        });
    },

    getAcademicEducationByTeacher() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getAcademicEducationByTeacher(this.idTeacher)
        .then(({ data }) => {
          this.academicEducationByTeacher = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorAcademicEducationByTeacher = response.data;
          }
          this.$vs.loading.close();
        });
    },

    getAvaliabilityByTeacher() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getAvaliabilityByTeacher(this.idTeacher)
        .then(({ data }) => {
          this.avaliabilityByTeacher = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorAvaliabilityByTeacher = response.data;
          }
          this.$vs.loading.close();
        });
    },

    getDisciplineByTeacher() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getDisciplineByTeacher(this.idTeacher)
        .then(({ data }) => {
          this.disciplineByTeacher = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorDisciplineByTeacher = response.data;
          }
          this.$vs.loading.close();
        });
    },

    getTeacherAllocationByPeriod() {
      this.$vs.loading({ text: "Carregando..." });
      new Teachers()
        .getTeacherAllocationByPeriod(
          this.idTeacher,
          this.startDate,
          this.finalDate
        )
        .then(({ data }) => {
          this.teacherAllocationByPeriod = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorTeacherAllocationByPeriod = response.data;
          }
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.startDate = null;
      this.finalDate = null;
      this.teacherAllocationByPeriod = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.inputPopup {
  width: 15em;
}
.alingButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
legend {
  font-size: 1.25em;
}

.table_th {
  font-size: 1.2em;
}

.table_body {
  font-size: 1.1em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

.selectExampleDouble {
  width: 25em;
  margin-left: 1em;
}

@media screen and (max-width: 900px) {
  .selectExampleDouble {
    width: 35em;
  }

  .pd-c {
    padding-top: 1em;
  }
}

@media screen and (max-width: 650px) {
  .selectExampleDouble {
    width: 20em;
  }
}

@media screen and (max-width: 420px) {
  .selectExampleDouble {
    width: 14.5em;
    margin-left: 0;
  }
}

@media screen and (max-width: 330px) {
  .selectExampleDouble {
    width: 12.5em;
  }
}
</style>
