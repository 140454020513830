<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'house-user']" />
            <h2 class="tituloAtendimento">Agenda de Manutenções</h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="schedule"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Unidade QI </vs-th>
                    <vs-th class="table_th"> Data Agenda </vs-th>
                    <vs-th class="table_th"> Tipo</vs-th>
                    <vs-th class="table_th"> Responsável Atual</vs-th>
                    <vs-th class="table_th"> Etapa Atual </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        {{ data[indextr].CODLOCAL }}
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].PRAZOLIMITE }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].ASSUNTOOC }} <br />
                        At. {{ data[indextr].CODATENDIMENTO }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].RESPONSÁVEL }}
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].TAREFA }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </section>
</template>
    
    <script>
import Management from "../../services/management";
export default {
  name: "AgendaManutencoes",
  data: () => ({
    schedule: [],
    error: null,
    popupAtivo: false,
    scheduleSelected: null,
    scheduleData: [],
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Management()
      .getMaintenanceSchedule()
      .then(({ data }) => {
        this.schedule = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
  methods: {},
};
</script>
    
    <style scoped>
h3 {
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}
</style>
    