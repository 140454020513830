<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
    <div class="subContainer">
      <vs-row class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'suitcase-rolling']" />
          <h2 class="tituloAtendimento">
             Viagens e Deslocamentos
          </h2>
        </div>
      </vs-row>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>N°. de pessoas <b style="color: red;">*</b></legend>
              <ValidationProvider 
                name="N°. de pessoas"
                rules="required|numeric"
                 
              >
                <vs-input
                  v-model="numberPeople"
                  placeholder="Digite o N°. de pessoas *"
                  class="selectExampleDouble"
                />          
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Vlr. Total da Hospedagem <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Vlr. Total da Hospedagem"
                rules="required"
                 
              >
                <vs-input
                  v-model="hostingValue"
                  placeholder="Digite o Vlr. Total da Hospedagem *"
                  class="selectExampleDouble"
                  type= "number"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Vlr. Total da Passagem <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Vlr. Total da Passagem"
                rules="required"
                 
              >
                <vs-input
                  v-model="ticketPrice"
                  placeholder="Digite Vlr. Total da Passagem"
                  class="selectExampleDouble"
                  type= "number"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Vlr. Total da Alimentação <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Vlr. Total da Alimentação"
                rules="required"
                 
              >
                <vs-input
                  v-model="foodValue"
                  placeholder="Digite o Vlr. Total da Alimentação *"
                  class="selectExampleDouble"
                  type= "number"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Data de Saída <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Data de saida"
                rules="required"
                 
              >
                  <vs-input
                    v-model="departureDate"
                    type="date"
                    class="selectExampleDouble"
                  />
                   
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Termino da Ação <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Termino da Ação"
                rules="required"
                 
              >
                <vs-input
                  v-model="endDate"
                  type="date"
                  class="selectExampleDouble"
                />
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Destino <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Destino"
                rules="required"
                 
              >              
                <vs-input v-model="destination" placeholder="Digite o Destino" class="selectExample"/>
                 
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider
              name="Texto Solicitação"
              rules="required|max: 500"
               
            >
              <vs-textarea
                rows="11"
                label="Texto Solicitação *"
                class="textArea"
                height="15em"
                counter="500"
                v-model="solicitation"
              />
               
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center;">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>
            
            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>            
        </vs-row>
      </div>                
    </div>
    </ValidationObserver>
  </section>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
import {
  getLoginDataFromStorage, convertDate
} from "../../../../helpers/storage.js";


export default {
  name: "ViagensEDeslocamentos",
 
  data: () => ({
    numberPeople: null,
    hostingValue: null,
    ticketPrice: null,
    foodValue: null,
    solicitation: null,
    departureDate: null,
    endDate: null,
    idAttendance: null,
    errorIdAttendance: null,
    destination: null,
  }),
  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response)
        }
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A10-4');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.subject = "NUMERÁRIOS - VIAGENS E DESLOCAMENTOS";
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNÚMERO DE PESSOAS: ${this.numberPeople}\nVALOR TOTAL DA HOSPEDAGEM: ${this.hostingValue}\nVALOR TOTAL DA PASSAGEM: ${this.ticketPrice}\nVALOR TOTAL DA ALIMENTAÇÃO: ${this.foodValue}\nDATA SAIDA: ${convertDate(this.departureDate)}\nTERMINO DA AÇÃO: ${convertDate(this.endDate)}\nDESTINO: ${this.destination}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.solicitation = null;
      this.numberPeople = null;
      this.hostingValue = null;
      this.ticketPrice = null;
      this.foodValue = null;
      this.departureDate = null;
      this.endDate = null;
      this.destination = null;
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
