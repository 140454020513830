<template>
    <section>                     
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div class="subContainer">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'upload']" />
              <h2 class="tituloAtendimento">
                Criação de Atendimento/Protocolo
              </h2>
            </div>
          </vs-row>
  
          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t"
                  :msg="idAttendance"
                  :error="errorIdAttendance"
                  v-if="idAttendance || errorIdAttendance"          
                />
              </vs-col>
            </vs-row>
          </div>
  
          <div>
            <vs-row class="pd-t">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>Nome do Atendimento/Protocolo <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Nome do protocolo"
                    rules="required"
                  >                
                    <vs-input 
                    v-model="nameAtendanceProtocol" 
                    placeholder="Nome do Protocolo"
                    class="selectExampleDouble"
                    /> 
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Destino <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Destino"
                    rules="required"
                  >
                    <vs-select
                      class="selectExampleDouble"
                      v-model="selectedDestination"
                      placeholder="Selecione o Destino da Notícia"
                    >
                      <vs-select-item
                        :key="item.destinationNew"
                        :value="item.value"
                        :text="item.text"
                        v-for="item in destinationNew"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
              
              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Fila Inicial <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Fila Inicial"
                    rules="required"
                  >                
                    <vs-input 
                    v-model="initialQueue" 
                    placeholder="Fila Inicial"
                    type="number"
                    class="selectExampleDouble"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
          </vs-row>

            <vs-row class="pd-t" v-if="selectedDestination == 'Portal do Aluno'">
              <vs-col class="pd-c">
                <fieldset>
                  <legend>Modalidade de Curso <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Modalidade de Curso"
                    rules="required"
                  >
                    <div class="boxTurn">
                      <span v-for="(course, index) in selectedCourse" :key="index">
                        <input
                          type="checkbox"
                          :id="index"
                          class="checkboxTurn"
                          v-model="courseModality"
                          :value="course.value"
                        />
                        <label :for="index">{{ course.text }}</label>
                      </span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Campos Necessários"
                  rules="required|max: 500"
                >
                  <vs-textarea
                    rows="11"
                    label="Campos Necessários *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="requiredFields"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Descrição do Fluxo"
                  rules="required|max: 500"
                >
                  <vs-textarea
                    rows="11"
                    label="Descrição do Fluxo *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="describeFlow"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                    name="Descrição do Protocolo"
                    rules="required|max: 500"
                >
                  <vs-textarea
                    rows="11"
                    label="Descrição do Protocolo *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="describeProtocol"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>                                                    
  
            <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </section>
</template>

<script>
import Attendance from "../../../../services/attendance";
import { getLoginDataFromStorage} from "../../../../helpers/storage.js";
import AdministrativoModel from "../../../../models/administrativo/administrativo";

export default {
  name: "CriacaoProtocolo",
  data: () => ({
    nameAtendanceProtocol: null,
    initialQueue: null,
    selectedDestination: 'Intranet',
    destinationNew: [
      { text: "Intranet", value: "Intranet" },
      { text: "Portal do Aluno", value: "Portal do Aluno" },
    ],
    courseModality: [],
    selectedCourse: [
      { text: "Técnico", value: 1 },
      { text: "Livre", value: 2 },
      { text: "Graduação", value: 3 },
      { text: "Idiomas", value: 4 },
      { text: "Pós-Graduação", value: 5 },
      { text: "Extensão", value: 6 },
    ],
    requiredFields: null,
    describeFlow: null,
    describeProtocol: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  methods: {
    async createAttendance() {
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A13-10');

      payload.data.locationId = personLocalId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME DO ATENDIMENTO/PROTOCOLO: ${this.nameAtendanceProtocol}\nFILA INICIAL: ${this.initialQueue}\nMODALIDADE DE CURSO: ${this.selectedDestination == 'Intranet' ? 'Intranet' : this.courseModality}\nCAMPOS NECESSÁRIOS: ${this.requiredFields}\nDESCRIÇÃO DO FLUXO: ${this.describeFlow}\nDESCRIÇÃO DO PROTOCOLO: ${this.describeProtocol}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
      .createAttendance(payload.data)
      .then(({ data }) => {
        this.idAttendance = data;
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorIdAttendance = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.resetForm();
        this.$vs.loading.close();
      });
    },

    resetForm() {
      this.nameAtendanceProtocol = null;
      this.initialQueue = null;
      this.selectedDestination = "Intranet";
      this.courseModality = [];
      this.requiredFields = null;
      this.describeFlow = null;
      this.describeProtocol = null;

      this.$nextTick(() => {
          this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },      
  },  
};
</script>

<style scoped>
.checkboxTurn {
  margin: 1em;
  margin-bottom: .5em;
}

@media (max-width: 1000px) {
    .boxTurn {
        flex-direction: column;
    }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: .9em;
  }
}
</style>
  