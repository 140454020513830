<template>
 <section>
    <NavBarAtendimentos />  
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'gavel']" />
              <h2 class="tituloAtendimento">
                Juridico
              </h2>
            </div>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.value"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>  

        <ContratoDeDireitosAutorais v-if="selectedTypes === 1"/>
        <ContratoDePrestacaoDeServicos v-if="selectedTypes === 2"/>
        <Processos v-if="selectedTypes === 3"/>
        <AnaliseDeDocumentos v-if="selectedTypes === 4"/>
        <Convenios v-if="selectedTypes === 5"/>
      </div>
    </div>  
  </section> 
</template>

<script>
import ContratoDeDireitosAutorais from './FormJuridico/ContratoDeDireitosAutorais.vue';
import ContratoDePrestacaoDeServicos from './FormJuridico/ContratoDePrestacaoDeServicos.vue';
import Processos from './FormJuridico/Processos.vue';
import AnaliseDeDocumentos from './FormJuridico/AnaliseDeDocumentos.vue';
import Convenios from './FormJuridico/Convenios';
export default {
  name: "Juridico",
  components: {
    ContratoDeDireitosAutorais,
    ContratoDePrestacaoDeServicos,
    Processos,
    AnaliseDeDocumentos,
    Convenios

  },
  data: () => ({
    selectedTypes: null,
    types: [
      {text: "Contrato de Direitos Autorais", value: 1},
      {text: "Contrato de Prestação de Serviço", value: 2},
      {text: "Processos", value: 3},
      {text: "Análise de Documentos", value: 4},
      {text: "Convênios", value: 5},
    ]

  })
}
</script>
