import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import { getProfiles, isLogged } from "../helpers/storage";

import router from "@/router";

Vue.use(AclInstaller);

const LOGGED_ROLE_DEFAULT = ["public", "logged"];

let initialRole = "public";

function _setProfiles() {
  let profiles = getProfiles();

  if (profiles && profiles.length > 0) {
    profiles.push(...LOGGED_ROLE_DEFAULT);
    initialRole = profiles;
  } else if (isLogged()) {
    initialRole = LOGGED_ROLE_DEFAULT;
  }
}

_setProfiles();

export default new AclCreate({
  initial: initialRole,
  notfound: "/not-authorized",
  router,
  acceptLocalRules: true,
  middleware: async acl => {
    _setProfiles();
    acl.change(initialRole);
  },
  globalRules: {
    isLogged: new AclRule("logged").or("DBA").generate(),
    public: new AclRule("public").generate(),
    isSecretary: new AclRule("Secretária_QI")
    .or("Gerente_QI")
    .or("DBA")
    .generate(),
    isOpenRH: new AclRule("DBA")
    .or("CalcComissaoCom")
    .generate(),
    isManager: new AclRule("VISAOGERAL")
    .or("Gerente_QI")
    .or("DBA")
    .generate(),
    isAll: new AclRule("VISAOGERAL")
    .or("Secretária_QI")
    .or("Gerente_QI")
    .or("DBA")
    .generate(),
    isCommercial: new AclRule("DBA")
    .or("Comercial_QI")
    .generate(),
  }
});
