<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <ValidationObserver ref="observer" v-slot="{ invalid }" class="validator">
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas','scroll']" />
            <h2 class="tituloAtendimento">
               Normativas
            </h2>
          </div>
        </vs-row>
        
        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>N°. Normativa <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Normativa"
                  rules="required"
                   
                >
                  <vs-input
                    v-model="normativa"
                    placeholder="Digite o N°. Normativa *"
                    class="selectExample"
                    type="number"
                  >
                  </vs-input>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Texto Solicitação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Texto Solicitação *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
        </div>      
      </div>
      </ValidationObserver>    
    </div>
  </section>
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import AdministrativoModel from "../../../models/administrativo/administrativo";

export default {
  name: "Normativas",
  data: () => ({
   
    normativa: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,    
  }),
  
  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A9');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText= `USUÁRIO: ${personName} - ${personId}\nN°. Normativa: ${this.normativa}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    
    resetForm() {
      this.solicitation = null;
      this.normativa = null;
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};

</script>
