<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'graduation-cap']" />
          <h2 class="tituloAtendimento">            
            Criação de Curso Novo
          </h2>
        </div>
      </vs-row>          

      <ValidationObserver ref="observer2" v-slot="{ invalid }">
        <div>     
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Nome Do Curso <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nome Do Curso"
                  rules="required"
                   
                >
                  <vs-input
                    class="selectExampleDouble"                    
                    v-model="nameCourse"
                    placeholder="Digite o Nome de Curso"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>   

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Carga Horária <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Carga Horária"
                  rules="required|numeric"
                   
                >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="workload"
                    placeholder="Digite a Carga Horária em Horas"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>             
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Filial <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Filial"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExample"
                    placeholder="Selecione uma Filial"
                    v-model="selectedUnit"
                  >
                    <vs-select-item
                      :key="item.CODFILIAL"
                      :value="item.CODFILIAL"
                      :text="item.POLO"
                      v-for="item in units"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>                  
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Nivel de Ensino <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nivel de Ensino"
                  rules="required"
                   
                >                                       
                  <div class="boxTurn">
                    <input type="radio" v-model="levelEducation" name="levelEducation" id="Escola" value="Escola" class="radioButton" />
                    <label for="Escola">Escola</label>
                    <input type="radio" v-model="levelEducation" name="levelEducation" id="Faculdade" value="Faculdade" class="radioButton" />
                    <label for="Faculdade">Faculdade</label>
                  </div>
                   
                </ValidationProvider>                
              </fieldset>
            </vs-col>              

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Tipo de Ensino <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Tipo de Ensino"
                  rules="required"
                   
                >       
                  <div class="boxTurn">                  
                    <input type="radio" v-model="typeEducation" name="typeEducation" id="Presencial" value="Presencial" class="radioButton" />
                    <label for="Presencial">Presencial</label>
                    <input type="radio" v-model="typeEducation" name="typeEducation" id="Distância" value="Distância" class="radioButton" />
                    <label for="Distância">Distância</label>
                  </div>                   
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>          
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Anexo <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Anexo"
                  ref="provider"
                  rules="required"
                   
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                   
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Justificativa"
                rules="required|max:500"
                 
              >
                <vs-textarea
                  label="Justificativa *"
                  height="210px"
                  rows="11"
                  class="textArea"
                  counter="500"
                  v-model="solicitation"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import EducacionalModel from "../../../../models/educacional/educacional.js";
import { getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import Units from "../../../../services/units";

export default {
  name: "CriacaoDeCursoNovo",
  data: () => ({
    files: null,
    base64Image: null,
    selectedUnit: null,
    units: null,
    errorUnits: null,
    nameCourse: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    workload: null,
    levelEducation: null,
    typeEducation: null,
    visibleButton: false,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnits = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
    .getAllCourses()
    .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
        if (response && response.data) {
          this.errorCourses = response.data;
          console.log(response)
        }
    })
    .finally(() => {
        this.$vs.loading.close();
    });
  },

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1-6");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME DO CURSO: ${this.nameCourse}\nCARGA HORÁRIA: ${this.workload}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nNIVEL DE ESNINO: ${this.levelEducation}\nTIPO DE ENSINO: ${this.typeEducation}\nJustificativa: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
        this.selectedUnit = "";
        this.errorUnits = null;
        this.nameCourse = null;
        this.workload = null;
        this.solicitation = null;
        this.typeEducation = null;
        this.levelEducation = null;
        this.visibleButton = false;
        this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
    getAllRooms() {
        new Units()
        .getAllRooms(this.selectedUnit)
        .then(({ data }) => {
          this.rooms = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorRooms = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
  },
};
</script>

<style scoped>
.boxTurn {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 400px) {
  .boxTurn {
    flex-direction: column;    
  }
}
</style>
