<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'filter']" />
              <h2 class="tituloAtendimento">
                Atendimento Numerários
              </h2>
            </div>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.value"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>
              </fieldset>  
            </vs-col>
          </vs-row>            
        </div>

        <AcaoComercial v-if="selectedTypes == 'Ação Comercial'"/>
        <ViagensEDeslocamentos v-if="selectedTypes == 'Viagens e deslocamentos'"/>
        <SolicitacaoDeReembolso v-if="selectedTypes == 'Solicitação de Reembolso'" />
        <CorreiosEMalotes v-if="selectedTypes == 'Correios e Malotes'"/>      
      </div>
    </div>    
  </section>
</template>

<script>
import AcaoComercial from './FormNum/AcaoComercial.vue'
import ViagensEDeslocamentos from './FormNum/ViagensEDeslocamentos.vue';
import SolicitacaoDeReembolso from './FormNum/SolicitacaoDeReembolso.vue';
import CorreiosEMalotes from './FormNum/CorreiosEMalotes.vue'


export default {
  name: "Numerarios",
  components: {
    AcaoComercial,
    ViagensEDeslocamentos,
    SolicitacaoDeReembolso,
    CorreiosEMalotes,
  },
  data: () => ({
    selectedTypes: null,
    
    types: [
      { text: "Ação Comercial", value: "Ação Comercial" },
      { text: "Viagens e Deslocamentos", value: "Viagens e deslocamentos" },
      { text: "Solicitações de Reembolso", value: "Solicitação de Reembolso" },
      { text: "Correios e Malotes", value: "Correios e Malotes" },
    ],
  }),

  
};

</script>
