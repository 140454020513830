<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
            Solicitação de Atendimento NADD
          </h2>
        </div>
      </vs-row>
      
      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col>
                <fieldset class="search">
                  <legend>RA do Aluno <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="RA"
                    rules="required|numeric"
                     
                  >
                    <vs-input
                      @keyup.enter="getCoursesByRA()" 
                      v-model="ra"
                      placeholder="Digite o RA do Aluno"
                      class="selectExampleRA"
                    >
                    </vs-input>
                     
                  </ValidationProvider>
                  <vs-button
                    color="primary"
                    class="buscar"
                    type="filled"
                    :disabled="invalid"
                    @click="getCoursesByRA()"
                  >
                    <font-awesome-icon :icon="['fas', 'search']" />
                    <span> Buscar </span></vs-button
                  >
                </fieldset>              
              </vs-col>
            </vs-row>          
        </ValidationObserver>
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">        
        <div   v-if="responseRa != null">
          <div v-if="responseRa == ''">
            <p class="validacao">RA Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Nome do Aluno</legend>
                  <vs-input
                    class="selectExample"
                    :placeholder="responseRa[0].NOME_ALUNO"
                    disabled="disabled"
                  />
                </fieldset>                    
              </vs-col>
            </vs-row>                

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Telefone <b style="color: red;">*</b></legend>
                  <ValidationProvider
                      name="Telefone"
                      rules="required|min:15"
                       
                  >                        
                    <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o telefone"
                        v-model="phone"
                        v-mask="'(##) #####-####'"
                    />                        
                       
                  </ValidationProvider>
                </fieldset>                  
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Telefone Reserva <b style="color: red;">*</b></legend>
                  <ValidationProvider
                      name="Telefone Reserva"
                      rules="required|min:15"
                       
                  >                        
                    <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o telefone reserva"
                        v-model="reservePhone"
                        v-mask="'(##) #####-####'"
                    />                        
                       
                  </ValidationProvider>
                </fieldset>                  
              </vs-col>
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Email <b style="color: red;">*</b></legend>
                  <ValidationProvider
                      name="Email"
                      rules="required"
                       
                  >                     
                    <vs-input
                        class="selectExampleDouble"                          
                        placeholder="Digite o email"
                        v-model="email"
                        type="email"
                    />                      
                     
                  </ValidationProvider>
                </fieldset>                                      
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Email Reserva <b style="color: red;">*</b></legend>
                  <ValidationProvider
                      name="Email Reserva"
                      rules="required"
                       
                  >                     
                    <vs-input
                        class="selectExampleDouble"                          
                        placeholder="Digite o email reserva"
                        v-model="reserveEmail"
                        type="email"
                    />                      
                     
                  </ValidationProvider>
                </fieldset>                                      
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                    name="Solicitação"
                    rules="required|max: 500"
                     
                >
                    <vs-textarea
                        rows="11"
                        label="Justificativa *"
                        class="textArea"
                        height="15em"
                        counter="500"
                        v-model="solicitation"
                    />
                     
                </ValidationProvider>
              </vs-col>          
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Students from "../../../../services/students";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
    name: "SolicitacaoNADD",
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    idAttendance: null,
    errorIdAttendance: null,
    phone: null,
    solicitation: null,
    reservePhone: null,
    email: null,
    reserveEmail: null,
  }),

  methods: {
    getCoursesByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCoursesByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
  
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const studentName = this.responseRa[0].NOME_ALUNO;      

      const payload = new EducacionalModel().getDataByAttendanceId("E5-19");

      payload.data.locationId = personLocal.toString();
      payload.data.ra = this.ra;
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA DO ALUNO: ${this.ra}\nALUNO: ${studentName}\nTELEFONE: ${this.phone}\nTELEFONE RESERVA: ${this.reservePhone}\nEMAIL: ${this.email}\nEMAIL RESERVA: ${this.reserveEmail}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.reservePhone = null;
      this.phone = null;
      this.email = null;
      this.reserveEmail = null;
      this.solicitation = null;
      this.responseRa = null;  
      this.ra = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
