import { https } from './api'

export default class {
    constructor() {
        this.instance = https('realTime')
    }

    getEnrollCourses = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/enrollCourses',
            
        });
    }

    getCourseTypeStudents = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/courseTypeStudents',
            
        });
    }

    getMatriculationMAInTheCurrentMonth = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/matriculationMAInTheCurrentMonth',
           
        });
    }

    getMatriculationRCInTheCurrentMonth = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/matriculationRCInTheCurrentMonth',
            
        });
    }

    getRetentionData = () => {
        return this.instance ({ 
            method: 'GET',
            url: '/retention',
           
        });
    }
}