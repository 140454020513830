<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'laptop-code']" />
              <h2 class="tituloAtendimento">
                 TI
              </h2>
            </div>
          </vs-row>          

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.types"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>                                                             
              </fieldset>                                                     
            </vs-col>
          </vs-row>
        </div>
        <AjusteDeUsuario v-if="this.selectedTypes == 1"/>
        <AlteracaoProcesso v-if="this.selectedTypes == 2"/>
        <AutomatizacaoProcesso v-if="this.selectedTypes == 3"/>
        <CriacaoAtendimentoProtocolo v-if="this.selectedTypes == 4" />
        <Cubos v-if="this.selectedTypes == 5" />
        <InfraestruturaETelefonia v-if="this.selectedTypes == 6" />
        <OutrasSolicitacoes v-if="this.selectedTypes == 7" />
        <Relatorios v-if="this.selectedTypes == 8" />
        <ErrosReportados v-if="this.selectedTypes == 9" />
        <UnificaRaPessoa v-if="this.selectedTypes == 10" />
      </div>
    </div>    
  </section>
</template>

<script>

import AjusteDeUsuario from "./FormTi/AjusteDeUsuario.vue";
import AlteracaoProcesso from "./FormTi/AlteracaoProcesso.vue";
import AutomatizacaoProcesso from "./FormTi/AutomatizacaoProcesso.vue";
import Cubos from "./FormTi/Cubos.vue";
import ErrosReportados from "./FormTi/ErrosReportados.vue"
import InfraestruturaETelefonia from "./FormTi/InfraETelefonia.vue";
import OutrasSolicitacoes from "./FormTi/OutrasSolicitacoes.vue";
import Relatorios from "./FormTi/Relatorios.vue";
import UnificaRaPessoa from "./FormTi/UnificaRaPessoa.vue";
import CriacaoAtendimentoProtocolo from "./FormTi/CriacaoAtendimentoProtocolo.vue";


export default {
  name: "Ti",
  components: {    
    AjusteDeUsuario,
    AlteracaoProcesso,
    AutomatizacaoProcesso,
    Cubos,
    ErrosReportados,
    InfraestruturaETelefonia,
    OutrasSolicitacoes,
    Relatorios,
    UnificaRaPessoa,
    CriacaoAtendimentoProtocolo
  },
  data: () => ({
    types: [
      { text: "Ajuste de Usuário", value: 1 },
      { text: "Alteração de Processo", value: 2 },
      { text: "Automatização de Processo", value: 3 },
      { text: "Criação de Atendimento/Protocolo", value: 4 },
      { text: "Cubos", value: 5 },
      { text: "Infraestrutura / Telefonia", value: 6 },
      { text: "Outras Solicitações", value: 7 },
      { text: "Relatórios", value: 8 },
      { text: "Reportar Erro", value: 9 },
      { text: "Unificação de RA", value: 10 },
    ],
    selectedTypes: null,
  }),
};
</script>
