<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'plane']" />
          <h2 class="tituloAtendimento">
             Férias
          </h2>
        </div>
      </vs-row>
      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
        <ValidationObserver ref="observer" v-slot="{ invalid }">          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Nome Colaborador <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Nome Colaborador"
                  rules="required"
                   
                >
                  <vs-select
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample"
                    placeholder="Digite o Nome do Colaborador"
                    v-model="indexSelectedSub"
                    v-on:change="getVacationByPlate"
                  >
                    <vs-select-item
                      :key="index"
                      :value="index"
                      :text="item.NOME"
                      v-for="(item, index) in data.employees"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>            
          </vs-row>

          <div v-if="indexSelectedSub != null">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Função <b style="color: red;">*</b></legend>
                  <vs-input
                    disabled
                    v-model="data.employees[indexSelectedSub].FUNCAO"
                    class="selectExampleDouble"
                  />
                </fieldset>
              </vs-col> 

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Últimas Férias <b style="color: red;">*</b></legend>
                  <vs-input disabled v-model="lastVacation" class="selectExampleDouble"/>
                </fieldset>
              </vs-col>             
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data Início das Férias <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Data Início das Férias"
                    rules="required"
                     
                  >
                    <vs-input
                      type="date"
                      v-model="startDateVacation"
                      class="selectExampleDouble"
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Tipo de Férias <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Tipo de Férias"
                    rules="required"
                     
                  >
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione o Tipo de Férias"
                      v-model="typeSelectedVacation"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.typeVacation"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>              
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                name="Observação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  label="Observação"
                  height="210px"
                  rows="11"
                  class="textArea"
                  counter="500"
                  v-model="solicitation"
                />
                 
              </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row> 
          </div>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import Person from "../../../../services/person";
import Attendance from "../../../../services/attendance";
import PessoalModel from "../../../../models/pessoal/pessoal.js";
import {
  convertDate,
  getLoginDataFromStorage,
} from "../../../../helpers/storage";

export default {
  name: "Ferias",
  data: () => ({
    indexSelectedSub: null,
    startDateVacation: null,
    typeSelectedVacation: null,
    lastVacation: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    data: {
      employees: null,
      errorEmployees: null,
      vacation: null,
      errorVacation: null,
      typeVacation: [
        { text: "30 dias", value: "30 dias" },
        {
          text: "20 + 10 (abono de férias)",
          value: "20 + 10 (abono de férias)",
        },
      ],
    },
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Person()
      .getEmployees()
      .then(({ data }) => {
        this.data.employees = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.errorEmployees = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    inputChange(event) {
      return event.target.value;
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new PessoalModel().getDataByAttendanceId('P3-2');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.subject = "RH - MOVIMENTAÇÃO DE PESSOAL - FÉRIAS";
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME/CHAPA: ${this.data.employees[this.indexSelectedSub].NOME} - ${this.data.employees[this.indexSelectedSub].CHAPA}\nDATA DE INICIO DAS FERIAS: ${convertDate(this.startDateVacation)}\nTIPO DE FÉRIAS: ${this.typeSelectedVacation}\nOBSERVAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },
    resetForm() {
      this.startDateVacation = null;
      this.typeSelectedVacation = '';
      this.solicitation = null;
      this.indexSelectedSub = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getVacationByPlate() {
      if (!!this.data.employees && Number.isInteger(this.indexSelectedSub)) {
        new Person()
          .getVacationByPlate(this.data.employees[this.indexSelectedSub].CHAPA)
          .then(({ data }) => {
            this.data.vacation = Array.isArray(data) ? data : [data];
            this.lastVacation = this.data.vacation[0].INI_FERIAS;
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.data.errorVacation = response.data;
              console.log(response)
            }
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>

