<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div class="subContainer">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'box']" />
            <h2 class="tituloAtendimento">
              Convênios
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>CNPJ da Empresa <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="CNPJ da Empresa"
                  rules="required"
                   
                >
                  <vs-input
                    v-model="cnpjCompany"
                    placeholder="Digite o CNPJ Da Empresa"
                    class="selectExampleDouble"
                    v-mask="'##.###.###/####-##'"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Assunto <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Assunto"
                  rules="required"
                   
                >
                  <vs-input v-model="subject" placeholder="Digite o Assunto" class="selectExampleDouble" />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Texto Solicitação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  rows="11"
                  label="Texto Solicitação *"
                  class="textArea"
                  height="15em"
                  counter="500"
                  v-model="solicitation"
                />
                 
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
        </div>
      </div>
    </ValidationObserver>
  </section> 
</template>

<script>
import Attendance from "../../../../services/attendance";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
export default {
    name: "Convenios",
    data: () => ({
      cnpjCompany: null,
      subject: null,
      solicitation: null,
      idAttendance: null,
      errorIdAttendance: null,
    }),

    methods: {

      async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A7-5');

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = this.subject; 
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCNPJ da Empresa: ${this.cnpjCompany}\nASSUNTO: ${this.subject}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

      resetForm() {
        this.cnpjCompany = null;
        this.subject = null;
        this.solicitation = null;

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      },
    }
}
</script>
