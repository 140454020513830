<template>
  <div class="qi-login">
    <div class="support">
      <img src="@/assets/suporte.png" class="img-suporte" alt="Suporte QI" />
      <div>
        <h3 class="support-title">Utilize o chat para atendimento nos horários conforme abaixo:</h3>
        <p class="support-description"><em>Fora do horário de atendimento, favor abrir atendimento ou deixar mensagem offline no chat</em></p>
        <vs-divider/>
      </div>
      <div>
        <vs-col class="support-column">
          <fieldset>
            <p>
              <strong>
                <font-awesome-icon :icon="['fas', 'coins']" />
                FINANCEIRO <br /> <br />
              </strong>
              Segunda à Sexta: 08h30 às 19h <br />
              Sábado: 8h30 às 12h
            </p>
          </fieldset>
          <fieldset>
            <p>
              <strong>
                <font-awesome-icon :icon="['fas', 'headset']" />
                SECRETARIA ONLINE | RELACIONAMENTO <br /> <br />
              </strong>
              Segunda à Sexta: 08h30 às 21h <br />
              Sábado: 8h30 às 16h
            </p>
          </fieldset>
          <fieldset>
            <p>
              <strong>
                <font-awesome-icon :icon="['fas', 'question-circle']" />
                SUPORTE <br /> <br />
              </strong>
              Segunda à Sexta: 10h30 às 19h30 <br />
              Sábado: 10h às 14h
            </p>
          </fieldset>
          <!--<p>
            Infraestrutura <br />
            <strong>
              Segunda à Sexta: 08h30 às 21h <br />
              Sábado: 8h30 às 12h | 13h às 16h
            </strong>
          </p>-->
        </vs-col>
        <vs-col class="support-column">
          <fieldset>
            <p>
              <strong>
                <font-awesome-icon :icon="['fas', 'file-invoice']" />
                DEPARTAMENTO PESSOAL <br /> <br />
              </strong>
              Segunda à Sexta: 08h30 às 12h | 13h às 18h18
            </p>
          </fieldset>
          <fieldset>
            <p>
              <strong>
                <font-awesome-icon :icon="['fas', 'graduation-cap']" />
                PLANEJAMENTO ACADÊMICO <br /> <br />
              </strong>
              Segunda à Sexta: 08h30 às 18h18
            </p>
          </fieldset>
        </vs-col>
      </div>
    </div>

    <div class="qi-form">
      <div class="logo">
        <img src="../assets/qi-logo-branco.svg" alt="Logo QI" />
      </div>

      <div class="title-intranet">
        <h3>Intranet</h3>
        <vs-row>
          <vs-input
            class="user"
            v-model="user"
            label-placeholder="Login"
            @keyup.enter="focusPasswordInput"
          />
        </vs-row>
        <vs-row>
          <vs-input
            class="password"
            v-model="password"
            type="password"
            label-placeholder="Senha"
            ref="password"
            @keyup.enter="login"
          />
        </vs-row>
        <vs-row>
          <a
            class="forgot-password"
            href="https://qifaculdade132663.rm.cloudtotvs.com.br/FrameHTML//Web/App/RH/PortalMeuRH/#/login"
            target="_blank"
          >
            <font-awesome-icon :icon="['fas', 'lock']" />
            Esqueceu a Senha?
          </a>
        </vs-row>
        <vs-divider/>
        <vs-row v-if="error">
          <h4 class="error-login">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
            {{ error }}
          </h4>
        </vs-row>
        <vs-row vs-w="12">
          <vs-button 
            class="button-login" 
            @click="login"
            :disabled="!user || !password"
          >
            <font-awesome-icon :icon="['fas', 'sign-in-alt']" />
            Entrar 
          </vs-button>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "../services/auth";
import { saveDataInStorage } from "../helpers/storage";
import { bus } from "../main";

var Tawk_API = Tawk_API || {};
(function () {
  var s1 = document.createElement("script");
  var s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/5f21c4894f3c7f1c910d78fd/default";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();

export default {
  name: "Login",
  data: () => ({
    user: "",
    colorItem: "#C50711",
    password: "",
    error: null,
    itens: [],
  }),
  created() {
    bus.$emit("clearSteps");
  },
  methods: {
    login() {
      this.$vs.loading({ text: "Carregando..." });
      new LoginService()
        .login({
          username: this.user,
          password: this.password,
        })
        .then((res) => {
          saveDataInStorage(res.data);
          bus.$emit("login");
          this.$vs.loading.close();
          this.$router.push("/home");
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },
    focusPasswordInput() {
      this.$refs.password.focusInput();
    },
  },
};
</script>

<style scoped>
.title-intranet {
  margin-top: 40px;
}

.vs-divider {
  margin: 13.5px 0 0 0;
}

fieldset {
  margin-top: 15px !important;
  height: 97.5px;
}

.support-title {
  margin-top: 13.5px;
} 

.support-description {
  margin-top: 5px; 
  text-align: center;
}

.support-column {
  width: 50% !important;
}

h1 {
  text-align: center;
  margin: 1em;
  color: #ffffff;
}

h3 {
  margin: 0 auto;
  text-align: center;
}

.img-suporte {
  border-radius: 7.5px;
  width: 100%;
}

.support {
  padding: 15px;
  background: none;
}

.vs-input {
  width: 15em !important;
}

.qi-form {
  background: var(--light);
  padding: var(--maxmp);
  color: var(--secundary);
}

.qi-login {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(2, minmax(auto, auto));
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 165px;
  margin-top: -13.5px !important;
  background: var(--primary);
  margin: auto;
  border-radius: 7.5px;
  padding: var(--mp);
  box-shadow: 5px 5px var(--dark);
}

.logo img {
  width: 80%;
}

.forgot-password {
  font-size: 0.815em;
  margin-top: 12.5px;
  width: 100%; 
  text-align: center;
}

.user,
.password {
  margin: auto;
  margin-top: 35px !important;
  width: 100% !important;
}

.error-login {
  margin: 3.5% auto auto auto;
  font-size: 0.95em;
  color: #8B0000;
  font-weight: bold;
}

.button-login {
  background: #571515;
  border-radius: 20px;
  margin: auto;
  width: 100%;
  margin-top: 15px;
}

@media screen and (max-width: 1290px) {
  .error-alert {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 1100px) {
  .logo {
    width: var(--logo-square) !important;
    height: var(--logo-square) !important;
  }

  .support-column {
    width: 100% !important;
  }
}

@media screen and (max-width: 1000px) {
  .support-title,
  .support-description {
    font-size: 0.915em;
  }
}

@media screen and (max-width: 860px) {
  .error-alert {
    height: 50px !important;
  }
}

@media screen and (max-width: 760px) {
  .support-title,
  .support-description {
    font-size: 1em;
  }

  .title-intranet {
    margin-top: 30px;
  }

  .qi-login {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
  }

  .qi-form {
    width: 100%;
  }

  .button-login {
    width: 65%;
  }
}

@media screen and (max-width: 730px) {
  .error-alert {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 675px) {
  .error-alert {
    height: 60px !important;
  }
}

@media screen and (max-width: 600px) {
  .error-alert {
    height: 40px !important;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 500px) {
  .error-alert {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 415px) {
  .error-alert {
    font-size: 0.7em;
  }

  fieldset {
    height: 85px;
  }

  .support-title,
  .support-description {
    font-size: 1em;
  }

  .support-column > fieldset > p,
  .support-title,
  .support-description {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 360px) {
  fieldset {
    height: 75px;
  }

  .support-column > fieldset > p {
    font-size: 0.735em;
  }
}
</style>
