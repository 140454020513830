<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <section class="container">
        <div class="welcome">
          <h2>Bem-vindo(a) {{this.nome}}</h2>
        </div>
        <vs-divider />

        <vs-row class="contrast" v-if="trainning[0]">
          <vs-col>
            <vs-alert
              color="success"
              title="VOCÊ TEM TREINAMENTO HOJE!!"
              closable
              :active.sync="showTrainningAlert"
            >
              <p v-for="(item, index) in trainning" :key="index">
                <strong>{{ item.NOMECURSO }}</strong> - {{ item.HORAINICIO }}h -
                <span v-if="item.TIPO == 'ONLINE'">
                  <a :href="item.LOCAL" target="_blank">{{item.LOCAL}}</a>
                </span>
                <span v-else>{{ item.LOCAL }}</span>
              </p>
            </vs-alert>
          </vs-col>
        </vs-row>

        <vs-row class="contrast">
          <vs-col
            vs-lg="2"
            vs-sm="3"
            vs-xs="6"
            v-for="(item, index) in itens"
            :key="index.menu"
          >
            <vs-button
              v-if="item.type == 'external'"
              class="button-contrast"
              :color="colorItem"
              :href="item.url"
              target="_blank"
            >
              <font-awesome-icon
                class="icon-contrast"
                size="2x"
                v-bind:icon="item.icon"
              />
              <p class="text-button">{{ item.label }}</p>
            </vs-button>

            <vs-button
              v-else
              class="button-contrast"
              :color="colorItem"
              :href="item.url"
            >
              <font-awesome-icon
                class="icon-contrast"
                size="2x"
                v-bind:icon="item.icon"
              />
              <p class="text-button">{{ item.label }}</p>
            </vs-button>
          </vs-col>
        </vs-row>

        <vs-divider />

        <vs-row class="events">
          <vs-col>
            <h3>
              <font-awesome-icon style="margin-right: 0.5%; color: #9B0E1C;" :icon="['fas', 'newspaper']" />
              <a
                style="color: black"
                href="administrativo/acontecimentos"
              >
                Acontecimentos 
                <span style="color: #9B0E1C;">QI</span>
              </a>

              <span> | </span>

              <a style="color: #9B0E1C;" href="https://qi.edu.br/blog/" target="_blank">
                <font-awesome-icon :icon="['fas', 'wifi']"/>
                Blog QI
              </a>
            </h3>
          </vs-col>

          <div>
            <Galeria />
          </div>
        </vs-row>

        <vs-divider />

        <div class="birthday-text">
          <vs-col>
            <h3>
              <font-awesome-icon style="margin-right: 0.5%; color: #9B0E1C;" :icon="['fas', 'birthday-cake']" />
              Aniversariantes
            </h3>
          </vs-col>

          <div v-if="birthday == ''">
            <fieldset class="birthday-none">
              <p>Sem aniversariantes para hoje!!</p>
            </fieldset>
          </div>

          <div 
            class="birthday-grid"
            v-else
          >
            <div
              class="card-birthday"
              v-for="(i, index) in birthday"
              :key="index.birthday"
            >
              <vs-avatar
                v-if="i.IMAGEM != null"
                size="60px"
                :src="`data:image/png;base64,${i.IMAGEM}`"
              />

              <vs-avatar v-else size="60px" />
              <div class="title-office">
                <p>
                  <strong>Filial: </strong>FL{{ i.FILIAL }} <br />
                  <strong>Dep.: </strong>{{ i.DESC_SETOR }}
                </p>
              </div>

              <p class="title-birthday">{{ i.NOME }}</p>

              <a 
                style="font-size: 0.85em;"
                @click="sendMessage(i.EMAIL, i.NOME)"
                target="_blank"
              >
                <font-awesome-icon :icon="['fas', 'gift']" />
                Dê os parabéns
              </a>
            </div>
          </div>
        </div>

        <vs-divider />

        <div class="collaborator-text">
          <vs-col vs-w="12">
            <fieldset>
              <h3 style="text-align: center;">
                <font-awesome-icon style="color: #9B0E1C; margin-right: 5px;" :icon="['fas', 'bullhorn']" />
                BOAS-VINDAS
                <font-awesome-icon style="color: #9B0E1C; margin-left: 5px; transform: scaleX(-1);" :icon="['fas', 'bullhorn']" />
              </h3>

              <vs-divider/>

              <div v-if="newCollaborator == ''">
                <p class="collaborator-none">Sem novos colaboradores na semana!!</p>
              </div>

              <div 
                class="collaborator-grid"
                v-else
              >
                <div
                  class="card-collaborator"
                  v-for="(i, index) in newCollaborator"
                  :key="index.newCollaborator"
                >
                  <vs-avatar
                    v-if="i.IMAGEM != null"
                    size="60px"
                    :src="`data:image/png;base64,${i.IMAGEM}`"
                  />

                  <vs-avatar v-else size="60px" />
                  <div class="title-office">
                    <p>
                      <strong>Filial: </strong>{{ i.FILIAL }} <br />
                      <strong>Dep.: </strong>{{ i.DESC_SETOR }}
                    </p>
                  </div>

                  <p class="title-collaborator">{{ i.NOME }}</p>

                  <div style="margin: 8.5px 0;">
                    <fieldset style="padding: 1.5px;">
                      <b style="font-size: 0.8em;">
                        <font-awesome-icon :icon="['fas', 'calendar-day']" />
                        {{ i.DATAINICIO }}
                      </b>
                    </fieldset>
                  </div>
                </div>
              </div>
            </fieldset>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { getLoginDataFromStorage, firstName } from "../helpers/storage";
import User from "../services/person";
import MenuModel from "../models/menus/menu";
import Galeria from "../components/Galeria.vue";
import Person from "../services/person";

export default {
  components: {
    Galeria,
  },
  name: "home",
  data: () => ({
    nome: null,
    colorItem: "#888888",
    itens: [],
    user: null,
    birthday: null,
    newCollaborator: null,
    error: null,
    trainning: [],
    showTrainningAlert: true,
  }),

  mounted() {
    this.itens = new MenuModel().getData();

    const { ...user } = getLoginDataFromStorage();
    this.nome = firstName(user.userData.NOME).toUpperCase();
    this.trainning = user.trainning;
    const { CODUSUARIO, CHAPA, CODIGO } = user.userData;

    new User()
      .getDataUser(CODUSUARIO, CHAPA, CODIGO)
      .then(({ data }) => {
        this.user = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Person()
      .getBirthday()
      .then(({ data }) => {
        this.birthday = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Person()
      .getNewCollaborator()
      .then(({ data }) => {
        this.newCollaborator = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    sendMessage(email, name) {
      this.name = firstName(name);

      window.open('mailto:'+email+'?subject=🎊 Feliz aniversário '+this.name+ ' 🎊', '_blank');
    }
  }
};
</script>

<style scoped>
a {
  color: #666;
  font-weight: bold;
}

.container {
  border: none;
}

.welcome {
  width: auto !important;
  text-align: center;
  font-size: 0.915em;
}

.button-contrast {
  margin: 0 5% 10% 5%;
  border-radius: 20px;
  color: #9b0e1c !important;
  background-color: white !important;
  border: 1px solid rgba(161, 0, 0, 0.25);
  box-shadow: rgba(161, 0, 0, 0.5) -3.5px 3.5px 1px, rgba(150, 150, 150, 0.5) -2.5px 10px 15px;
  width: 9.5em;
  height: 8.5em;
}

.button-contrast:hover {
  font-weight: bold;
  border: 1px solid rgba(70, 0, 0, 0.25);
  box-shadow: rgba(70, 0, 0, 0.75) -3.5px 3.5px 1px, rgba(70, 0, 0, 0.15) -2.5px 10px 15px !important;
  color: rgb(70, 0, 0) !important;
}

.pd-t > p {
  margin-top: 0.75%;
}

.icon-contrast {
  margin: 10px auto;
}

.birthday-none,
.collaborator-none {
  width: 60%;
  border-radius: 20px;
  text-align: center;
  margin: auto 20% !important;
}

.birthday-grid,
.collaborator-grid {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, auto));
  justify-content: space-around;
}

.card-birthday,
.card-collaborator {
  padding: 0 10px;
  margin: 25px;
  border-radius: 20px;
  border: 1px solid rgba(161, 0, 0, 0.25);
  box-shadow: rgba(161, 0, 0, 0.5) -3.5px 3.5px 1px, rgba(150, 150, 150, 0.5) -2.5px 10px 15px;
}

.text-without-birthday {
  width: 18.5em !important;
}

.events {
  display: grid;
  justify-content: center;
}

.title-contrast,
.birthday-text,
.collaborator-text {
  margin-bottom: 15px;
}

.events > div:nth-child(1),
.birthday-text > div,
.collaborator-text > div {
  margin-bottom: 1.15em;
}

.title-birthday,
.title-office,
.title-collaborator {
  font-size: 0.8em;
  margin: auto;
}

.title-birthday,
.title-collaborator {
  width: 100%;
  font-weight: bold;
  color: #9b0e1c;
  margin-bottom: 3.5px;
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 1370px) {
  .birthday {
    width: 16.5em !important;
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 1220px) {
  .birthday {
    width: 20em !important;
  }
}

@media screen and (max-width: 1190px) {
  .button-contrast {
    width: 8.5em;
    height: 8.15em;
  }
}

@media screen and (max-width: 1045px) {
  .birthday-grid,
  .collaborator-grid {
    grid-template-columns: repeat(2, minmax(auto, auto));
  }
}

@media screen and (max-width: 975px) {
  .button-contrast {
    width: 7.5em;
    height: 7.5em;
  }

  .text-button {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 900px) {
  .birthday-grid,
  .collaborator-grid {
    grid-template-columns: none;
    justify-content: center;
  }

  .card-birthday,
  .card-collaborator {
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .button-contrast {
    width: 9.5em;
    height: 8.5em;
  }

  .text-button {
    font-size: 1em;
  }
}

@media screen and (max-width: 790px) {
  .columns {
    grid-template-columns: none;
  }

  .menu {
    display: none;
  }

  .contrast {
    margin-left: 1.5%;
  }

  .icon-contrast {
    margin: 10px auto;
  }
}

@media screen and (max-width: 720px) {
  .button-contrast {
    margin: 0 0% 10% 0%;
    width: 8.5em;
    height: 8.25em;
  }
}

@media screen and (max-width: 600px) {
  .contrast {
    margin-left: 10%;
  }

  .button-contrast {
    margin: 0 5% 10% 5%;
    width: 9.5em;
    height: 8.5em;
  }
}

@media screen and (max-width: 570px) {
  .contrast {
    margin-left: 8.5%;
  }

  .birthday {
    width: 25em !important;
  }

  .birthday-none,
  .collaborator-none {
    width: 80%;
    margin: auto 10% !important;
  }
}

@media screen and (max-width: 480px) {
  .birthday {
    width: 17.5em !important;
    margin-left: 1em;
  }

  .card-birthday,
  .card-collaborator {
    width: 95%;
    margin: 0 2.5% 20px 2.5%;
    padding: 0 5px;
  }
}

@media screen and (max-width: 420px) {
  .contrast {
    margin-left: 4.5%;
  }
}

@media screen and (max-width: 375px) {
  .birthday {
    width: 17em;
    margin-left: 0;
  }

  .birthday-none,
  .collaborator-none {
    width: 90%;
    margin: auto 5% !important;
  }
}

@media screen and (max-width: 340px) {
  .button-contrast {
    width: 8.5em;
    height: 8.15em;
  }

  .birthday {
    width: 15em;
  }
}
</style>