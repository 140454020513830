<template>
  <section>
    <div class="events">
      <vs-card
        actionable
        class="cardx events-card"
        v-for="(i, index) in news"
        :key="index"
      >
        <div slot="media">
          <img class="image" :src="news[index].NOMEIMAGEM" />

          <div class="information_event">
            <h4>{{ news[index].TITULO }}</h4>

            <div v-html="limitCaracter(news[index].TEXTO)"></div>

            <vs-divider />

            <vs-button @click="handleClickDetails(news[index])">
              <font-awesome-icon :icon="['fas', 'plus']" />
              Ver Mais
            </vs-button>
          </div>
        </div>
      </vs-card>

      <vs-popup
        style="z-index: 999999 !important"
        title="Acontecimentos QI"
        :active.sync="popupGallery"
        v-if="selectedEvent"
      >
        <vs-card outlined>
          <h3 class="title">{{ selectedEvent.TITULO }}</h3>
          <img class="image_popup" :src="selectedEvent.NOMEIMAGEM" />
          <div v-html="selectedEvent.TEXTO" class="description"></div>
        </vs-card>
      </vs-popup>
    </div>
  </section>
</template>

<script>
import Events from "../services/events";

export default {
  name: "Galeria",
  data: () => ({
    news: null,
    error: null,
    selectedEvent: null,
    popupGallery: false,
  }),

  mounted() {
    new Events()
    .getTopNews()
    .then(({ data }) => {
      this.news = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.error = response.data;
      }
    });
  },

  methods: {
    limitCaracter(texto) {
      if (texto.length < 180) {
        return texto;
      } else {
        return texto.slice(0, 180) + "...";
      }
    },

    handleClickDetails(selectedEvent) {
      this.selectedEvent = selectedEvent;
      this.popupGallery = true;
    },
  },
};
</script>

<style scoped>
.events {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, auto));
  column-gap: 0.85em;
  justify-content: center;
}

.events-card {
  width: 23vmax;
  box-shadow: 0px 0px 2px 1px #888888;
}

.image {
  padding: 1.25%;
  object-fit: cover;
  border-radius: 10px;
}

.events-card:hover {
  border: 1px solid;
}

.events-card:hover .image {
  display: none;
}

.events-card:hover .information_event {
  display: grid;
}

.information_event {
  display: none;
  padding: 2.5%;
}

.information_event > h4 {
  margin-top: 3.5%;
  text-align: center;
}

.information_event > div {
  margin-top: 5%;
  text-align: justify;
}

.title {
  font-size: 1.45em;
}

.image_popup {
  margin-top: 3%;
  margin-left: 20%;
  margin-bottom: 3%;
  height: 60%;
  width: 60%;
}

.description {
  text-align: justify;
}

h3 {
  text-align: center;
}

img {
  height: 21.5vmax;
}

@media screen and (max-width: 1080px) {
  .information_event > p {
    font-size: 0.75em;
    margin-top: 2.5%;
  }
}

@media screen and (max-width: 1000px) {
  .events {
    grid-template-columns: repeat(2, minmax(auto, auto));
    column-gap: 5em;
  }

  img {
    height: 25vmax;
  }

  .events-card {
    width: 26.5vmax;
  }
}

@media screen and (max-width: 900px) {
  .events {
    column-gap: 3.5em;
  }

  .events-card {
    width: 27.5vmax;
  }
}

@media screen and (max-width: 820px) {
  .events {
    column-gap: 2.5em;
  }
}

@media screen and (max-width: 790px) {
  .events {
    column-gap: 2.15em;
    row-gap: 1.35em;
  }

  .events-card {
    width: 35vmax !important;
  }

  .information_event > p {
    font-size: 0.75em;
  }

  img {
    height: 35vmax;
  }
}

@media screen and (max-width: 725px) {
  .events {
    column-gap: 1.25em;
  }
}

@media screen and (max-width: 625px) {
  .events {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }

  .events-card {
    width: 60vmax !important;
  }

  img {
    height: 45vmax;
  }
}

@media screen and (max-width: 500px) {
  .events-card {
    width: 40vmax !important;
  }

  img {
    height: 35vmax;
  }
}

@media screen and (max-width: 460px) {
  .title {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 370px) {
  .events-card {
    width: 32.5vmax !important;
  }

  img {
    height: 30vmax;
  }

  .title {
    font-size: 1.1em;
  }

  .description {
    font-size: 0.85em;
  }
}
</style>
