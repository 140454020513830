<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
            Abertura de Turma
          </h2>
        </div>
      </vs-row>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div>    
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>          
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Curso <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Curso"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExample"
                    v-model="selectedCourse"                                    
                    placeholder="Selecione um Curso"
                  >
                    <vs-select-item
                      :key="item.CODCURSO"
                      :value="item.NOME"
                      :text="item.NOME"
                      v-for="item in courses"
                    />                                    
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>                                          
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Filial <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Filial"
                  rules="required"
                   
                >
                  <vs-select
                      class="selectExampleDouble"
                      v-model="selectedUnit"
                      v-on:input="getAllRooms()"
                      placeholder="Selecione uma Filial"
                  >
                      <vs-select-item
                        :key="item.CODFILIAL"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="item in units"
                      />
                  </vs-select>
                   
                  </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Sala <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Sala"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExampleDouble"
                    v-model="selectedRoom"
                    placeholder="Selecione uma Sala"
                  >
                    <vs-select-item
                      :key="item.CODIGO"
                      :value="item.DESCRICAO"
                      :text="item.DESCRICAO+ - (item.CAPACIDADEMAXIMA)"
                      v-for="item in rooms"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>                  
          </vs-row>
                    
          <vs-row class="pd-t">         
            <vs-col>
              <fieldset>
                <legend>Dias <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Dias"
                  rules="required"
                   
                >               
                  <div class="boxTurn">                            
                    <span v-for="(weeks, index) in selectedWeek" :key="index">
                      <input type="checkbox" :id="index" class="checkboxTurn" v-model="week" :value="weeks.value">
                      <label :for="index">{{ weeks.value }}</label>
                    </span>                      
                  </div>    
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>              
          </vs-row>                         

          <vs-row class="pd-t">  
            <vs-col>
              <fieldset>
                <legend>Turnos <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Turnos"
                  rules="required"
                   
                >               
                  <div class="boxTurn">                            
                    <span v-for="(shifts, index) in selectedShift" :key="index">
                      <input type="checkbox" :id="index" class="checkboxTurn" v-model="shift" :value="shifts.value">
                      <label :for="index">{{ shifts.value }}</label>
                    </span>                      
                  </div>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>                    
                              
          <vs-row class="pd-t" >
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Data <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data"
                  rules="required"
                   
                >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="date"
                    type="date"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Horário <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Horário"
                  rules="required"
                   
                >                
                  <div class="horario">
                  <vs-input
                    class="input-horario"
                    v-model="time[0]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[1]"
                    v-mask="'##:##'"
                  />
                  <span>e</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[2]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[3]"
                    v-mask="'##:##'"
                  />
                </div>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>          
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Professor <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Professor"
                  rules="required"
                   
                >
                  <vs-select
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample"
                    v-model="selectedTeacher"                                    
                    placeholder="Selecione um Professor"
                  >
                    <vs-select-item
                      :key="item.teacher"
                      :value="item.NOME + ' (' + item.SECAO + ')'"
                      :text="item.NOME + ' (' + item.SECAO + ')'"
                      v-for="item in activeTeachers"
                    />                                    
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>N° de vagas solicitadas <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="N° de vagas solicitadas"
                  rules="required|numeric"
                   
                >
                  <vs-input
                    class="selectExampleDouble"
                    v-model="numberVacancies"
                    placeholder="Digite o N° de vagas"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Justificativa"
                rules="required|max: 500"
              >
                <vs-textarea
                  label="Justificativa"
                  height="210px"
                  rows="11"
                  class="textArea"
                  counter="500"
                  v-model="solicitation"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>               
        </div>
      </ValidationObserver>    
    </div>    
  </section>
</template>

<script>
import EducacionalModel from "../../../../models/educacional/educacional.js";
import { convertDate, getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import Units from "../../../../services/units";
import Teachers from "../../../../services/teachers";

export default {
  name: "AberturaDeTurma",
  data: () => ({
    activeTeachers: null,
    errorActiveTeachers: null,
    selectedTeacher: null,
    selectedUnit: null,
    units: null,
    errorUnits: null,
    numberVacancies: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    selectedCourse: null,
    courses: null,
    errorCourses: null,
    rooms: null,
    errorRooms: null,
    selectedRoom: null,
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
      { text: "Sábado (Todos)", value: "Sábado (Todos)" },
      { text: "Sábado (Alternado)", value: "Sábado (Alternado)" },
    ],
    week: [],
    time: [],
    shift: [],
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    teacher: null,
    date: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
    .getUnits()
    .then(({ data }) => {
      this.units = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.errorUnits = response.data;
        console.log(response)
      }
    })
    .finally(() => {
      this.$vs.loading.close();
    });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
    .getAllCourses()
    .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
        if (response && response.data) {
          this.errorCourses = response.data;
          console.log(response)
        }
    })
    .finally(() => {
        this.$vs.loading.close();
    });

    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
    .getActiveTeachers()
    .then(({ data }) => {
      this.activeTeachers = Array.isArray(data) ? data : [data];
      this.$vs.loading.close();
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.errorActiveTeachers = response.data;
        console.log(response)        
      }
      this.$vs.loading.close();
    });
  },

  methods: {

    inputChange(event) {
      return event.target.value;
    },


    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCURSO: ${this.selectedCourse}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nSALA: ${this.selectedRoom}\nDIAS: ${this.week}\nTURNO: ${this.shift}\nHORÁRIO: ${this.time[0]} ATÉ ${this.time[1]} E DAS ${this.time[2]} ATÉ ${this.time[3]}\nDATA: ${convertDate(this.date)}\nNÚMERO DE VAGAS SOLICITADAS: ${this.numberVacancies}\nPROFESSOR: ${this.selectedTeacher}\nJustificativa: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
        this.selectedUnit = null;
        this.numberVacancies = null;
        this.solicitation = null;
        this.idAttendance = null;
        this.errorIdAttendance = null;
        this.selectedCourse = null;
        this.errorCourses = null;
        this.selectedRoom = null;   
        this.week = [];
        this.time = [];
        this.shift = [];
        this.rooms = [];
        this.selectedTeacher = null,
        this.date = null,

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    getAllRooms() {
      new Units()
      .getAllRooms(this.selectedUnit)
      .then(({ data }) => {
        this.rooms = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorRooms = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },
  },
};
</script>

<style scoped>
.input-horario {
  width: 5.6em;  
}

@media screen and (max-width: 650px) {  
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {  
  .checkboxTurn {
    width: 2.6em;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 400px) {  
  .checkboxTurn {
    font-size: .9em;
  }
}
</style>
