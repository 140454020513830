<template>
  <section>
      <ValidationObserver ref="observer2" v-slot="{ invalid }">
        <div class="subContainer">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'handshake']" />
              <h2 class="tituloAtendimento">
                NETEP - Aluno QI
              </h2>
            </div>
          </vs-row>
        <vs-row class="pd-t">
          <vs-col>
            <fieldset class="search">
              <legend>RA do Aluno <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="RA"
                rules="required|numeric"
                
              >
                <vs-input
                  @keyup.enter="getCoursesByRA()" 
                  v-model="ra"
                  placeholder="Digite o RA do Aluno"
                  class="selectExampleRA"
                >
                </vs-input>
                
              </ValidationProvider>
              <vs-button
                color="primary"
                class="buscar"
                type="filled"
                :disabled="invalid"
                @click="getCoursesByRA()"
              >
                <font-awesome-icon :icon="['fas', 'search']" />
                <span> Buscar </span></vs-button
              >
            </fieldset>              
          </vs-col>
        </vs-row>          

  <div>
    <vs-row class="pt-d">
      <vs-col>
        <Message class="pd-t"
          :msg="idAttendance"
          :error="errorIdAttendance"
          v-if="idAttendance || errorIdAttendance"          
        />
      </vs-col>
    </vs-row>
  </div>
       
    <div   v-if="responseRa != null">
      <div v-if="responseRa == ''">
        <p class="validacao">RA Inválido</p>
      </div>
      <div v-else>
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Nome do Aluno</legend>
              <vs-input
                class="selectExampleDouble"
                :placeholder="responseRa[0].NOMEALUNO"
                disabled="disabled"
              />
            </fieldset>                    
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Telefone <b style="color: red;">*</b></legend>
              <ValidationProvider
                  name="Telefone"
                  rules="required|min:15"
                  
              >                        
                <vs-input
                    class="selectExampleDouble"
                    placeholder="Digite o telefone"
                    v-model="phone"
                    v-mask="'(##) #####-####'"
                />                        
                  
              </ValidationProvider>
            </fieldset>                  
          </vs-col>
        </vs-row>                

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Curso <b style="color: red;">*</b></legend>
              <ValidationProvider
                name="Curso"
                rules="required"
                
              >
                <vs-select
                  class="selectExampleDouble"
                  v-model="course"
                  placeholder="Selecione um Curso"
                  @change="searchByCodCurso()"
                >
                  <vs-select-item
                    v-for="(i, index) in responseRa"
                    :key="index"
                    :value="i.CODCURSO"
                    :text="i.CURSO"
                  />
                </vs-select>
                
              </ValidationProvider>
            </fieldset>                  
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Filial/Polo</legend>
              <ValidationProvider
                name="Filial/Polo"
                rules="required"
                
              >
                <vs-input
                  class="selectExampleDouble"
                  :placeholder="finalFilialPolo"     
                  disabled="disabled"            
                />
                
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Anexo (Opcional)</legend>
              <ValidationProvider
                name="Anexo"
                ref="provider"
                  
              >
                <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                  
              </ValidationProvider>
            </fieldset>
          </vs-col> 
        </vs-row>
    
        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider
                name="Solicitação"
                rules="required|max: 500"
                
            >
                <vs-textarea
                    rows="11"
                    label="Justificativa *"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="solicitation"
                />
                
            </ValidationProvider>
          </vs-col>          
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center;">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>
            
            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>            
        </vs-row>
      </div>
    </div>
  </div>
  </ValidationObserver>
  </section>
</template>

<script>
  import Students from "../../../../services/students";
  import Attendance from "../../../../services/attendance";
  import AdministrativoModel from "../../../../models/administrativo/administrativo";
  import {
  getLoginDataFromStorage
} from "../../../../helpers/storage.js";
  
  export default {
      name: "Aluno",
    data: () => ({
      ra: null,
      files: "",
      base64Image: "",
      responseRa: null,
      errorResponseRa: null,
      idAttendance: null,
      errorIdAttendance: null,
      phone: null,
      solicitation: null,
      course: null,
      finalNameCourse: null,
      finalFilialPolo: null,
    }),
  
    methods: {

      async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

      getCoursesByRA() {
        this.$vs.loading({ text: "Carregando..." });
        new Students()
          .getCancelDataByRA(this.ra)
          .then(({ data }) => {
            this.responseRa = Array.isArray(data) ? data : [data];
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.errorResponseRa = response.data;
              console.log(response)
            }
            this.$vs.loading.close();
          });
      },
    
      async createAttendance() {
        this.idAttendance = null;
        this.errorIdAttendance = null;
        const { ...userProfile } = getLoginDataFromStorage();
  
        const personId = userProfile.userData.CODIGO;
        const personLocal = userProfile.userData.CODLOCAL;
        const personName = userProfile.userData.NOME;
        const attendantId = userProfile.userData.CODATENDENTE;
  
        const studentName = this.responseRa[0].NOMEALUNO;
  
        const payload = new AdministrativoModel().getDataByAttendanceId('A15-1');
  
        payload.data.locationId = personLocal.toString();
        payload.data.ra = this.ra;
        payload.data.attachment = this.base64Image;
        payload.data.fileName = this.files.name;
        payload.data.attendantId = attendantId.toString();
        payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA DO ALUNO: ${this.ra}\nALUNO: ${studentName}\nCURSO: ${this.finalNameCourse}\nFILIAL/POLO: ${this.finalFilialPolo}\nTELEFONE: ${this.phone}\nSOLICITAÇÃO: ${this.solicitation}`;
  
        this.$vs.loading({ text: "Carregando..." });
        new Attendance()
          .createAttendance(payload.data)
          .then(({ data }) => {
            this.idAttendance = data;
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.errorIdAttendance = response.data;
              console.log(response)
            }
            this.$vs.loading.close();
          });
        this.resetForm();
      },
  
      searchByCodCurso() {
        const search = (key, inputArray) => {
          for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i].CODCURSO === key) {
              return inputArray[i];
            }
          }
        };
        let result = search(this.course, this.responseRa);
        let finalNameCourse = result.CURSO;
        let finalFilialPolo = result.POLO == "[object Object]" ? result.FILIAL : result.POLO;
  
        this.finalNameCourse = finalNameCourse;
        this.finalFilialPolo = finalFilialPolo;
      },
  
      resetForm() {
        this.course = null;
        this.phone = null;
        this.solicitation = null;
        this.responseRa = null;  
        this.ra = null;
        this.finalNameCourse = null;
        this.finalFilialPolo = null;
        this.files = null;
        this.base64Image = null;
  
        this.$nextTick(() => {
          this.$refs.observer.reset();
          this.$refs.observer2.reset();
        });
      },
    },
  };
  </script>
  
  <style scoped>
  
  @media screen and (max-width: 480px) {
    .buscar span {
      display: none;
    }
    .buscar {
      width: 3em;
      margin-left: 0.7em;
    }
  }
  </style>
  