var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('vs-row',{staticClass:"pd-table"},[_c('vs-col',[(this.codAtendente == '0' || this.callsInLine == '')?_c('fieldset',[_c('p',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle']}}),_vm._v(" Nenhum atendimento encontrado ")],1)]):_c('vs-table',{staticClass:"table_intranet",attrs:{"data":_vm.callsInLine,"hoverFlat":"","search":"","pagination":"","max-items":"10"},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('vs-popup',{staticStyle:{"z-index":"999999 !important"},attrs:{"title":data[_vm.idPopup] ? 'Atendimento - ' + data[_vm.idPopup].CODATENDIMENTO : 'Atendimento',"active":_vm.popupAtivo},on:{"update:active":function($event){_vm.popupAtivo=$event}}},[_c('vs-col',[_c('vs-tabs',{attrs:{"alignment":"center"}},[_c('vs-tab',{attrs:{"icon":"library_add","label":"Solicitação"},on:{"click":function($event){_vm.type='solicitation'}}}),_c('vs-tab',{attrs:{"icon":"group","label":"Discussão"},on:{"click":function($event){_vm.type='discussion'}}}),_c('vs-tab',{attrs:{"icon":"check_circle","label":"Solução"},on:{"click":function($event){_vm.type='solution'}}})],1)],1),(_vm.type == 'solicitation')?_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                    data[_vm.idPopup] ? 
                    _vm.parseNewLine(data[_vm.idPopup].SOLICITACAO) : 
                    ''
                  )}})]):_vm._e(),(_vm.type == 'discussion')?_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                    data[_vm.idPopup] ? 
                    _vm.parseNewLine(data[_vm.idPopup].DISCUSSAO) : 
                    ''
                  )}})]):_vm._e(),(_vm.type == 'solution')?_c('fieldset',[_c('p',{domProps:{"innerHTML":_vm._s(
                    data[_vm.idPopup] ? 
                    _vm.parseNewLine(data[_vm.idPopup].SOLUCAO) : 
                    ''
                  )}})]):_vm._e()],1),_vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleClickDetails(indextr)}}},[_vm._v(_vm._s(data[indextr].CODATENDIMENTO))])]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].DATA_ABERTURA)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].ASSUNTO)+" ")]),_c('vs-td',{staticClass:"classificacao"},[_vm._v(" "+_vm._s(data[indextr].CLASSIFICACAO)+" ")])],1)})]}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" ATENDIMENTO ")]),_c('vs-th',[_vm._v(" DATA ABERTURA ")]),_c('vs-th',[_vm._v(" ASSUNTO ")]),_c('vs-th',[_vm._v(" CLASSIFICAÇÃO ")])],1)],2)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }