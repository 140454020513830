<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div class="subContainer">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'address-card']" />
            <h2 class="tituloAtendimento">
              NETEP - Solicitação Interna
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
        </div>

        <div>          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Filial Desejada <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Filial"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExampleDouble"
                    placeholder="Selecione uma Filial"
                    v-model="selectedUnit"
                  >
                    <vs-select-item
                      :key="item.CODFILIAL"
                      :value="item.POLO"
                      :text="item.POLO"
                      v-for="item in units"
                    />
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Anexo (Opcional)</legend>
                <ValidationProvider
                  name="Anexo"
                  ref="provider"
                    
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                    
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Texto Solicitação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  rows="11"
                  label="Texto Solicitação *"
                  class="textArea"
                  height="15em"
                  counter="500"
                  v-model="solicitationStudent"
                />
                 
              </ValidationProvider>
            </vs-col>
          </vs-row>                                                       

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
        </div>
      </div>
    </ValidationObserver>    
  </section>
</template>

<script>
  import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import { getLoginDataFromStorage } from "../../../../helpers/storage.js";
import AdministrativoModel from "../../../../models/administrativo/administrativo";

  
  export default {
      name: "SolicitacaoInterna",
      data: () => ({
        units: null,
        selectedUnit: null,
        files: "",
        base64Image: "",
        valueStudent: null,
        solicitationStudent: null,
        counterDanger: false,
        idAttendance: null,
        errorIdAttendance: null,
        visibleButton: false,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnit = response.data;
          console.log(response);
        }
      });
  },

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A15-3');

      payload.data.locationId = personLocalId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nFILIAL DESEJADA: ${this.selectedUnit} \nSOLICITAÇÃO: ${this.solicitationStudent}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.valueStudent = null;
      this.solicitationStudent = null;  
      this.selectedUnit = null;    
      this.visibleButton = false;
      this.files = null,
      this.base64Image = null,


      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },    
  },  
};
</script>
