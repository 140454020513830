<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
            Solicitação de Redução de Carga Horária
          </h2>
        </div>
      </vs-row>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div>    
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>          
          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                    <legend>Filial <b style="color: red;">*</b></legend>
                    <ValidationProvider
                    name="Filial"
                    rules="required"
                     
                    >
                    <vs-select
                        class="selectExampleDouble"
                        v-model="selectedUnit"
                        placeholder="Selecione uma Filial"
                    >
                        <vs-select-item
                            :key="item.CODFILIAL"
                            :value="item.CODFILIAL"
                            :text="item.POLO"
                            v-for="item in units"
                        />
                    </vs-select>
                     
                    </ValidationProvider>
                </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Curso <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Curso"
                  rules="required"
                   
                >
                  <vs-select
                    class="selectExampleDouble"
                    v-model="selectedCourse"                                    
                    placeholder="Selecione um Curso"
                  >
                    <vs-select-item
                      :key="item.CODCURSO"
                      :value="item.NOME"
                      :text="item.NOME"
                      v-for="item in courses"
                    />                                    
                  </vs-select>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>                                          
          </vs-row>          
                    
          <vs-row class="pd-t">         
            <vs-col>
              <fieldset>
                <legend>Dias <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Dias"
                  rules="required"
                   
                >               
                  <div class="boxTurn">                            
                    <span v-for="(weeks, index) in selectedWeek" :key="index">
                      <input type="checkbox" :id="index" class="checkboxTurn" v-model="week" :value="weeks.value">{{ weeks.value }}
                    </span>                      
                  </div>    
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>              
          </vs-row>                         

          <vs-row class="pd-t">  
            <vs-col>
              <fieldset>
                <legend>Turnos <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Turnos"
                  rules="required"
                   
                >               
                  <div class="boxTurn">                            
                    <span v-for="(shifts, index) in selectedShift" :key="index">
                      <input type="checkbox" :id="index" class="checkboxTurn" v-model="shift" :value="shifts.value">{{ shifts.value }}
                    </span>                      
                  </div>
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>                    
                              
          <vs-row class="pd-t" >
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Data do Inicio da Redução <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Data do Inicio da Redução"
                  rules="required"
                   
                >
                  <vs-input
                      class="selectExampleDouble"
                      v-model="date"
                      type="date"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Horas Reduzidas <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Horas Reduzidas"
                  rules="required"
                   
                >                
                  <vs-input
                    class="selectExampleDouble"
                    v-model="reducedHours"
                    placeholder="Digite as Horas Reduzidas(Semanal)"
                  />
                   
                </ValidationProvider>
              </fieldset>
            </vs-col>          
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Anexo <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Anexo"
                  ref="provider"
                  rules="required"
                   
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                   
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Observação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  label="Observação"
                  height="210px"
                  rows="11"
                  class="textArea"
                  counter="500"
                  v-model="solicitation"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>               
        </div>
      </ValidationObserver>
    </div>    
  </section>
</template>

<script>
import EducacionalModel from "../../../../models/educacional/educacional.js";
import { convertDate, getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import Units from "../../../../services/units";

export default {
  name: "SolicitacaoDeReducaoDeCargaHoraria",
  data: () => ({
    files: null,
    base64Image: null,
    selectedUnit: null,
    units: null,
    errorUnits: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    selectedCourse: null,
    courses: null,
    errorCourses: null,
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
      { text: "Sábado (Todos)", value: "Sábado (Todos)" },
      { text: "Sábado (Alternado)", value: "Sábado (Alternado)" },
    ],
    week: [],
    reducedHours: null,
    shift: [],
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    date: null,
    visibleButton: false,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorUnits = response.data;
          console.log(response)
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Carregando..." });
    new Units()
    .getAllCourses()
    .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
    })
    .catch(({ response }) => {
        if (response && response.data) {
          this.errorCourses = response.data;
          console.log(response)
        }
    })
    .finally(() => {
        this.$vs.loading.close();
    });
  },

  methods: {

    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1-11");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCURSO: ${this.selectedCourse}\nFILIAL: ${poloTextFormat(this.selectedUnit, this.units)}\nDIAS: ${this.week}\nTURNO: ${this.shift}\nHORAS REDUZIDAS: ${this.reducedHours}\nDATA DE INICIO DA REDUÇÃO: ${convertDate(this.date)}\nOBSERVAÇÃO: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
        this.selectedUnit = null;
        this.solicitation = null;
        this.idAttendance = null;
        this.errorIdAttendance = null;
        this.selectedCourse = null;
        this.errorCourses = null;   
        this.week = [];
        this.hora = [];
        this.shift = [];
        this.date = null;
        this.reducedHours = null;
        this.visibleButton = false;
        this.$refs.image.value=null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    
  },
};
</script>

<style scoped>

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {  
  .checkboxTurn {
    width: 2.6em;
  }
}

@media screen and (max-width: 400px) {
  .checkboxTurn {
    font-size: .9em;
  }
}
</style>
