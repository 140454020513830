<template>
  <div>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'cogs']" />
              <h2 class="tituloAtendimento">
                 Planejamento Acadêmico
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"                    
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in options"
                  />
                </vs-select>
              </fieldset>                  
            </vs-col>
          </vs-row>      
        </div>

        <AberturaDeTurma v-if="typeSelected == 1" />
        <AdiamentoDeTurma v-if="typeSelected == 2" />    
        <AjusteDeHorarioDiasDaSemana v-if="typeSelected == 3" />
        <AjusteDeVagas v-if="typeSelected == 4" />
        <AjusteDeProfessorInstrutor v-if="typeSelected == 5" />
        <ComunicacaoDeSubstituicaoDeProfessorInstrutor v-if="typeSelected == 6" />
        <CriacaoDeCursoNovo v-if="typeSelected == 8" />
        <FechamentoDeTurma v-if="typeSelected == 9" />
        <SolicitacaoDeReducaoDeCargaHoraria v-if="typeSelected == 10" />
        <SolicitacaoDeSequenciaDeTurma v-if="typeSelected == 11" />
        <TrocaDeSala v-if="typeSelected == 12" />
      </div>
    </div>        
  </div>
</template>

<script>
import AjusteDeVagas from "./FormPlanejamentoAcademico/AjusteDeVagas.vue";
import AberturaDeTurma from "./FormPlanejamentoAcademico/AberturaDeTurma.vue";
import AdiamentoDeTurma from "./FormPlanejamentoAcademico/AdiamentoDeTurma.vue";
import AjusteDeHorarioDiasDaSemana from "./FormPlanejamentoAcademico/AjusteDeHorarioDiasDaSemana.vue";
import AjusteDeProfessorInstrutor from "./FormPlanejamentoAcademico/AjusteDeProfessorInstrutor.vue";
import CriacaoDeCursoNovo from "./FormPlanejamentoAcademico/CriacaoDeCursoNovo.vue";
import FechamentoDeTurma from "./FormPlanejamentoAcademico/FechamentoDeTurma.vue";
import TrocaDeSala from "./FormPlanejamentoAcademico/TrocaDeSala.vue";
import SolicitacaoDeSequenciaDeTurma from "./FormPlanejamentoAcademico/SolicitacaoDeSequenciaDeTurma.vue";
import SolicitacaoDeReducaoDeCargaHoraria from "./FormPlanejamentoAcademico/SolicitacaoDeReducaoDeCargaHoraria.vue";
import ComunicacaoDeSubstituicaoDeProfessorInstrutor from "./FormPlanejamentoAcademico/ComunicacaoDeSubstituicaoDeProfessorInstrutor";
export default {
  name: "AjusteDiarioDeClasse",
  components: {
    AberturaDeTurma,
    AjusteDeVagas,
    AdiamentoDeTurma,
    AjusteDeHorarioDiasDaSemana,
    AjusteDeProfessorInstrutor,
    CriacaoDeCursoNovo,
    FechamentoDeTurma,
    TrocaDeSala,
    SolicitacaoDeSequenciaDeTurma,
    SolicitacaoDeReducaoDeCargaHoraria,
    ComunicacaoDeSubstituicaoDeProfessorInstrutor,
  },
  data: () => ({
    typeSelected: "",
    options: [
      { text: "Abertura de Turma", value: 1 },
      { text: "Adiamento de Turma", value: 2 },
      { text: "Ajuste de Horário/dias da semana", value: 3 },
      { text: "Ajuste de Vagas", value: 4 },
      { text: "Alteração de Professor/Instrutor", value: 5 },
      { text: "Comunicação de Substituição de Professor/Instrutor", value: 6 },
      { text: "Criação de Curso Novo", value: 8 },
      { text: "Fechamento de Turma", value: 9 },
      { text: "Solicitação de Redução de Carga Horária", value: 10 },
      { text: "Solicitação de Sequência de Turma", value: 11 },
      { text: "Troca de Sala", value: 12 },
    ],
  }),
};
</script>