<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'user-plus']" />
            <h2 class="tituloAtendimento">            
               Criação ou Comunicação
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Principais Objetivos da peça"
                  rules="required">            
                  <fieldset>
                    <legend>Principal Objetivo da Peça: <b style="color: red;">*</b></legend>
                    <div class="boxTurn">
                      <input type="radio" v-model="playObjectives" id="Informar" name="playObjectives" value="Informar" class="radioButton" />
                      <label for="Informar">Informar</label>
                      <input type="radio" v-model="playObjectives" name="playObjectives" value="Vender" class="radioButton"/>Vender
                      <input type="radio" v-model="playObjectives" name="playObjectives" value="Dar suporte" class="radioButton"/>Dar suporte                            
                    </div>                  
                     
                  </fieldset>
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Tipo de Peça"
                  rules="required">
                  <fieldset>
                    <legend>Tipo de Peça: <b style="color: red;">*</b></legend>
                    <div class="boxTurn">
                      <input type="radio" v-model="playType" name="playType" value="Puramente Promocional" class="radioButton" />Puramente Promocional
                      <input type="radio" v-model="playType" name="playType" value="Coletor de Informações" class="radioButton"/>Coletor de Informações
                      <input type="radio" v-model="playType" name="playType" value="Uma Publicação" class="radioButton"/>Uma Publicação
                    </div>                  
                     
                  </fieldset>                
                </ValidationProvider>
              </vs-col>                                                        
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Público que Pretende ser Atingido"
                  rules="required">
                  <fieldset>
                    <legend>Público que Pretende ser Atingido: <b style="color: red;">*</b></legend>
                    <vs-input v-model="targetAudience" placeholder="Digite o Publico a ser Atingindo" class="selectExample"/>
                     
                  </fieldset>                            
                </ValidationProvider>
              </vs-col>                     
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Data aproximada para o material estar pronto"
                  rules="required">
                  <fieldset>
                    <legend>Data aproximada para o material estar pronto: <b style="color: red;">*</b></legend>
                    <vs-input type="date" v-model="readyMaterialDate" class="selectExample"/>
                     
                  </fieldset>       
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Resultado do Material"
                  rules="required">
                  <fieldset>
                    <legend>Resultado do Material: <b style="color: red;">*</b></legend>
                    <div class="boxTurn">
                      <input type="radio" v-model="materialResult" name="materialResult" value="Digital" class="radioButton" />Digital
                      <input type="radio" v-model="materialResult" name="materialResult" value="Impresso" class="radioButton"/>Impresso
                      <input type="radio" v-model="materialResult" name="materialResult" value="Digital e Impresso" class="radioButton" />Digital e Impresso              
                    </div>                  
                     
                  </fieldset>                                                                   
                </ValidationProvider>
              </vs-col>            
            </vs-row>

            <vs-row class="pd-t" v-if="materialResult == 'Impresso' || materialResult == 'Digital e Impresso'">
              <vs-col>
                <ValidationProvider
                name="Quantidade de Impressões"
                rules="required"
                 
              >
                <fieldset>
                  <legend>Quantidade de Impressões: <b style="color: red;">*</b></legend>
                  <vs-input type="number" v-model="numberOfImpression" class="selectExample" placeholder="Digite a Qunatidade de Impressões"/>
                   
                </fieldset>
              </ValidationProvider>              
              </vs-col>            
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Responsável pelo contato para eventuais esclarecimentos sobre o projeto ou envio de materiais: <b style="color: red;">*</b></legend>            
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <ValidationProvider
                        name="Nome"
                        rules="required"
                         
                      >
                        <vs-input v-model="name" label="Nome:" placeholder="Digite o Nome" class="selectExampleDouble"/>
                         
                      </ValidationProvider>
                    </vs-col>
                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <ValidationProvider
                        name="Ramal"
                        rules="required"
                         
                      >
                        <vs-input v-model="ramal" label="Ramal:" placeholder="Digite o Ramal" class="selectExampleDouble"/>
                         
                      </ValidationProvider> 
                    </vs-col>                  
                </fieldset>            
              </vs-col>                        
            </vs-row>          

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Mensagens mais importantes que a peça deve passar aos consumidores"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    label="Mensagens mais importantes que a peça deve passar aos consumidores:"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="messagePlay"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>
                
            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row> 
          </ValidationObserver>
        </div>        
      </div>
    </div>
  </section>
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage, convertDate } from "../../../helpers/storage.js";
import MarketingModel from "../../../models/marketing/marketing.js";
export default {
  name: "CriacaoComunicacao",
  data: () => ({
    playObjectives: null,
    playType: null,
    targetAudience: null,
    readyMaterialDate: null,
    materialResult: null,
    numberOfImpression: null,
    name: null,
    ramal:null,
    messagePlay: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  methods: {

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;  
    
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new MarketingModel().getDataByAttendanceId('M1');

      payload.data.locationId = personLocalId.toString();
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();

      let verifyMeterial = this.materialResult == 'Impresso' || this.materialResult == 'Digital e Impresso' ? "\nQUANTIDADE DE IMPRESSÕES: "+this.numberOfImpression : "";

      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nPRINCIPAl OBJETIVO DA PEÇA: ${this.playObjectives}\nTIPO DE PEÇA: ${this.playType}\nPÚBLICO QUE PRETENDE SER ATINGIDO: ${this.targetAudience}\nDATA APROXIMADA PARA QUANDO O MATERIAL TENHA QUE ESTAR PRONTO: ${convertDate(this.readyMaterialDate)}\nRESULTADO DO MATERIAL: ${this.materialResult}${verifyMeterial}\nREPONSÁVEL PELO CONTATO PARA EVENTUAIS ESCLARECIMENTOS SOBRE O PROJETO OU ENVIO DE MATERIAIS:\nNOME: ${this.name}\nRAMAL: ${this.ramal}\nMENSAGENS MAIS IMPORTANTES QUE A PEÇA DEVE PASSAR AOS CONSUMIDORES: ${this.messagePlay}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
      },

    resetForm() {
      this.playObjectives = null;
      this.playType = null;
      this.targetAudience = null;
      this.readyMaterialDate = null;
      this.materialResult = null;
      this.numberOfImpression = null;
      this.name = null;
      this.ramal = null;
      this.messagePlay = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    }
  }
}
</script>

<style scoped>
.boxTurn {
  align-items: center;
}

@media (max-width: 650px) {
  .boxTurn {
    flex-direction: column;
  }
}
</style>
