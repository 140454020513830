<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'wrench']" />
              <h2 class="tituloAtendimento">
                Agendamento de Manutenção
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.value"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>
              </fieldset>                                  
            </vs-col>  
          </vs-row>
        </div>

        <AgendaInfra v-if="this.selectedTypes == 1" />
        <AgendaPredial v-if="this.selectedTypes == 2" />
   
      </div>      
    </div>            
  </section>
</template>

<script>
import AgendaInfra from "./FormManutencao/AgendaInfra.vue"
import AgendaPredial from "./FormManutencao/AgendaPredial.vue"

export default {
  name: "AgendamentoManutencao",
  components: {
    AgendaInfra,
    AgendaPredial,
  },
  data: () => ({
    types: [
      { text: "Agendamento de Manutenção Preventiva - Infraestrutura/Telefonia", value: 1 },
      { text: "Agendamento de Manutenção Preventiva - Predial", value: 2},
    ],
    selectedTypes: null,
  }),
};
</script>
