import { https } from './api'

export default class {
    constructor() {
        this.instance = https('attendance')
    }

    createAttendance = (data) => {
        return this.instance({
            method: 'POST',
            url:'/',            
            data,
        })
    }

    updateDiscussion = (data) => {
        return this.instance({
          method: "PUT",
          url: "/discussion",
          data
        });
    };

    updateSolution = (data) => {
        return this.instance({
          method: "PUT",
          url: "/solution",
          data
        });
    };
}