<template>
    <section>        
        <div class="subContainer">                        
            <vs-row class="topo">
                <div class="titulo">
                    <font-awesome-icon :icon="['fas', 'handshake']" />
                    <h2 class="tituloAtendimento">
                         Devolução
                    </h2>
                </div>
            </vs-row>
            
            <div>
                <vs-row class="pt-d">
                    <vs-col>
                    <Message class="pd-t"
                        :msg="idAttendance"
                        :error="errorIdAttendance"
                        v-if="idAttendance || errorIdAttendance"          
                    />
                    </vs-col>
                </vs-row>
            </div>

            <div>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                    <vs-row class="pd-t">
                        <vs-col>
                            <fieldset class="search">
                                <legend>RA do Aluno <b style="color: red;">*</b></legend>
                                <ValidationProvider
                                    name="RA"
                                    rules="required|numeric"
                                     
                                >                                            
                                    <vs-input
                                        @keyup.enter="getAgreementByRa()" 
                                        v-model="valueStudent"
                                        placeholder="Digite o RA do Aluno"
                                        class="selectExampleRA"
                                    />                                            
                                     
                                </ValidationProvider>
                                <vs-button
                                    color="primary"
                                    class="buscar"
                                    type="filled"
                                    :disabled="invalid"
                                    @click="getAgreementByRa()">
                                    <font-awesome-icon :icon="['fas', 'search']"/>
                                <span> Buscar </span></vs-button>                                        
                            </fieldset>                                    
                        </vs-col>
                    </vs-row>                            
                </ValidationObserver>                        

                <ValidationObserver ref="observer">
                    <div v-if="agreementRa != null">
                        <div v-if="agreementRa ==''">
                            <p class="validacao">RA Inválido</p>            
                        </div>
                        <div v-else>                            
                            <ValidationObserver ref="observer2" v-slot="{ invalid }">
                                <vs-row class="pd-t">
                                    <vs-col>
                                        <fieldset class="search">
                                            <legend>Cod. Atendimento da Venda <b style="color: red;">*</b></legend>
                                            <ValidationProvider
                                                name="Cod. Atendimento da Venda"
                                                rules="required|numeric"
                                                 
                                            >                                            
                                                <vs-input
                                                    @keyup.enter="getSalesServiceCodeStudent()" 
                                                    v-model="inputSalesServiceCodeStudent"
                                                    placeholder="Digite o Cod. Atendimento da Venda"
                                                    class="selectExampleRA"
                                                />                                            
                                                 
                                            </ValidationProvider>
                                            <vs-button
                                                color="primary"
                                                class="buscar"
                                                type="filled"
                                                :disabled="invalid"
                                                @click="getSalesServiceCodeStudent()"
                                                >
                                                <font-awesome-icon :icon="['fas', 'search']"/>
                                            <span> Buscar </span></vs-button>                                        
                                        </fieldset>                                    
                                    </vs-col>
                                </vs-row>                            
                            </ValidationObserver>
                        </div>
                    </div>
                </ValidationObserver>

                <ValidationObserver ref="observer" v-slot="{ invalid }">
                    <div v-if="salesServiceCodeStudent != null">
                        <div v-if="salesServiceCodeStudent ==''">
                            <p class="validacao">Cod. Atendimento da Venda Inválido</p>            
                        </div>
                        <div v-else>
                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Filial que Matriculou <b style="color: red;">*</b></legend>
                                        <vs-input
                                            class="selectExampleDouble"
                                            disabled
                                            v-model="salesServiceCodeStudent[0].FilialQueMatriculou"
                                        />
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>ID Prematricula <b style="color: red;">*</b></legend>
                                        <vs-input
                                            class="selectExampleDouble"
                                            disabled
                                            v-model="salesServiceCodeStudent[0].IdPrematricula"                                                    
                                        />
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Usuário do Vendedor <b style="color: red;">*</b></legend>
                                        <vs-input
                                            class="selectExampleDouble"
                                            disabled
                                            v-model="salesServiceCodeStudent[0].Vendedor"                                                    
                                        />
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Contrato <b style="color: red;">*</b></legend>
                                        <vs-input
                                            class="selectExampleDouble"
                                            disabled
                                            v-model="salesServiceCodeStudent[0].Contrato"
                                        />
                                    </fieldset>                                            
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col>
                                    <fieldset>
                                        <legend>Tipo de Matricula <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Tipo de Matricula"
                                            rules="required"
                                             
                                        >
                                            <div class="boxTurn">
                                                <input type="radio" v-model="enrollmentTypeStudent" id="Presencial" name="enrollmentTypeStudent" value="Presencial" class="radioButton" />
                                                <label for="Presencial">Presencial</label>
                                                <input type="radio" v-model="enrollmentTypeStudent" id="Online" name="enrollmentTypeStudent" value="Online" class="radioButton" />
                                                <label for="Online">Online</label>                                                        
                                            </div>
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>                                        
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col>
                                    <fieldset>
                                        <legend>Comprovante de Advertência Aplicada <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Anexo"
                                            ref="provider"
                                             
                                        >
                                            <input class="selectExample" type="file" @change="onFileUploadStudent">
                                             
                                        </ValidationProvider>                                
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">                                        
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Filial <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Filial"
                                            rules="required"
                                                
                                        >
                                            <vs-select
                                                class="selectExampleDouble"
                                                v-model="selectedUnitStudent"
                                                placeholder="Selecione uma Filial"
                                            >
                                                <vs-select-item
                                                    :key="index"
                                                    :text="item.POLO"
                                                    :value="item.CODFILIAL"
                                                    v-for="(item, index) in units"
                                                />
                                            </vs-select>                                        
                                             
                                        </ValidationProvider>
                                    </fieldset>                                        
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Dados Bancários <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Dados Bancários"
                                            rules="required"
                                                
                                        >
                                            <vs-select
                                                class="selectExampleDouble"
                                                v-model="selectOperationTypeStudent"
                                                placeholder="Selecione uma Operação"
                                            >
                                                <vs-select-item
                                                    :key="index"
                                                    :text="item.value"
                                                    :value="item.text"
                                                    v-for="(item, index) in operationType"
                                                />
                                            </vs-select>
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Banco <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Banco"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="bankStudent"
                                                placeholder="Digite o Banco"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Agência <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Agência"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="agencyStudent"
                                                placeholder="Digite a Agência"
                                                v-mask="'####'"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Conta <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Conta"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="accountStudent"
                                                placeholder="Digite a Conta"
                                                v-mask="'########-#'"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>Nome Completo do Titular da Conta <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Nome Completo do Titular da Conta"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="ownerAccountStudent"
                                                placeholder="Digite o Nome Completo"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col>
                                    <legend class="pd-r">DEVOLUÇÃO NA CONTA DE TERCEIROS, ANEXAR TERMO DE AUTORIZAÇÃO A PRÓPRIO PUNHO, FEITO PELO RESPONSÁVEL FINANCEIRO. <b style="color: red;">*</b></legend>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col>
                                    <fieldset>
                                        <legend>Tipo de Chave PIX <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="Tipo de Chave PIX"
                                            rules="required"
                                                
                                        >
                                            <vs-select
                                                class="selectExample"
                                                v-model="selectTypePIXKeyStudent"
                                                placeholder="Selecione um Tipo de Chave PIX"
                                            >
                                                <vs-select-item
                                                    :key="item.types"
                                                    :value="item.value"
                                                    :text="item.text"
                                                    v-for="item in typePIXKey"
                                                />
                                            </vs-select>
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>
                            </vs-row>

                            <vs-row class="pd-t">
                                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>PIX do Responsável Financeiro <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="PIX do Responsável Financeiro"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="pixKeyStudent"
                                                placeholder="Digite o PIX"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>

                                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                                    <fieldset>
                                        <legend>CPF <b style="color: red;">*</b></legend>
                                        <ValidationProvider
                                            name="CPF"
                                            rules="required"
                                                
                                        >
                                            <vs-input
                                                class="selectExampleDouble"
                                                v-model="cpfStudent"
                                                placeholder="Digite o CPF"
                                                v-mask="'###.###.###-##'"
                                            />
                                             
                                        </ValidationProvider>
                                    </fieldset>
                                </vs-col>
                            </vs-row>                               

                            <vs-row class="pd-t">
                                <vs-col>
                                    <ValidationProvider
                                        name="Texto Solicitação"
                                        rules="required|max: 500"
                                         
                                    >
                                    <vs-textarea
                                        rows="11"
                                        label="Texto Solicitação *"
                                        class="textArea"
                                        height="15em"
                                        counter="500"
                                        v-model="solicitationStudent"
                                    />
                                     
                                    </ValidationProvider>
                                </vs-col>
                            </vs-row>                                                       

                            <vs-row class="pd-t">
                                <vs-col style="text-align: center;">
                                    <vs-button
                                        color="danger"
                                        type="gradient"
                                        class="button-protocol"
                                        @click="resetForm()"
                                    >
                                        <font-awesome-icon :icon="['fas', 'times-circle']" />
                                        Cancelar
                                    </vs-button>
                                    
                                    <vs-button
                                        class="button-protocol"
                                        color="success"
                                        type="gradient"
                                        :disabled="invalid"
                                        @click="urgence = true"
                                    >
                                        <font-awesome-icon :icon="['fas', 'check']" />
                                        Gerar
                                    </vs-button>
                                </vs-col>            
                            </vs-row> 

                            <vs-popup title="Prioridade do Atendimento" :active.sync="urgence">
                                <vs-col>
                                    <vs-button
                                        style="width: 47.5%;margin: 0 1.25%;"
                                        type="gradient"
                                        @click="createAttendance(10000)"
                                    >
                                        Urgente
                                    </vs-button>

                                    <vs-button
                                        style="width: 47.5%;margin: 0 1.25%;"
                                        type="gradient"
                                        @click="createAttendance(100)"
                                    >
                                        Normal
                                    </vs-button>
                                </vs-col>
                            </vs-popup> 
                        </div>
                    </div>    
                </ValidationObserver>                                                      
            </div>
        </div>
    </section>
</template>

<script>
import Management from "../../../../services/management";
import Units from "../../../../services/units";
import { getLoginDataFromStorage, poloTextFormat } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
export default {
    name: "AbonoDeMulta",

    data: () => ({
        filesStudent: "",
        base64ImageStudent: "",
        urgence: false,
        typeWarningSlip: [
            { text: "Sim", value: "Sim" },
            { text: "Não", value: "Não" },
        ],
        operationType : [
            { text: "Conta Corrente", value: "Conta Corrente" },
            { text: "Conta Poupança", value: "Conta Poupança" },
        ],
        typePIXKey: [
            { text: "CPF ou CNPJ", value: "CPF ou CNPJ" },
            { text: "Email", value: "Email"},
            { text: "Celular", value: "Celular"},
            { text: "Chave Aleatória", value: "Chave Aleatória"},
        ],
        idAttendance: null,
        errorIdAttendance: null,
        units: null,
        errorUnit: null,
        enrollmentTypeStudent: null,
        valueStudent: null,
        agreementRa: null,
        errorAgreementRa: null,
        selectedUnitStudent: null,
        solicitationStudent: null,
        inputSalesServiceCodeStudent: null,
        salesServiceCodeStudent: null,
        selectedWarningSlipStudent: null,
        selectOperationTypeStudent: null,
        bankStudent: null,
        agencyStudent: null,
        accountStudent: null,
        ownerAccountStudent: null,
        pixKeyStudent: null,
        cpfStudent: null,
        selectTypePIXKeyStudent: null,
    }),

    mounted() {
        this.$vs.loading({ text: "Carregando..." });
        new Units()
        .getUnits()
        .then(({ data }) => {
            this.units = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
            if (response && response.data) {
                this.errorUnit = response.data;
                console.log(response)
            }
        })
        .finally(() => {
            this.$vs.loading.close();
        });
    },

    methods: {

        async onFileUploadStudent (event) {
            this.filesStudent = event.target.files[0];                            
            const { valid } = await this.$refs.provider.validate(this.filesStudent);   
            if (valid) {                                   
                this.createBase64ImageStudent(this.filesStudent);
            }
        },

        createBase64ImageStudent(file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                var bynaryData = event.target.result;

                this.base64ImageStudent = window.btoa(bynaryData);

            };
            reader.readAsBinaryString(file);
        },

        async createAttendance(urgence) {
            this.idAttendance = null;
            this.errorIdAttendance = null;
            const { ...userProfile } = getLoginDataFromStorage();

            const personId = userProfile.userData.CODIGO;
            const personLocalId = userProfile.userData.CODLOCAL;
            const personName = userProfile.userData.NOME;
            const attendantId = userProfile.userData.CODATENDENTE;
            
            const payload = new AdministrativoModel().getDataByAttendanceId('A1-13');

            if (this.base64Image != null ) {
                payload.data.locationId = personLocalId.toString();
                payload.data.ra = this.valueStudent;
                payload.data.attendantId = attendantId.toString();
                payload.data.attachment = this.base64ImageStudent;
                payload.data.fileName = this.filesStudent.name;
                payload.data.priority = urgence;
                payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA: ${this.valueStudent}\nCOD. ATENDIMENTO DA VENDA: ${this.inputSalesServiceCodeStudent}\nFILIAL QUE MATRICULOU: ${this.salesServiceCodeStudent[0].FilialQueMatriculou}\nID PREMATRICULA: ${this.salesServiceCodeStudent[0].IdPrematricula}\nUSUÁRIO DO VENDEDOR: ${this.salesServiceCodeStudent[0].Vendedor}\nCONTRATO: ${this.salesServiceCodeStudent[0].Contrato}\nTIPO DE MATRICULA: ${this.enrollmentTypeStudent}\nFILIAL: ${poloTextFormat(this.selectedUnitStudent, this.units)}\nDADOS BANCÁRIOS: ${this.selectOperationTypeStudent}\nBANCO: ${this.bankStudent}\nAGÊNCIA: ${this.agencyStudent}\nCONTA: ${this.accountStudent}\nNOME COMPLETO DO TITULAR DA CONTA: ${this.ownerAccountStudent}\nTIPO DE CHAVE PIX: ${this.selectTypePIXKeyStudent}\nPIX DO RESPONSÁVEL FINANCEIRO: ${this.pixKeyStudent}\nCPF: ${this.cpfStudent}\nSOLICITAÇÃO: ${this.solicitationStudent}`;
            } else {
                payload.data.locationId = personLocalId.toString();
                payload.data.ra = this.valueStudent;
                payload.data.attendantId = attendantId.toString();
                payload.data.attachment = "";
                payload.data.fileName = "";
                payload.data.priority = urgence;
                payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA: ${this.valueStudent}\nCOD. ATENDIMENTO DA VENDA: ${this.inputSalesServiceCodeStudent}\nFILIAL QUE MATRICULOU: ${this.salesServiceCodeStudent[0].FilialQueMatriculou}\nID PREMATRICULA: ${this.salesServiceCodeStudent[0].IdPrematricula}\nUSUÁRIO DO VENDEDOR: ${this.salesServiceCodeStudent[0].Vendedor}\nCONTRATO: ${this.salesServiceCodeStudent[0].Contrato}\nTIPO DE MATRICULA: ${this.enrollmentTypeStudent}\nFILIAL: ${poloTextFormat(this.selectedUnitStudent, this.units)}\nDADOS BANCÁRIOS: ${this.selectOperationTypeStudent}\nBANCO: ${this.bankStudent}\nAGÊNCIA: ${this.agencyStudent}\nCONTA: ${this.accountStudent}\nNOME COMPLETO DO TITULAR DA CONTA: ${this.ownerAccountStudent}\nTIPO DE CHAVE PIX: ${this.selectTypePIXKeyStudent}\nPIX DO RESPONSÁVEL FINANCEIRO: ${this.pixKeyStudent}\nCPF: ${this.cpfStudent}\nSOLICITAÇÃO: ${this.solicitationStudent}`;
            }
            

            this.$vs.loading({ text: "Carregando..." });
            new Attendance()
                .createAttendance(payload.data)
                .then(({ data }) => {
                this.idAttendance = data;
                })
                .catch(({ response }) => {
                if (response && response.data) {
                    this.errorIdAttendance = response.data;
                    console.log(response)
                }
                })
                .finally(() => {
                this.resetForm();
                this.$vs.loading.close();
                });
        },

        getAgreementByRa() {
            this.$vs.loading({ text: "Carregando..." });
            new Management()
                .getAgreementByRA(this.valueStudent)
                .then(({ data }) => {
                this.agreementRa = Array.isArray(data) ? data : [data];                
                })
                .catch(({ response }) => {
                if (response && response.data) {
                    this.errorAgreementRa = response.data;
                    console.log(response)
                }
                })
                .finally(() => {
                this.$vs.loading.close();
                });
        },

        resetForm() {
            this.solicitationStudent = null;
            this.selectedUnitStudent = null;
            this.valueStudent = null;
            this.agreementRa = null;
            this.inputSalesServiceCodeStudent = null;
            this.salesServiceCodeStudent = null;
            this.enrollmentTypeStudent = null;        
            this.selectedWarningSlipStudent = null;
            this.selectedUnitStudent = null;
            this.selectOperationTypeStudent = null;
            this.bankStudent = null;
            this.agencyStudent = null;
            this.accountStudent = null;
            this.ownerAccountStudent = null;
            this.pixKeyStudent = null;
            this.cpfStudent = null;
            this.selectTypePIXKeyStudent = null;

            this.$nextTick(() => {
                this.$refs.observer.reset();                
            });
        },

        getSalesServiceCodeStudent() {
            this.$vs.loading({ text: "Carregando..." });
            new Management()
            .getReturnAndChargeback(this.inputSalesServiceCodeStudent)
            .then(({ data }) => {
                this.salesServiceCodeStudent = Array.isArray(data) ? data: [data];
            })
            .catch(({ response }) => {
                if (response && response.data) {
                    this.errorSalesServiceCodeStudent = response.data;
                    console.log(response)
                }
            })
            .finally(() => {
                this.$vs.loading.close();
            });
        },        
    }
}
</script>

<style scoped>
.pd-r {
  padding: 1em;  
  color: red;
}

.boxTurn {
  display: flex;
  align-items: center;
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
