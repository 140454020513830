<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'chart-bar']" />
          <h2 class="tituloAtendimento">            
             Certificado Palestra    
          </h2>
        </div>
      </vs-row>      

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"          
            />
          </vs-col>
        </vs-row>
      </div>
      
      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>RA do Aluno <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="RA"
                  rules="required|numeric"
                >
                  <vs-input
                    @keyup.enter="getCoursesByRA()" 
                    v-model="ra"
                    placeholder="Digite o RA do Aluno"
                    class="selectExampleRA"
                  >
                  </vs-input>
                   
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getCoursesByRA()"
                >
                  <font-awesome-icon :icon="['fas', 'search']" />
                  <span> Buscar </span></vs-button
                >
              </fieldset>              
            </vs-col>
          </vs-row>  
        </ValidationObserver>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div v-if="responseRa != null">
          <div v-if="responseRa == ''">
            <p class="validacao">RA Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Nome Aluno <b style="color: red;">*</b></legend>
                  <vs-input
                    class="selectExample"
                    :placeholder="responseRa[0].NOME_ALUNO"
                    disabled="disabled"
                  />
                </fieldset>                    
              </vs-col>

              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Palestrante <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Tema Aluno"
                    rules="required"
                  >                      
                    <vs-input
                      class="selectExampleDouble"
                      placeholder="Digite o nome do palestrante"
                      v-model="speaker"
                    />
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Tema <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Tema Aluno"
                    rules="required"
                  >                      
                    <vs-input
                      class="selectExampleDouble"
                      placeholder="Digite o Tema"
                      v-model="themeStudent"
                    />
                     
                  </ValidationProvider>
                </fieldset>                                    
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Carga Horária <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Carga Horaria Aluno"
                    rules="required"
                     
                  >
                    <vs-input
                      class="selectExampleDouble"                          
                      placeholder="Digite a Carga Horária em Horas"
                      v-model="workloadStudent"
                      type="number"
                    />
                     
                  </ValidationProvider>
                </fieldset>                        
              </vs-col> 
            </vs-row>
            
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Filial Aluno <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Filial Aluno"
                    rules="required"
                     
                  >
                    <vs-select
                      v-model="selectedUnitStudent"
                      class="selectExampleDouble"
                      placeholder="Selecione uma Filial"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.CODFILIAL"
                        :text="item.POLO"
                        v-for="(item, index) in units"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>                    
              </vs-col>  

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Data Aluno"
                    rules="required"
                     
                  >                        
                    <vs-input
                      class="selectExampleDouble"
                      v-model="dateStudent"
                      type="date"
                    />                
                     
                  </ValidationProvider>
                </fieldset>                    
              </vs-col>
            </vs-row>                                

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>  
</template>

<script>
import Units from "../../../../services/units";
import Students from "../../../../services/students";
import { getLoginDataFromStorage, poloTextFormat, convertDate } from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
    name: "Palestra",
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    idAttendance: null,
    errorIdAttendance: null,    
    selectedType: null,
    types: [
        { text: "Aluno", value: "Aluno" },
        { text: "Outros", value: "Outros" },
    ],
    themeStudent: null,
    units: null,
    errorUnits: null,
    selectedUnitStudent: null,
    dateStudent: null,
    speaker: null,
    workloadStudent: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.null = response.data;
          console.log(response)
        }
      })      
      .finally(() => {
        this.$vs.loading.close();
      });
  },
  methods: {
    getCoursesByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCoursesByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
    },
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      this.idAttendance = null;
      this.errorIdAttendance = null;

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const studentName = this.responseRa[0].NOME_ALUNO

      const payload = new EducacionalModel().getDataByAttendanceId("E4");

      payload.data.locationId = personLocal.toString();
      payload.data.ra = this.ra;
      payload.data.attendantId = attendantId.toString();
      payload.data.subject = "CERTIFICADO - PALESTRA";
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nRA DO ALUNO: ${this.ra}\nALUNO: ${studentName}\nPALESTRANTE: ${this.speaker}\nTEMA: ${this.themeStudent}\nFILIAL: ${poloTextFormat(this.selectedUnitStudent, this.units)}\nDATA: ${convertDate(this.dateStudent)}\nCARGA HORÁRIA: ${this.workloadStudent}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
          this.$vs.loading.close();
        });
      this.resetForm();
    },
    resetForm() {
      this.themeStudent = null;
      this.selectedUnitStudent = null;
      this.dateStudent = null;
      this.workloadStudent = null;      
      this.ra = null;
      this.responseRa = null;     

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
