import { https } from './api';

export default class {
    constructor() {
        this.instance = https('teachers');
    }

    getActiveTeachers = () => {
        return this.instance({
            method: 'GET',
            url: '/'
        });
    }

    getAcademicEducationByTeacher = (codProfessor) => {
        return this.instance({
            method: 'GET',
            url: `/${codProfessor}`
        });
    }

    getAvaliabilityByTeacher = (codProfessor) => {
        return this.instance({
            method: 'GET',
            url: `/${codProfessor}/availability`
        });
    }

    getDisciplineByTeacher = (codProfessor) => {
        return this.instance({
            method: 'GET',
            url: `/${codProfessor}/disciplines`
        });
    }

    getTeacherAllocationByPeriod = (teacherID, inicialDate, finalDate) => {
        return this.instance({
            method: 'GET',
            url: `/${teacherID}/allocation/${inicialDate}/${finalDate}`
        });
    }

    getFreeTeacherByDateAndShift = (date, shift) => {
        return this.instance({
            method: 'GET',
            url: `/available/${date}/${shift}`
        });
    }

    getSelectiveProcess = () => {
        return this.instance({
            method: 'GET',
            url: '/selectiveProcess/all'
        });
    }

    getSelectiveProcessData = (collidateId, attendanceId, locationId) => {
        return this.instance({
            method: 'GET',
            url: `/selectiveProcess/${attendanceId}/${locationId}/${collidateId}`
        });
    }

    getTeacherClasses = (collidateID, userName) => {
        return this.instance({
            method: 'GET',
            url: `/classes/${collidateID}/${userName}`
        });
    }

    getWorkloadByTeacher = (timeId) => {
        return this.instance({
            method: 'GET',
            url: `/workload/${timeId}`
        });
    }
}