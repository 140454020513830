<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'coins']" />
              <h2 class="tituloAtendimento">
                Ajustes Financeiros
              </h2>
            </div>
          </vs-row>
          
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  v-model="selectedTypes"
                  placeholder="Selecione um Tipo de Atendimento"
                >
                  <vs-select-item
                    :key="item.value"
                    :value="item.value"
                    :text="item.text"
                    v-for="item in types"
                  />
                </vs-select>
              </fieldset>                                  
            </vs-col>  
          </vs-row>
        </div>

        <AbonoDeMulta v-if="this.selectedTypes == 1" />
        <AproveitamentoDeCredito v-if="this.selectedTypes == 2" />
        <AdicionalNoturno v-if="this.selectedTypes == 4" />
        <BolsaEmpresaConveniada v-if="this.selectedTypes == 5" />
        <CampanhaResgate v-if="this.selectedTypes == 6"/>
        <DepartamentoDeContratos v-if="this.selectedTypes == 7"/>
        <Devolucao v-if="this.selectedTypes == 8"/>
        <Estorno v-if="this.selectedTypes == 9"/>
        <EstornoDRC v-if="this.selectedTypes == 10"/>
        <InclusaoDeDisciplinaNativa v-if="this.selectedTypes == 11" />
        <NotaFiscal v-if="this.selectedTypes == 12" />
        <QuitacaoDeContrato v-if="this.selectedTypes == 13" />      
        <TrocaDaDataDeVencimentos v-if="this.selectedTypes == 14" />      
        <TrocaDeMeioDePagamento v-if="this.selectedTypes == 15" />    
      </div>      
    </div>            
  </section>
</template>

<script>
import AbonoDeMulta from "./FormAjustes/AbonoDeMulta.vue"
import AproveitamentoDeCredito from "./FormAjustes/AproveitamentoDeCredito.vue"
import NotaFiscal from "./FormAjustes/NotaFiscal.vue"
import TrocaDaDataDeVencimentos from "./FormAjustes/TrocaDaDataDeVencimentos.vue"
import BolsaEmpresaConveniada from "./FormAjustes/BolsaEmpresaConveniada.vue"
import CampanhaResgate from "./FormAjustes/CampanhaResgate.vue"
import DepartamentoDeContratos from "./FormAjustes/DepartamentoDeContratos.vue"
import EstornoDRC from "./FormAjustes/EstornoDRC.vue"
import AdicionalNoturno from "./FormAjustes/AdicionalNoturno.vue"
import TrocaDeMeioDePagamento from "./FormAjustes/TrocaDeMeioDePagamento.vue"
import QuitacaoDeContrato from "./FormAjustes/QuitacaoDeContrato.vue"
import InclusaoDeDisciplinaNativa from "./FormAjustes/InclusaoDeDisciplinaNativa.vue"
import Estorno from "./FormAjustes/Estorno.vue"
import Devolucao from "./FormAjustes/Devolucao.vue"
export default {
  name: "AjustesFinanceiros",
  components: {
    AbonoDeMulta,
    AproveitamentoDeCredito,
    AdicionalNoturno,
    NotaFiscal,
    TrocaDaDataDeVencimentos,
    BolsaEmpresaConveniada,
    CampanhaResgate,
    DepartamentoDeContratos,
    EstornoDRC,
    TrocaDeMeioDePagamento,
    QuitacaoDeContrato,
    InclusaoDeDisciplinaNativa,
    Estorno,
    Devolucao,
  },
  data: () => ({
    types: [
      { text: "Abono de Multa - Promoção Indicação", value: 1 },
      { text: "Aproveitamento de Curso/Crédito", value: 2 },
      { text: "Adicional Noturno - Inclusão de Percentual", value: 4 },
      { text: "Bolsa Empresa Conveniada", value: 5 },
      { text: "Campanha Resgate", value: 6 },
      { text: "Departamento de Contratos", value: 7 },
      { text: "Devolução", value: 8 },
      { text: "Estorno", value: 9 },
      { text: "Estorno - DRC", value: 10 },
      { text: "Inclusão de Disciplina Nativa - Valor", value: 11 },
      { text: "Nota Fiscal", value: 12 },
      { text: "Quitação de Contrato", value: 13 },      
      { text: "Troca da Data de Vencimento", value: 14 },      
      { text: "Troca de Meio de Pagamento - Recorrente para Boleto", value: 15 },      
    ],
    selectedTypes: null,
  }),
};
</script>
