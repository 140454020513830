import { render, staticRenderFns } from "./CriacaoComunicacao.vue?vue&type=template&id=e5e24ec0&scoped=true"
import script from "./CriacaoComunicacao.vue?vue&type=script&lang=js"
export * from "./CriacaoComunicacao.vue?vue&type=script&lang=js"
import style0 from "./CriacaoComunicacao.vue?vue&type=style&index=0&id=e5e24ec0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5e24ec0",
  null
  
)

export default component.exports