<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarHome class="menu"/>
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'user-plus']" />
            <h2 class="tituloAtendimento">
               Cadastro de Empresas Conveniadas
            </h2>
          </div>
        </vs-row>
        
        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Nome da Empresa <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Nome Da Empresa"
                    rules="required"
                     
                  >
                    <vs-input v-model="nameCompany" placeholder="Digite o Nome Da Empresa" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>            

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>CNPJ da Empresa <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="CNPJ Da Empresa"
                    rules="required"
                     
                  >
                    <vs-input v-mask="'##.###.###/####-##'" v-model="CNPJCompany" placeholder="Digite o CNPJ Da Empresa" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Percentual da Bolsa <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Percentual Da Bolsa"
                    rules="required"
                     
                  >
                    <vs-input type="number" v-model="percentage" placeholder="Digite o Percentual Da Bolsa" class="selectExampleDouble"/>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Responsável Contato <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Responsável Contato"
                    rules="required"
                     
                  >
                    <vs-select
                      placeholder="Selecione o Responsável Contato"
                      v-model="responsibleContact"
                      class="selectExampleDouble"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in typeResponsible"
                      />
                    </vs-select>
                     
                  </ValidationProvider>
                </fieldset>
              </vs-col>            
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Observação"
                  rules="required|max: 500"
                   
                >
                  <vs-textarea
                    rows="11"
                    label="Observação"
                    class="textArea"
                    height="15em"
                    counter="500"
                    v-model="note"
                  />
                   
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>DADOS CONTATO EMPRESA <b style="color: red;">*</b></legend>
                  <vs-col>
                    <legend class="legendBancario">Nome para Contato <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Nome para Contato"
                      rules="required"
                       
                    >
                      <vs-input v-model="nameContactCompany" placeholder="Digite o Nome Contato" class="selectExample"/>
                       
                    </ValidationProvider>
                  </vs-col>

                  <vs-col class="pd-b">
                    <legend class="legendBancario">Telefone para Contato <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Telefone para Contato"
                      rules="required|min:15"
                       
                    >
                      <vs-input v-mask="'(##) #####-####'" v-model="phoneContactCompany" placeholder="Digite o Telefone Contato" class="selectExample"/>
                       
                    </ValidationProvider>
                  </vs-col>

                  <vs-col class="pd-b">
                    <legend class="legendBancario">Email para Contato <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="Email para Contato"
                      rules="required"
                       
                    >
                      <vs-input type="email" v-model="emailContactCompany" placeholder="Digite o Email Contato" class="selectExample"/>
                       
                    </ValidationProvider>
                  </vs-col>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </ValidationObserver>        
        </div>
      </div>
    </div>
  </section>  
</template>

<script>
import Attendance from "../../../services/attendance";
import { getLoginDataFromStorage } from "../../../helpers/storage";
import AdministrativoModel from "../../../models/administrativo/administrativo";
export default {
  name: "RegistroDeConvenio",
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    nameCompany: null,
    CNPJCompany: null,
    percentage: null,
    responsibleContact: null,
    note: null,
    nameContactCompany: null,
    phoneContactCompany: null,
    emailContactCompany: null,
    typeResponsible: [
      { text: "EMPRESA", value: "EMPRESA" },
      { text: "ALUNO", value: "ALUNO" },
    ]
  }),
  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;  
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new AdministrativoModel().getDataByAttendanceId('A7-5');

      payload.data.locationId = personLocalId.toString();
      payload.data.subject = "INTRANET - JURÍDICO - EMPRESAS CONVÊNIADAS";
      payload.data.attendantId = attendantId.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nNOME DA EMPRESA: ${this.nameCompany}\nCNPJ DA EMPRESA: ${this.CNPJCompany}\nPERCENTUAL DA BOLSA: ${this.percentage}\nRESPOSÁVEL CONTATO: ${this.responsibleContact}\nOBSERVAÇÃO: ${this.note}\nDADOS CONTATO EMPRESA\nNOME CONTATO: ${this.nameContactCompany}\nTELEFONE CONTATO: ${this.phoneContactCompany}\nEMAIL: ${this.emailContactCompany}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
        this.idAttendance = data;
        })
        .catch(({ response }) => {
        if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
        }
        })
        .finally(() => {
        this.$vs.loading.close();
        this.resetForm();
        });
    },

    resetForm() {
      this.nameCompany = null;
      this.CNPJCompany = null;
      this.percentage = null;
      this.responsibleContact = "";
      this.note = null;
      this.nameContactCompany = null;
      this.phoneContactCompany = null;
      this.emailContactCompany = null;

      this.$nextTick(() => {
          this.$refs.observer.reset();
      });
    },
  },
}
</script>

<style scoped>
.legendBancario {    
  padding-left: 1em;
}

.pd-b {
  margin-top: 1em;
}

@media screen and (max-width: 900px) {
  .pd-c {
    padding-top: 1em;  
  }
}
</style>
