<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'house-user']" />
            <h2 class="tituloAtendimento">Disciplinas em Produção</h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="productionDisciplines"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Atendimento </vs-th>
                    <vs-th class="table_th"> Solicitante/Data </vs-th>
                    <vs-th class="table_th"> Disciplina </vs-th>
                    <vs-th class="table_th"> Etapa </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <a href="#" @click="showDetails(data[indextr])">
                          {{ data[indextr].CODATENDIMENTO }}
                        </a>
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].SOLICITANTE }} <br>
                        <span>
                          Aberto em {{ data[indextr].ABERTURA }}
                          <br>
                          {{ data[indextr].PRAZOLIMITE == null ? "" : 'Prazo limite em ' + data[indextr].PRAZOLIMITE }}
                        </span>
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].DISCIPLINA }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].TAREFA }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-popup title="Detalhes" :active.sync="popupAtivo" v-if="selectedEvent">
            <vs-card outlined>
                <div>
                    <vs-card>
                        <h3>Solicitação</h3>
                        <p>{{ selectedEvent.TEXTOSOLICITACAO }}</p>

                        <vs-divider/>

                        <h3>Link moodle: <a :href="selectedEvent.LINKMOODLE" target="_blank">{{ selectedEvent.LINKMOODLE == null ? "-" : selectedEvent.LINKMOODLE }}</a></h3>
                    </vs-card>

                    <vs-card>
                        <h3>Responsável</h3>
                        <p>{{ selectedEvent.RESPONSÁVEL }}</p>

                        <vs-divider/>

                        <h3>Assunto</h3>
                        <p>{{ selectedEvent.ASSUNTOOC }}</p>

                        <vs-divider/>

                        <h3>Autor</h3>
                        <p>{{ selectedEvent.AUTOR }}</p>
                    </vs-card>
                </div>
            </vs-card>
          </vs-popup>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductionDisciplines from "../../services/management";
export default {
  name: "DisciplinasEmProducao",
  data: () => ({
    popupAtivo: false,
    selectedEvent: null,
    productionDisciplines: [],
    errorProductionDisciplines: null,
  }),
  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new ProductionDisciplines()
      .getProductionDisciplines()
      .then(({ data }) => {
        this.productionDisciplines = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorProductionDisciplines = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    showDetails(selectedEvent) {
      this.popupAtivo = true;
      this.selectedEvent = selectedEvent;
    },
  }
};
</script>

<style scoped>
h3,
p {
  margin-bottom: 1em;
}

.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #D4D4D4;
}
</style>
