<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div class="container">
        <vs-row vs-w="12" class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'user-plus']" />
            <h2 class="tituloAtendimento">
              Benefício de Docente
            </h2>
          </div>
        </vs-row>

        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div>
            <vs-row class="pt-d">
              <vs-col>
                <Message class="pd-t" :msg="idAttendance" :error="errorIdAttendance" v-if="idAttendance || errorIdAttendance" />
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-w="12">
                <fieldset>
                  <legend>Professor <b style="color: red;">*</b></legend>
                  <ValidationProvider 
                  name="Professor" 
                  rules="required" 
                  
                  >
                    <vs-select 
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample" 
                    v-model="selectedTeacher" 
                    placeholder="Selecione um Professor"
                    >
                      <vs-select-item 
                      :key="item.teacher" 
                      :value="item.NOME + ' (' + item.SECAO + ')'" 
                      :text="item.NOME + ' (' + item.SECAO + ')'"
                      v-for="item in activeTeachers" 
                      />
                    </vs-select>
                    
                  </ValidationProvider>
                </fieldset>
              </vs-col>

            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider 
                name="Justificativa" 
                rules="required|max: 500" 
                
                >
                  <vs-textarea 
                  label="Justificativa *" 
                  height="210px" 
                  rows="11" 
                  class="textArea" 
                  counter="500"
                  v-model="solicitation" 
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Anexo <b style="color: red;">*</b></legend>
                  <ValidationProvider
                    name="Imagem"
                    ref="provider"
                    rules="required"
                  >
                    <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                    
                  </ValidationProvider>
                </fieldset>
              </vs-col> 
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>
                
                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>            
            </vs-row>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>
<script>
import Teachers from "../../../services/teachers";
import { getLoginDataFromStorage } from "../../../helpers/storage.js";
import Attendance from "../../../services/attendance";
import PessoalModel from "../../../models/pessoal/pessoal.js";

export default {
  name: "BenenficioDeProfessorInstrutor",
  data: () => ({
    files: "",
    base64Image: "",
    activeTeachers: null,
    errorActiveTeachers: null,
    selectedTeacher: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    nameTeacher: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
    .getActiveTeachers()
    .then(({ data }) => {
      this.activeTeachers = Array.isArray(data) ? data : [data];
      this.$vs.loading.close();
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.errorActiveTeachers = response.data;
        console.log(response)
      }
      this.$vs.loading.close();
    });
  },

  methods: {

    inputChange(event) {
      return event.target.value;
    },
    
    async onFileUpload (event) {
      this.files = event.target.files[0];                            
      const { valid } = await this.$refs.provider.validate(this.files);   
      if (valid) {                                   
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);

      };
      reader.readAsBinaryString(file);
    },    

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new PessoalModel().getDataByAttendanceId("P6");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.attachment = this.base64Image;
      payload.data.fileName = this.files.name;
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nPROFESSOR: ${this.selectedTeacher}\nTEXTO SOLICITAÇÃO: ${this.solicitation}`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response)
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },


    resetForm() {
      this.solicitation = null;
      this.selectedTeacher = null;
      this.$refs.image.value = null;
      this.files= null,
      this.base64Image = null,

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
  
  