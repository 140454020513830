<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }" class="validator">
      <div class="subContainer">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'handshake']" />
            <h2 class="tituloAtendimento">
               Ação Comercial
            </h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pt-d">
            <vs-col>
              <Message class="pd-t"
                :msg="idAttendance"
                :error="errorIdAttendance"
                v-if="idAttendance || errorIdAttendance"          
              />
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Vlr. Total da Ação <b style="color: red;">*</b></legend>
                <ValidationProvider
                  rules="required"
                  name="Vlr. Total da Ação"
                   
                >
                  <vs-input
                    v-model="actionValue"
                    placeholder="Digite o Vlr.total da ação *"
                    type="number"
                    class="selectExampleDouble"
                  />                  
                   
                </ValidationProvider>  
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Destino do Valor <b style="color: red;">*</b></legend>
                <ValidationProvider
                  rules="required"
                  name="Destino do Valor"
                   
                >
                  <vs-input
                    v-model="destinoDoValor"
                    placeholder="Digite o Destino do Valor"
                    class="selectExampleDouble"
                  />
                   
                </ValidationProvider>  
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Inicio da Ação <b style="color: red;">*</b></legend>
                <ValidationProvider
                  rules="required"
                  name="Inicio da Ação"
                   
                >
                  <vs-input
                    v-model="startAction"
                    type="date"
                    class="selectExampleDouble"
                  />
                   
                </ValidationProvider> 
              </fieldset>
            </vs-col>

            <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
              <fieldset>
                <legend>Termino da Ação <b style="color: red;">*</b></legend>
                <ValidationProvider
                  rules="required"
                  name="Termino da Ação"
                   
                >
                  <vs-input
                    v-model="endAction"
                    type="date"
                    class="selectExampleDouble"              
                  />
                   
                </ValidationProvider>   
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Nome da Ação <b style="color: red;">*</b></legend>
                <ValidationProvider
                  rules="required"
                  name="Nome da Ação"
                   
                >
                  <vs-input
                    v-model="nameAction"
                    placeholder="Digite o Nome da Ação"
                    class="selectExample"
                  />
                   
                </ValidationProvider> 
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Anexo <b style="color: red;">*</b></legend>
                <ValidationProvider
                  name="Anexo"
                  ref="provider"
                  rules="required"
                   
                >
                  <input class="selectExample" ref="image" type="file" @change="onFileUpload">
                   
                </ValidationProvider>
              </fieldset>
            </vs-col> 
          </vs-row>

          <vs-row class="pd-t">
            <vs-col>
              <ValidationProvider
                name="Texto Solicitação"
                rules="required|max: 500"
                 
              >
                <vs-textarea
                  rows="11"
                  label="Texto Solicitação *"
                  class="textArea"
                  height="15em"
                  counter="500"
                  v-model="solicitation"
                />
                 
              </ValidationProvider>
            </vs-col>
          </vs-row>

          <vs-row class="pd-t">
            <vs-col style="text-align: center;">
              <vs-button
                color="danger"
                type="gradient"
                class="button-protocol"
                @click="resetForm()"
              >
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-protocol"
                color="success"
                type="gradient"
                @click="createAttendance()"
                :disabled="invalid || !visibleButton"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Gerar
              </vs-button>
            </vs-col>            
          </vs-row>
        </div>
      </div>        
    </ValidationObserver> 
  </section>
</template>

<script>
import Units from "../../../../services/units";
import Attendance from "../../../../services/attendance";
import AdministrativoModel from "../../../../models/administrativo/administrativo";
import {
  getLoginDataFromStorage, convertDate
} from "../../../../helpers/storage.js";
export default {
    name: "AcaoComercial",

    data: () => ({
      files: null,
      base64Image: null,
      units: null,
      destinoDoValor: null,
      errorIdAttendance: null,
      errorUnit: null,
      idAttendance: null,
      actionValue: null,
      startAction: null,
      endAction: null,
      nameAction: null,
      solicitation: null,
      visibleButton: false,
    }),

    mounted() {
      new Units()
        .getUnits()
        .then(({ data }) => {
          this.units = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorUnit = response.data;
            console.log(response)
          }
        });
    },

    methods: {

      async onFileUpload (event) {
        this.files = event.target.files[0];                            
        const { valid } = await this.$refs.provider.validate(this.files);   
        if (valid) {                                   
          this.createBase64Image(this.files);
          this.visibleButton = true;
        }
      },

      createBase64Image(file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          var bynaryData = event.target.result;

          this.base64Image = window.btoa(bynaryData);

        };
        reader.readAsBinaryString(file);
      },
      
      async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
        const { ...userProfile } = getLoginDataFromStorage();

        const personId = userProfile.userData.CODIGO;
        const personLocalId = userProfile.userData.CODLOCAL;
        const personName = userProfile.userData.NOME;
        const attendantId = userProfile.userData.CODATENDENTE;

        const payload = new AdministrativoModel().getDataByAttendanceId('A10');

        payload.data.locationId = personLocalId.toString();
        payload.data.personId = personId.toString();
        payload.data.subject = "NUMERÁRIOS - AÇÃO COMERCIAL";
        payload.data.attendantId = attendantId.toString();
        payload.data.attachment = this.base64Image;
        payload.data.fileName = this.files.name;
        payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nDESTINO DO VALOR: ${this.destinoDoValor}\nVALOR TOTAL DA AÇÃO: ${this.actionValue}\nINICIO DA AÇÃO: ${convertDate(this.startAction)}\nTERMINO DA AÇÃO: ${convertDate(this.endAction)}\nNOME DA AÇÃO: ${this.nameAction}\nSOLICITAÇÃO: ${this.solicitation}`;

        this.$vs.loading({ text: "Carregando..." });
        new Attendance()
          .createAttendance(payload.data)
          .then(({ data }) => {
            this.idAttendance = data;
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.errorIdAttendance = response.data;
              console.log(response)
            }
            this.$vs.loading.close();
          });
        this.resetForm();
      },
      resetForm() {
        this.solicitation = null;
        this.destinoDoValor = null;
        this.actionValue = null;
        this.startAction = null;
        this.endAction = null;
        this.nameAction = null;
        this.idAttendance = null;
        this.errorIdAttendance = null;
        this.visibleButton = false;
        this.$refs.image.value=null;

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
    },
  },

}

</script>

